<template>
    <div class="d-inline-block">
        <span class="filter-name">{{title}}:</span>
        <b-form-tag v-for="(tag, index) in dataList"
                    @remove="remove(tag)"
                    :key="tag"
                    :title="tag"
                    variant="filterTag"
                    v-if="index<limit"
                    class="mr-1 active">{{ $t(tag) }}</b-form-tag>
        <template v-if="dataList.length>limit">
            <b-badge variant="filterTag" class="mr-1 active" :id="title+'-more'">
                +{{dataList.length-this.limit}}
            </b-badge>
            <b-tooltip :target="title+'-more'" triggers="hover" placement="bottom" variant="tags">
                <b-form-tag v-for="(tag, index) in dataList.slice(limit)"
                            @remove="remove(tag)"
                            :key="tag"
                            :title="tag"
                            variant="filterTag"
                            v-if="index<5"
                            class="mr-1 active">{{ $t(tag) }}</b-form-tag>
            </b-tooltip>
        </template>
    </div>
</template>

<script>

    export default {
        name: "SearchTags",
        data: function () {
            return {
            }
        },
        props: {
            title: {
                type: String,
                required: true
            },
            dataList: {
                required: true
            },
            limit: {
                type: Number,
                default: 3
            }
        },
        methods: {
            remove(tag) {
                this.$emit('remove', tag)
            }
        }
    }
</script>

<style scoped>
    .filter-name {
        font: normal 14px/30px Roboto;
        letter-spacing: 0px;
        color: #8292A5;
    }

</style>
