export default {
    en: {
        'what_is_unterm_title': 'The <span class="text-secondary">UNTERM</span> Portal',
        'what_is_unterm': '<b>UNTERM</b> is a multilingual database for official terminology relevant to the work of the United Nations. It is a public website used by UN delegates and staff, as well as other users who are interested in the work and activities of the United Nations around the world.',
        'multilingual_content_title': 'Multilingual Content',
        'multilingual_content': 'Official terminology is provided in the six official languages of the United Nations — Arabic, Chinese, English, French, Russian and Spanish —  in line with the General Assembly mandate on multilingualism. ',
        'global_management_title': 'Global Management',
        'global_management': '<b>UNTERM</b> contains terminology entries from entities across the UN system. To ensure consistency across the UN system, the database is managed by UN language staff specialized in each subject.',
    }
}

