import { languages as AllLanguages } from "./Constants";

export class SearchModel {
    public constructor(init?: Partial<SearchModel>) {
        Object.assign(this, init);

        if (init == null || init.searchLanguages == null) {
            this.searchLanguages = this.getDefaultLanguages()
        }
        if (init == null || init.languagesDisplay == null) {
            this.languagesDisplay = this.getDefaultLanguages()
        }
    }
    searchTerm: string = ''
    searchType: number = 0
    searchLanguages: string[] = []
    languagesDisplay: string[] = []
    datasets: string[] = []
    bodies: string[] = []
    subjects: string[] = []
    recordTypes: string[] = []
    sortBy: string
    sortDirection: string

    acronymSearch: boolean = true;
    localDBSearch: boolean = true;
    termTitleSearch: boolean = true;
    phraseologySearch: boolean = false;
    footnoteSearch: boolean = false;
    fullTextSearch: boolean = false;

    facetedSearch: boolean = false;
    buildSubjectList: boolean = true;

    hasSearchTerm(): boolean {
        return (this.searchTerm != null && this.searchTerm.trim() !== "")
    }

    private getDefaultLanguages(): string[] {
        return AllLanguages.filter(l => l.isOfficial).map(l => l.code)
    }

    clearFilters(defaultSettings: any): void {
        this.searchLanguages = (defaultSettings || {})?.searchLanguages
        this.languagesDisplay = (defaultSettings || {})?.languagesDisplay
        this.datasets = ((defaultSettings || { dataSets: [] }).dataSets || [])
        this.bodies = []
        this.subjects = []
        this.recordTypes = []
    }

    toQueryString(): string {
        let qs = "?TermText=" + this.searchTerm
        qs += "&searchType=" + this.searchType
        qs = this.searchLanguages.reduce((current, itm) => current + `&SearchLanguages=${itm}`, qs)
        qs = this.datasets.length ? this.datasets.reduce((current, itm) => current + `&Datasets=${itm}`, qs) : ""
        qs = this.bodies.length ? this.bodies.reduce((current, itm) => current + `&Bodies=${itm}`, qs) : ""
        qs = this.subjects.length ? this.subjects.reduce((current, itm) => current + `&Subjects=${itm}`, qs) : ""
        qs += this.acronymSearch ? `&AcronymSearch=${this.acronymSearch}` : ""
        qs += this.localDBSearch ? `&LocalDBSearch=${this.localDBSearch}` : ""
        qs += this.termTitleSearch ? `&TermTitleSearch=${this.termTitleSearch}` : ""
        qs += this.phraseologySearch ? `&PhraseologySearch=${this.phraseologySearch}` : ""
        qs += this.footnoteSearch ? `&FootnoteSearch=${this.footnoteSearch}` : ""
        qs += this.fullTextSearch ? `&FullTextSearch=${this.fullTextSearch}` : ""
        qs += this.buildSubjectList ? `&BuildSubjectList=${this.buildSubjectList}` : ""
        qs += this.sortBy ? `&SortBy=${this.sortBy}` : ""
        qs += this.sortDirection ? `&SortDirection=${this.sortDirection}` : ""

        return qs
    }
}
