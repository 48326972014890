type RecordStateModel = { currentRecord: RecordModel; currentRecordId: string; feedbackShowing: boolean;  };
import * as Actions from "../Actions"
import { RecordModel } from "../../Model/RecordModel";


const state: RecordStateModel = {
    currentRecord: new RecordModel(),
    currentRecordId: "",
    feedbackShowing: false
};

const mutations = {
    setCurrentRecord(state : RecordStateModel, record : RecordModel) {
        state.currentRecord = record
    },
    setCurrentRecordId(state, recordId :string) {
        state.currentRecordId = recordId
    },
    toggleFeedbackShowing(state, showing?: boolean) {
        if (typeof showing === "undefined") {
            showing = !state.feedbackShowing
        }
        state.feedbackShowing = showing
    }
}

const getters = {
    getCurrentRecord(state: RecordStateModel): RecordModel {
        return state.currentRecord
    },
    isFeedbackShowing: state => {
        return state.feedbackShowing
    },
}

const actions = {
    setCurrentRecord({ commit }, record :RecordModel) {
        commit(Actions.SET_CURRENT_RECORD, record)
    },
    setCurrentRecordId({ commit }, recordId:string) {
        commit(Actions.SET_CURRENT_RECORD_ID, recordId)
    },
    toggleFeedbackShowing({ commit }, showing?: boolean) {
        commit(Actions.TOGGLE_FEEDBACK_SHOWING, showing)
    },
    resetRecordStore({ commit }) {
        commit(Actions.SET_CURRENT_RECORD, null)
        commit(Actions.TOGGLE_FEEDBACK_SHOWING, false)
    },
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}
