<template>
    <div class="contentsOnly">
        <template v-if="LanguageData!=null">
            <ul :class="['search-result', LanguageCode]" :dir="LanguageCode=='ar' ? 'rtl':null">
                <li v-for="term in LanguageData.terms" v-if="term.termStatus != 'open'">
                    <font-awesome-icon color="grey" :icon="['fa', 'minus']" />
                    <span :class="[term.termStatus, term.termType]" v-to-lang.children="LanguageCode" v-html="highlightSearch(term.term)"></span>
                </li>
            </ul>
        </template>
        <template v-if="LanguageData==null">
            &nbsp;
        </template>
    </div>
</template>

<script>

    import { faMinus } from '@fortawesome/free-solid-svg-icons'
    import { library } from '@fortawesome/fontawesome-svg-core'
    library.add(faMinus)

    export default {
        name: "LanguageResult",
        inheritAttrs: false,
        data: () => ({
            specialLanguages: ["ru", "zh", "ar"]
        }),
        props: ["LanguageData", "LanguageCode", 'SearchTerm'],
        computed: {
            isSpecialLanguage() {
                return this.specialLanguages.includes(this.LanguageCode);
            }
        },
        methods: {
            highlightSearch(term) {
                if (term != null && this.SearchTerm) {
                    let finalString = term;
                    const searchWords = this.SearchTerm.split(/[^\w-]/g);
                    let highlightTemplate = `<em>[match]</em>`;
                    searchWords.forEach(searchWord => {
                        searchWord = searchWord.replace(/[\(\[\][\^\]\]\*\[\)\]]/g, '');
                        if (searchWord !== '') {
                            let searchWordPattern = this.isSpecialLanguage ? `${searchWord}` : `\\b${searchWord}\\b`
                            const matches = term.match(new RegExp(searchWordPattern, 'gi'));
                            if (matches != null) {
                                matches.forEach(match => {
                                    let matchPattern = this.isSpecialLanguage ? `${match}` : `\\b${match}\\b`
                                    let hgihlightTerm = highlightTemplate.replace('[match]', match)
                                    finalString = finalString.replace(new RegExp(matchPattern, 'g'), hgihlightTerm)
                                })
                            }
                        }
                    });
                    return finalString;
                }
                return term;
            }
        }
    }
</script>

<style scoped lang="scss">
    ul.search-result {
        list-style: none;
        padding: 0;

        &.ar {
            text-align: right;
        }
    }

    .rtl {
        direction: rtl;
    }

    span.hl, em {
        color: Red;
        font-style: normal;
    }
</style>
