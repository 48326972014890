import { Language } from "./Language";

export const searchTypes = {
    ALL_WORDS: { value: 0, text: 'all_words' },
    EXACT_PHRASE: { value: 1, text: 'exact_phrase' },
    COMBINED: { value: 2, text: 'combined' },
    FUZZY: { value: 3, text: 'fuzzy' },
    BOOLEAN: { value: 4, text: 'boolean' }
}

export const languageCode = {
    English: 'en',
    French: 'fr',
    Spanish: 'es',
    Russian: 'ru',
    Chinese: 'zh',
    Arabic: 'ar',
    German: 'de',
    Portuguese: 'pt',
    Editorial: 'ed',
}

export const languages : Language[] = [
    new Language({ code: languageCode.English, name: 'English', isOfficial: true }),
    new Language({ code: languageCode.French, name: 'French', isOfficial: true }),
    new Language({ code: languageCode.Spanish, name: 'Spanish', isOfficial: true }),
    new Language({ code: languageCode.Russian, name: 'Russian', isOfficial: true }),
    new Language({ code: languageCode.Chinese, name: 'Chinese', isOfficial: true }),
    new Language({ code: languageCode.Arabic, name: 'Arabic', isOfficial: true }),
    new Language({ code: languageCode.German, name: 'German', isOfficial: false }),
    new Language({ code: languageCode.Portuguese, name: 'Portuguese', isOfficial: false }),
    new Language({ code: languageCode.Editorial, name: 'Editorial', isOfficial: false })
]

export const languagesDictionary = languages.reduce((map, obj) => {
    map[obj.code] = obj;
    return map;
}, {})

export const Record = {
    Space:
    {
        Portal: "portal",
        Archive: "archive",
        Deleted: "deleted",
        DeletedDuplicate: "deletedduplicate",
        spaceSuggestion: "suggestion",
        Workspace: "workspace",
        Oficial: "official",
        Master: "master",
        External: "external",
        Import: "import",

    },
    Type: {
        Country: "country",
        Title: "title",
        Term: "term",
        Phraseology: "phraseology",
        ProperName: "proper name",
        Footnote: "footnote",
    }
}

export const Term = {
    Type:
    {
        Suggestion: "suggestion",
        Correction: "correction",
        Term: "term",
        Title: "title",
        ProperName: "proper name",
        Acronym: "acronym",
        Full: "full",
        Short: "short",
        Variant: "variant",
        Abbreviation: "abbrv",
        Phrase: "phrase",
    },
    Status: {
        Preferred: "preferred",
        Admitted: "admitted",
        Deprecated: "deprecated",
        Superseded: "superseded",
        Obsolete: "obsolete",
        Suspended: "suspended",
        Standardized: "standardized",
        Deleted: "deleted",
        Open: "open",
        Rejected: "rejected",
        Accepted: "accepted",
        Editorial: "editorial",
    }
}

export const feedback = {
    CorrectionTypes: [
        { value: "typographicalError", description: "Typographical error" },
        { value: "missingEquivalent", description: "Missing equivalent" },
        { value: "inaccurateEquivalent", description: "Inaccurate equivalent" },
        { value: "supersededEntry", description: "Superseded entry" },
        { value: "obsoleteEntry", description: "Obsolete entry" },
        { value: "duplicate", description: "Duplicate" },
        { value: "general", description: "General" },
        { value: "other", description: "Other" },
    ]
}
