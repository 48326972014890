import Vue from "vue";
import Vuex from "vuex";
import search from "./Modules/SearchStore";
import record from "./Modules/RecordStore";
import i18n from "./Modules/i18nStore";

export * from "./Actions";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        search,
        record,
        i18n
    }
});
