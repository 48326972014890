<template>
    <div class="contentsOnly">
        <template v-if="data!=null">
            <div class="flex-parent-element">
                <span role="heading" aria-level="3" v-to-lang.children="LanguageCode" :class="[data.termStatus, data.termType, record.recordType]" v-html="data.term"></span>
                <div class="flex-child-element">
                    <small v-to-lang="LanguageCode">{{$t(displayType)}}</small>
                </div>
            </div>
        </template>
        <template v-if="data==null">
            &nbsp;
        </template>
    </div>
</template>

<script>
    import { RecordModel } from "../../Model/RecordModel";


    export default {
        name: "Term",
        data: function () {
            return {
            }
        },
        props: ["data", "record","LanguageCode"],
        components: { },
        watch: { },
        computed: {
            displayType() {
                return this.$t(RecordModel.getDisplayType(this.data.termType, this.data.termStatus, this.record.recordType))
            }
        },
    }
</script>

<style scoped>
    .flex-parent-element {
        display: flex;
    }

    .flex-child-element {
        flex: 1;
        padding-left: 2px;
    }
</style>
