export class SearchResults {
    constructor() {
        this.totalHits = 0
        this.pageSize = 0
        this.results = []
    }
    results: any[]
    totalHits: number
    pageSize: number
}
