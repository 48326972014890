import { Term, Record as record } from "./Constants";

export class RecordModel {
    public constructor(init?: Partial<RecordModel>) {
        Object.assign(this, init);
    }

    recordID: string
    datasource: string
    recordType: string
    space: string
    status: string
    distribution: string
    languages: Array<string> = []
    subjects: Array<string> = []
    bodies: Array<string> = []

    public static getDisplayType(termType: string, termStatus: string, recordType: string): string {

        let label = "";
        let prioritaryStatuses: Array<string> = [Term.Status.Deleted, Term.Status.Rejected, Term.Status.Accepted]
        if (prioritaryStatuses.indexOf(termStatus) >= 0) {
            return termStatus;
        }

        if (termStatus == Term.Status.Open) {
            return "suggestion";
        }

        label = RecordModel.getDisplayTypeForStandardized(termType, termStatus);
        if (label != "") {
            return label;
        }

        if (recordType == record.Type.Term) {
            label = RecordModel.getDisplayTypeForTerm(termType, termStatus);
        } else if (recordType == record.Type.Title) {
            label = RecordModel.getDisplayTypeFoTitle(termStatus);
        } else if (recordType == record.Type.Phraseology) {
            label = RecordModel.getDisplayTypeForPhrase(termType, termStatus);
        } else if (recordType == record.Type.Country) {
            label = RecordModel.getDisplayTypeForCountry(termType, termStatus);

        } else if (recordType == record.Type.ProperName) {
           label = RecordModel.getDisplayTypeForProperName(termType, termStatus);
        }
        return label;
    }

    public static getDisplayTypeForTerm(termType: string, termStatus: string): string {
        let label = 'term';
        if (termType == Term.Type.Full && termStatus == Term.Status.Admitted) {
            label = "synonym";
        }
        else if (termType == Term.Type.Short) {
            label = "short";
        } else if (termType == Term.Type.Variant) {
            label = "variant";
        }
        return label;
    }

    public static getDisplayTypeForStandardized(termType: string, termStatus: string): string {
        let label = "";
        if (termStatus == Term.Status.Standardized) {
            switch (termType) {
                case Term.Type.Short:
                    return "standardized short"
                case Term.Type.Variant:
                    return "standardized variant"
                default:
                    return "standardized";
            }
        } else if (termStatus == Term.Status.Deprecated) {
            return "avoid";
        } else if (termStatus == Term.Status.Superseded) {
            return "superseded";
        } else if (termType == Term.Type.Acronym) {
            return "acronym";
        } 
        return label;
    }

    public static getDisplayTypeFoTitle(termStatus: string): string {
        let label = "title";
        if (termStatus == Term.Status.Admitted) {
            label = "alternate";
        }
        return label;
    }
    public static getDisplayTypeForPhrase(termType: string, termStatus: string): string {
        let label = "phrase";
        if ((termType == Term.Type.Phrase || termType == Term.Type.Full)) {
            if (termStatus == Term.Status.Admitted) {
                label = "optional";
            } else if ((termType == Term.Type.Short)) {
                label = "short";
                if (termStatus == Term.Status.Preferred) {
                    label = "short phrase";
                } else if (termStatus == Term.Status.Admitted) {
                    label = "short optional";
                }
            } else if ((termType == Term.Type.Variant)) {
                label = "variant";
            } else if ((termType == Term.Type.Acronym)) {
                label = "acronym";
            }
        }
        return label;
    }

    public static getDisplayTypeForCountry(termType: string, termStatus: string): string {
        let label = "country";
        if (termType == Term.Type.Full) {
            if (termStatus == Term.Status.Admitted) {
                label = "synonym";
            }
        } else if (termType == Term.Type.Short) {
            label = "country short";
            if (termStatus == Term.Status.Admitted) {
                label = "synonym short";
            }
        } else if (termStatus == Term.Status.Admitted) {
            label = "variant";
        }
        return label;
    }
    public static getDisplayTypeForProperName(termType: string, termStatus: string): string {
        let label = "proper name";

        if ((termType == Term.Type.Full || termType == record.Type.Term)) {
            if (termStatus == Term.Status.Admitted) {
                label = "synonym";
            }
        } else if (termType == Term.Type.Short) {
            label = "short form";

            if (termStatus == Term.Status.Preferred) {
                label = "preferred short";
            }
        } else if (termType == Term.Type.Acronym) {
            label = "acronym";
        } else if (termType == Term.Type.Variant) {
            label = "variant";
        }
        return label;
    }
}
