import 'nprogress/nprogress.css';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
//import { CollapsePlugin } from 'bootstrap-vue'
import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import * as NProgress from "nprogress";
// app.js
import '../../wwwroot/css/unterm.scss'

//import "bootstrap-vue/dist/bootstrap-vue"
//import 'bootstrap/dist/js/bootstrap.js'
declare let UNTERM: any

Vue.filter('maxText', function (value,maxLength =30) {
    if (!value) return ''
    if (value.length > maxLength) return value.substr(0, maxLength)+'...'
    return value;
})
Vue.use(BootstrapVue)
Vue.use(require('vue-shortkey'))
Vue.use(BootstrapVueIcons)
Vue.use(VueReCaptcha, { siteKey: UNTERM.ReCaptchaKey })
Vue.component('font-awesome-icon', FontAwesomeIcon)
NProgress.configure({ showSpinner: false });
