import { bodies } from './bodies';
import { subjects } from './subjects';

export default {
    ar: {
        ...bodies.ar,
        ...subjects.ar,
        home: 'الصفحة الرئيسية',
        about: 'نبذة عن الموقع',
        help: 'المساعدة',
        user_guide: 'دليل المستخدم',
        contact_us: 'اتصل بنا',
        resources: 'الموارد',
        log_in: 'تسجيل الدخول',
        search_database: 'البحث في قاعدة البيانات',
        all_words: 'عن جميع الكلمات',
        exact_phrase: 'عن عبارة محددة',
        combined: 'بحث مدمج',
        boolean: 'بحث منطقي',
        fuzzy: 'بحث تقريبي',
        search: 'بحث',
        enter_search_query: "يرجى إدخال عناصر البحث",
        no_result_found: 'لا توجد نتائج ل',
        advanced_search: 'بحث متقدم',
        clear_all: 'محو جميع الحقول',
        search_in: 'لغة البحث',
        display_results_in: 'عرض النتائج باللغة',
        other: 'لغات أخرى',
        datasets: 'مجموعات البيانات',
        all_datasets: 'كافة مجموعات البيانات',
        all_subjects: 'جميع المواضيع',
        all_bodies: 'جميع الهيئات',
        apply: 'تطبيق الخيارات',
        please_read: 'يرجى قراءة {terms_of_use_link} و{copyright_notice_link} الخاصين بالأمم المتحدة.',
        please_read1: 'يرجى قراءة {terms_of_use_link} المتحدة للاستخدام والإشعار {copyright_notice_link} الخاصين بالأمم المتحدة.',
        terms_of_use: 'شروط الاستخدام',
        copyright_notice: 'الإشعار بحقوق النشر',
        error_executing_search: 'حدث خطأ أثناء عملية البحث، يُرجى المحاولة من جديد',
        results: 'النتائج {0} من {1}',
        other_languages: 'لغات أخرى',
        clear_search_history: 'محو سجل البحث',
        searching_for: 'أنتم تبحثون عن',
        open: 'فتح البطاقة',
        details: 'التفاصيل',
        public_record: 'هذه بطاقة متاحة للعموم',
        internal_record: 'هذه بطاقة داخلية.',
        record_complete: 'هذه بطاقة مكتملة.',
        record_obsolete: 'هذا المقابل لم يعد مستعملا.',
        language_validated: 'تم التحقق من صحة اللغة',
        language_internally_validated: 'تم التحقق داخليا من صحة اللغة',
        language_not_validated: 'لم يتم التحقق من صحة اللغة',
        language_under_review: 'اللغة قيد الاستعراض',
        record_status: 'حالة البطاقة',
        record_distribution: 'من يمكنه الاطلاع على البطاقة؟',
        references: 'المراجع',
        subjects: 'المواضيع',
        subject: 'الموضوع',
        bodies: 'الهيئات',
        english: 'الإنكليزية',
        french: 'الفرنسية',
        spanish: 'الإسبانية',
        russian: 'الروسية',
        chinese: 'الصينية',
        arabic: 'العربية',
        german: 'الألمانية',
        portuguese: 'البرتغالية',
        editorial: 'تحرير',
        share_by_email: 'إرسال رابط لهذه البطاقة عن طريق البريد الإلكتروني',
        send_feedback: 'إرسال ملاحظاتكم',
        send_us_feedback: 'إرسال ملاحظاتكم على هذه البطاقة',
        feedback_success: "تم إرسال الملاحظات بنجاح",
        feedback_error: "فشل في إرسال الملاحظات",
        country: 'البلد',
        phraseology: 'عبارة',
        proper_name: 'اسم علم',
        footnote: 'حاشية',
        term: 'مصطلح',
        title: 'عنوان',
        synonym: 'مرادف',
        complete: 'مكتمل',
        obsolete: 'قديم',
        suggestion: 'اقتراح',
        review_draft: 'استعراض الطرح الأوّلي',
        review_suggestion: 'استعراض الاقتراح',
        draft: 'الطرح الأوّلي',
        ok: 'موافقة',
        review_suggestion_priority: 'إعطاء الأولوية لاستعراض الاقتراح',
        obsolete_candidate: 'بطاقة مرشحة للتصنيف كقديمة',
        superseded: 'استعيض عنه',
        review_draft_priority: 'إعطاء الأولوية لاستعراض الطرح الأولي',
        suspended: 'عُلّق',
        deleted: 'حُذف',
        verification: 'التحقق',
        variant: 'صياغة أخرى',
        alternate: 'مرادف بديل',
        phrase: 'عبارة',
        short_form: 'صيغة مختصرة',
        acronym: 'اختصار',
        avoid: 'تجنب',
        rejected: 'رُفض',
        standardized: 'مُوحّد',
        loading: 'الصفحة الرئيسية',
        error: 'نبذة عن الموقع',
        success: "النجاح",
        search_failed: 'المساعدة',
        definition: 'التعريف',
        description: 'اتصل بنا',
        remark: 'ملاحظة',
        note: 'ملاحظة',
        source: 'المصدر',
        sources: 'المصادر',
        link: 'عن عبارة محددة',
        more: 'بحث مدمج',
        less: 'بحث منطقي',
        updated: 'بحث تقريبي',
        created: 'بحث',
        your_name: 'الاسم',
        enter_name: 'يرجى إدخال الاسم',
        email_address: 'البريد الإلكتروني',
        enter_email: 'يُرجى إدخال البريد الإلكتروني',
        never_share_your_email: 'لن نطلع أي جهة أخرى على بريدك الإلكتروني',
        comment: 'التعليق',
        enter_comment: 'يُرجى إدخال التعليق',
        submit: 'إرسال',
        reset: 'إعادة ضبط',
        validation_error_name: 'يجب إدخال الاسم',
        validation_error_email: 'يجب إدخال عنوان بريد إلكتروني صحيح',
        validation_error_suggestion: "Campo obligatorio",
        validation_error_term: "Campo obligatorio",
        validation_error_comment: 'يجب إدخال تعليق',
        validation_error_captcha: 'يُرجى إكمال اختبار CAPTCHA لإثبات أن المستخدم إنسان وليس آلة',
        comment_sent: 'تم إرسال التعليق',
        comment_not_sent: 'تعذّر إرسال التعليق. يرجى المحاولة مرة أخرى.',
        lanugage_inquiry_message: "هل بحثت بلغة غير الإنكليزية؟",
        search_in_language_message: "تأكد من تحديد اللغة (اللغات) المناسبة في الخيارات المتاحة ضمن بحث متقدم",
        currently_searching_in: "أنت تبحث حاليا في",
        are_important_entries_missing: "هل هناك مدخلات هامة غير مُدرجة؟",
        send_your_suggestions: "!من فضلك أرسل لنا اقتراحك",
        databases: "قواعد بيانات",
        domains: "المجالات",
        press_space_bar: "لتنشيط اضغط على شريط المسافة",
        enter_text_search: "أدخل النص واضغط على إدخال للبحث",
        filter: "منقي",
        country_name: " أسماء البلدان",
        download_country_name: "تنزيل أسماء البلدان",
        currency_designation: "تعيين العملة",
        currency_symbol: "رمز العملة",
        fractional_unit: "الوحدة الكسرية",
        inhabitant: "ساكن",
        capital_inhabitants: "سكان العاصمة",
        monetary_unit_plural: "جمع الوحدة النقدية",
        languages: "اللغات",
        adjective: "الصفة",
        capital_city: "العاصمة",
        monetary_unit: "الوحدة النقدية",
        settings: "الإعدادات",
        fill_in_the_form_to_suggest_a_new_entry: 'يُرجى ملء الاستمارة لاقتراح مدخل مصطلحي جديد ',
        source_language: 'لغة المصدر',
        context: 'السياق',
        target_language: 'اللغة المُترجَم إليها',
        body: 'الهيئة',
        your_email: 'البريد الإلكتروني',
        validation_suggestion: 'اقتراحك مطلوب',
        validation_source: 'مصدر المصطلح مطلوب',
        target: "استهداف",
        update_default_settings: 'تحديث الإعدادات المفتَرَضة تلقائيا',
        settings_saved_in_cookie: "الإعدادات المحفوظة في ملف تعريف الارتباط",
        submit_feedback: 'إرسال التعليقات',
        which_languages_feedback_for: "ما اللغات التي تريد تقديم تعليقات لها",
        email: 'البريد الإلكتروني',
        suggested_term: 'المصطلح المقترح',
        term_source: 'مصدر المدى',
        additional_fields: 'حقول إضافية',
        select: ' تحديد',
        comments: 'تعليقات',
        short: 'قصيرة',
        home_title: 'قاعدة مصطلحات الأمم المتحدة',
        "country short": "الاسم المختصر للبلد",
        remove: "إزالة",
        headerTitle1: "قاعدة مصطلحات",
        headerTitle2: "إدارة شؤون الجمعية العامة والمؤتمرات",
        country_not_found_message: "لم يتم العثور على دولة تطابق معايير البحث المقدمة",
        no_elements_found: "لم يتم العثور على عنصر. يرجى النظر في تغيير محتوى الاستعلام.",
        record_superseded: 'هذا المقابل متجاوز',
        record_ok: 'سجل طيب',
    }
}
