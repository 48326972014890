<template>
    <b-input-group v-to-lang role="search"  class="flex-nowrap shadow rounded-lg bg-white">
        <template v-slot:prepend>
            <u-image src="theme/search.svg" class="magnifier bv-d-sm-down-none" />
        </template>
        <template v-slot:append>
            <div class="search-post">
                <multiselect v-model="searchType" :options="searchTypes" track-by="value"
                             :searchable="false" :close-on-select="true" :show-labels="false" :preselect-first="true"
                             :allow-empty="false"
                             aria-label="Search Type menu. Press Enter to activate the menu, then navigate with arrow keys."
                             class="bg-transparent border-0 shadow-none searchType">
                    <template slot="option" slot-scope="props">
                        <span>{{ props.option.text }}</span>
                    </template>
                </multiselect>
            </div>
            <b-button v-to-lang variant="primary" v-on:click="handleClick" :disabled="disabled" class="search-button" size="lg">
                {{$t('search').toUpperCase()}}
            </b-button>
        </template>
        <SuggestionList v-model="userQuery" :disabled="disabled" @search="raiseOnSearch" ></SuggestionList>
     
    </b-input-group>
</template>

<script lang="ts">
    import Vue from "vue"
    import VueSimpleSuggest from 'vue-simple-suggest/lib'
    import { searchTypes } from "../../Model/Constants"
    import SearchHistory from "./SearchHistory"
    import * as TerminologyDataService from "../../DataServices/terminologyDataService";
    import UImage from '../UImage.vue'
    import Multiselect from 'vue-multiselect'
    import SuggestionList from './SuggestionList.vue'

    export default {
        name: "SearchInput",
        data: function () {
            return {
                suggestionsShowing: false,
                enterKeyPressed: false
            }
        },
        components: {
            SuggestionList,
            VueSimpleSuggest,
            UImage,
            Multiselect
        },
        props: {
            value: {
                required: true
            },
            'disabled': {
                type: Boolean,
                default: false
            },
        },
        computed: {
            hasInput() {
                return this.userQuery
            },
            userQuery: {
                get() {
                    return this.value.searchTerm
                },
                set(newVal) {
                    this.value.searchTerm = newVal
                    this.$emit('input', this.value)
                    if (this.enterKeyPressed) this.raiseOnSearch()
                }
            },
            searchTypes() {
                return [
                    searchTypes.ALL_WORDS,
                    searchTypes.EXACT_PHRASE,
                    //searchTypes.COMBINED,
                    searchTypes.FUZZY,
                    //searchTypes.BOOLEAN,
                ].map(st => { return { value: st.value, text: this.$t(st.text) } })
            },
            searchType: {
                get() {
                    return this.searchTypes.find(x => { return x.value == this.value.searchType }).text
                },
                set(newVal) {
                    this.value.searchType = newVal.value
                    this.$emit('input', this.value)
                }
            }
        },
        methods: {
            clearSearchHistory() {
                SearchHistory.clear()
            },
            async typeAhead() {
                let result: { id: string, title: string, type: string }[] = SearchHistory.getMatchingTerms(this.userQuery)
                    .map((val, ix, ar) => {
                        return { id: `history-${ix}`, title: val, type: 'history' }
                    })
                await TerminologyDataService.typeahead(this.userQuery).then(values => {
                    let items = values.map((val, ix, ar) => {
                        return { id: `search-${ix}`, title: val, type: 'search' }
                    })
                    result.push(...items)
                })
                return new Promise(resolve => {
                    resolve(result)
                })
            },
            onShowList() { this.suggestionsShowing = true },
            onHideList() { this.suggestionsShowing = false },
            onSelect() {
                Vue.nextTick().then(this.raiseOnSearch)
            },
            handleEnterKey() {
                //if enter was pressed without using the suggestions trigger search from input content
                if (!this.$refs.searchInput.hovered || !this.suggestionsShowing) {
                    this.raiseOnSearch()
                }
            },
            handleClick() {
                this.raiseOnSearch()
            },
            raiseOnSearch() {
                SearchHistory.addTerm(this.value.searchTerm)
                this.$emit('search', this.value)
            },
            clearInput() {
                this.$refs.searchInput.setText("")
            }
        }
    }
</script>

<style scoped>
    .search-input-clear {
        position: absolute;
        right: 0.5rem;
        top: 1.5rem;
        color: #adb5bd;
        cursor: pointer;
    }

    .multiselect > > > .multiselect__tags {
        cursor: pointer;
    }

    .search-input-clear:focus, .search-input-clear:hover {
        color: #009EDB;
        opacity: .8;
    }

    .search-button {
        /*border: 1px solid #cde;*/
        border-left: none;
    }

    ul.suggestions {
        top: 100% !important;
    }

    img.magnifier {
        margin-left: 30px;
        width: 30px;
    }

    .misc-item span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
    }
</style>
