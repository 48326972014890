type SearchState = {
    searchResults: SearchResults;
    currentPage: number;
    searchModel: SearchModel;
    datasources: string[];
    bodies: string[];
    subjects: string[],
    searchSettings: SearchSettings
};

import * as Actions from "../Actions"
import { SearchResults } from "../../Model/SearchResults";
import { SearchModel } from "../../Model/SearchModel";
import { SearchSettings } from "../../Model/SearchSettings";

const state: SearchState = {
    searchResults: new SearchResults(),
    currentPage: 0,
    searchModel: new SearchModel(),
    datasources: [],
    bodies: [],
    subjects: [],
    searchSettings: null
};

const mutations = {
    setSearchResults(state: SearchState, result: SearchResults) {
        if (result == null) result = new SearchResults()
        state.searchResults = result
    },
    searchChangePage(state: SearchState, newPage: number) {
        state.currentPage = newPage
    },
    setSearchModel(state: SearchState, searchModel: SearchModel) {       
        if (searchModel === null) {
            searchModel = new SearchModel()
            searchModel.languagesDisplay = (state.searchSettings || {})?.languagesDisplay
            searchModel.searchLanguages = (state.searchSettings || {})?.searchLanguages
        }
        state.searchModel = searchModel
    },
    setDatasources(state: SearchState, datasources: string[]) {
        state.datasources = datasources
    },
    setBodies(state: SearchState, bodies: string[]) {
        state.bodies = bodies
    },
    setSubjects(state: SearchState, subjects: string[]) {
        state.subjects = subjects
    },
    setSearchSettings(state: any, settings: any) {
        state.searchSettings = settings
        if (state.searchModel === null) {
            state.searchModel = new SearchModel()
            if (state.searchSettings) {
                state.searchModel.languagesDisplay = state.searchSettings.languagesDisplay
                state.searchModel.searchLanguages = state.searchSettings.searchLanguages
                state.searchModel.datasets = state.searchSettings.dataSets
            }
        }
    }
}

const getters = {
    getSearchResults: state => {
        return state.searchResults
    },
    getTotalHits: state => {
        return state.searchResults.totalHits
    },
    getPageSize: state => {
        return state.searchResults.pageSize
    },
    getSearchFacets: state => {
        return state.searchResults.facets
    },
    getCurrentPage: state => {        
        return state.currentPage
    },
    getSearchModel: state => {
        return state.searchModel
    },
    getDatasources: state => {
        return state.datasources
    },
    getBodies: state => {
        return state.bodies
    },
    getSubjects: state => {
        return state.subjects
    },
    getSearchSettings: (state) => {
        return state.searchSettings
    }
}

const actions = {
    setSearchResults({ commit }, payload) {
//        let results = terminologyDataService.searchTerm(input, page)
        //    .then(results => { TODO: return promise?
        commit(Actions.SET_SEARCH_RESULTS, payload)
         //   });
    },
    searchChangePage({ commit }, page: number) {
        commit(Actions.SEARCH_CHANGE_PAGE, page)
    },
    setSearchModel({ commit }, searchModel) {
        commit(Actions.SET_SEARCH_MODEL, searchModel)
    },
    setDatasources({ commit }, payload) {
        commit(Actions.SET_DATASOURCES, payload)
    },
    setBodies({ commit }, payload) {
        commit(Actions.SET_BODIES, payload)
    },
    setSubjects({ commit }, payload) {
        commit(Actions.SET_SUBJECTS, payload)
    },
    resetSearch({ commit }) {
        commit(Actions.SEARCH_CHANGE_PAGE, 0)
        commit(Actions.SET_SEARCH_MODEL, null)
    },
    setSearchSettings({ commit }, payload : SearchSettings) {
        commit(Actions.SET_SEARCH_SETTINGS, payload)
    },
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}
