import { bodies } from './bodies';
import { subjects } from './subjects';
export default {
    ru: {
        ...bodies.ru,
        ...subjects.ru,
        home: 'Главная',
        about: 'О сайте',
        help: 'Помощь',
        user_guide: 'Руководство пользователя',
        contact_us: 'Обратная связь',
        resources: 'Ресурсы',
        log_in: 'Вход в систему',
        search_database: 'Поиск по базе данных',
        all_words: 'Все слова',
        exact_phrase: 'Точное словосочетание',
        combined: 'Комбинированный поиск',
        boolean: 'Булев поиск',
        fuzzy: 'Неполные совпадения',
        search: 'Поиск',
        enter_search_query: "Пожалуйста, введите поисковый запрос",
        no_result_found: 'Ничего не найдено для',
        advanced_search: 'Расширенный поиск',
        clear_all: 'Сброс фильтров',
        search_in: 'Языки поиска',
        display_results_in: 'Языки результата',
        other: 'др.',
        datasets: 'Массивы данных',
        all_datasets: 'Все разделы данных',
        all_subjects: 'Все тематики',
        all_bodies: 'Все структуры',
        apply: 'Применить',
        please_read: 'Пожалуйста, внимательно ознакомьтесь с {terms_of_use_link} и {copyright_notice_link} Организации Объединенных Наций.',
        terms_of_use: 'условиями использования',
        copyright_notice: 'уведомлением об авторских правах',
        error_executing_search: 'Ошибка при выполнении поиска; пожалуйста, попробуйте еще раз.',
        results: 'Результаты: {0} из {1}',
        other_languages: 'Другие языки',
        clear_search_history: 'Очистить историю поиска',
        searching_for: 'Вы ищете',
        open: 'Открыть',
        details: 'Подробнее',
        public_record: 'Общедоступная карточка',
        internal_record: 'Внутренняя карточка',
        record_complete: 'Полная карточка',
        record_obsolete: 'Эта карточка устарела.',
        language_validated: 'Утверждено',
        language_internally_validated: 'Прошло внутреннее утверждение',
        language_not_validated: 'Не утверждено',
        language_under_review: 'Ожидает проверки',
        record_status: 'Статус карточки',
        record_distribution: 'Доступ к карточке',
        references: 'Справочная информация',
        subjects: 'Тематики',
        subject: 'тема',
        bodies: 'Органы',
        english: 'английский',
        french: 'французский',
        spanish: 'испанский',
        russian: 'русский',
        chinese: 'китайский',
        arabic: 'арабский',
        german: 'немецкий',
        portuguese: 'португальский',
        editorial: 'редакторы',
        share_by_email: 'Отправить ссылку на эту карточку по электронной почте',
        send_feedback: 'Отправить комментарии',
        send_us_feedback: 'Отправить комментарии к этой карточке',
        feedback_success: "Отзыв успешно отправлен.",
        feedback_error: "Не удалось отправить отзыв",
        country: 'страна',
        phraseology: 'фразеология',
        proper_name: 'имя собственное',
        footnote: 'сноска',
        term: 'термин',
        title: 'название',
        synonym: 'синоним',
        complete: 'полная',
        obsolete: 'устаревшая',
        suggestion: 'предложение',
        review_draft: 'черновик (ожидает проверки)',
        review_suggestion: 'предложение (ожидает проверки)',
        draft: 'черновик',
        ok: 'рабочая',
        review_suggestion_priority: 'важное предложение (ожидает проверки)',
        obsolete_candidate: 'кандидат в устаревшие',
        superseded: 'упразднена',
        review_draft_priority: 'важный черновик (ожидает проверки)',
        suspended: 'не в работе',
        deleted: 'удалена',
        verification: 'подлежит проверке',
        variant: 'вариант',
        alternate: 'альтернативная форма',
        phrase: 'фраза',
        short_form: 'краткая форма',
        acronym: 'сокращение',
        avoid: 'следует избегать',
        rejected: 'отклонено',
        standardized: 'стандартизировано',
        loading: 'Идет загрузка',
        error: 'Ошибка!',
        success: "успех",
        search_failed: 'Ошибка поиска',
        definition: 'Определение',
        description: 'Описание',
        remark: 'Дополнительная информация',
        note: 'Примечание',
        source: 'Источник',
        sources: 'Источники',
        link: 'Ссылка',
        more: 'Развернуть',
        less: 'Свернуть',
        updated: 'Обновлено',
        created: 'Создано',
        your_name: 'Имя и фамилия',
        enter_name: 'Введите имя и фамилию',
        email_address: 'Адрес электронной почты',
        enter_email: 'Введите адрес электронной почты',
        never_share_your_email: 'Мы ни в коем случае не передадим адрес вашей электронной почты в третьи руки.',
        comment: 'Комментарий',
        enter_comment: 'Введите комментарий',
        submit: 'Отправить',
        reset: 'Сброс',
        validation_error_name: 'Имя и фамилия (обязательно)',
        validation_error_email: 'Адрес электронной почты (обязательно)',
        "validation_error_suggestion": "Campo obligatorio",
        "validation_error_term": "Campo obligatorio",
        validation_error_comment: 'Комментарий (обязательно)',
        validation_error_captcha: 'Проверка CAPTCHA.',
        comment_sent: 'Комментарий отправлен.',
        comment_not_sent: 'Комментарий не отправлен. Попробуйте еще раз.',
        lanugage_inquiry_message: "Вы искали на другом языке, кроме английского?",
        search_in_language_message: "Убедитесь, что у вас выбран соответствующий язык(и) в опциях, доступных в расширенном поиске.",
        currently_searching_in: "Вы в настоящее время ищете в",
        are_important_entries_missing: "Отсутствуют важные записи?",
        send_your_suggestions: "Отправьте нам свое предложение!",
        databases: "Разделы данных",
        domains: "домены",
        press_space_bar: "Для активации нажмите пробел",
        enter_text_search: "Введите текст и нажмите Enter для поиска.",
        filter: "Фильтр",
        country_name: "Названия стран",
        download_country_name: "Скачать список названий стран",
        currency_designation: "Обозначение валюты",
        currency_symbol: "Символ валюты",
        fractional_unit: "Дробная единица",
        inhabitant: "Житель",
        capital_inhabitants: "Жители столицы",
        monetary_unit_plural: "Денежная единица",
        languages: "Языки",
        adjective: "Имя прилагательное",
        capital_city: "Столица",
        monetary_unit: "Денежная единица во множественном числе",
        settings: "Настройки",
        fill_in_the_form_to_suggest_a_new_entry: 'Форма для предложения нового термина',
        source_language: 'Язык оригинала',
        context: 'Контекст',
        target_language: 'Язык перевода',
        body: 'Орган',
        your_email: 'Электронная почта',
        validation_suggestion: 'ваше предложение требуется',
        validation_source: 'необходим источник термина',
        target: "Цель",
        update_default_settings: 'Обновить настройки по умолчанию',
        settings_saved_in_cookie: "Настройки сохранены в куки",
        submit_feedback: 'отправить отзыв',
        which_languages_feedback_for: "На каких языках вы хотите оставить отзыв",
        email: 'Эл. адрес',
        suggested_term: 'предлагаемый термин',
        term_source: 'источник термина',
        additional_fields: 'дополнительные поля',
        select: 'Выбрать',
        comments: 'Комментарии',
        short: 'короткий',
        home_title: 'Терминологическая база данных Организации Объединенных Наций',
        remove: "Удалить",
        headerTitle1: "Терминологическая база данных",
        headerTitle2: "Департамент по делам<br/>Генеральной Ассамблеи и<br/>конференционному управлению",
        country_not_found_message: "Страна, соответствующая указанным критериям поиска, не найдена",
        no_elements_found: "Элемент не найден. Пожалуйста, рассмотрите возможность изменения содержания запроса.",
        record_superseded: 'Эта карточка была заменена.',
        record_ok: 'Запись ок',
    }
}
