<template>
    <div class="contentsOnly record-preview" v-if="Record!=null">
        <div v-for="(lang, ix) in previewLanguages" v-if="hasLanguage(lang.name)" :key="lang.code">
            <hr v-if="ix>0" />
            <b-row>
                <b-col cols="2">
                    <h2 v-to-lang :title="$t(lang.name.toLowerCase())" class="language-name-title">{{$t(lang.name.toLowerCase())}}</h2>
                </b-col>
                <b-col cols="10">
                    <language :data="Record[lang.name.toLowerCase()]" :record="Record" :LanguageCode="lang.code" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import Language from "./LanguagePreview.vue"
    import { languages } from "../../Model/Constants";

    export default {
        name: "RecordPreview",
        data: function () {
            return {
                previewLanguages: languages.filter(x => x.isOfficial)
            }
        },
        props: ["Record"],
        components: { Language },
        methods: {
            hasLanguage(language: string) {
                return this.Record != null && this.Record.languages.includes(language) && this.Record[language.toLowerCase()]&& this.Record[language.toLowerCase()].terms.some(x => x.termStatus != 'open');
            }
        }
    }
</script>

<style scoped>
</style>
