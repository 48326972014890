//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Language from "./LanguagePreview.vue"
import { languages } from "../../Model/Constants";

export default {
    name: "RecordPreview",
    data: function () {
        return {
            previewLanguages: languages.filter(x=>x.isOfficial)
        }
    },
    props:["Record"],
    components: { Language },
    methods: {
        hasLanguage(language: string) {
            return this.Record!=null && this.Record.languages.includes(language)
        }
    }
}
