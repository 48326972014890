var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contentsOnly" },
    [
      _vm.LanguageData != null
        ? [
            _c(
              "ul",
              {
                class: ["search-result", _vm.LanguageCode],
                attrs: { dir: _vm.LanguageCode == "ar" ? "rtl" : null },
              },
              _vm._l(_vm.LanguageData.terms, function (term) {
                return term.termStatus != "open"
                  ? _c(
                      "li",
                      [
                        _c("font-awesome-icon", {
                          attrs: { color: "grey", icon: ["fa", "minus"] },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "to-lang",
                              rawName: "v-to-lang.children",
                              value: _vm.LanguageCode,
                              expression: "LanguageCode",
                              modifiers: { children: true },
                            },
                          ],
                          class: [term.termStatus, term.termType],
                          domProps: {
                            innerHTML: _vm._s(_vm.highlightSearch(term.term)),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              0
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.LanguageData == null ? [_vm._v("\n         \n    ")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }