import "./init";
import Vue from "vue";
import App from "../Core/Components/App.vue";
import { mapGetters } from 'vuex'
import store from "../Core/Store/index"
import router from "../Core/router"
import i18n from "../Core/Lang/i18n"
declare let document: Document;


Vue.mixin({
    computed: {
        ...mapGetters(['getCurrentLanguage', 'getSearchSettings'])
    },
    methods: {
        setDefaultLanguage(searchModel) {
            let ctx = this as any;
            if (!ctx.getSearchSettings) return;
            if (ctx.getSearchSettings.languagesDisplay) {
                ctx.$set(searchModel, 'languagesDisplay', ctx.getSearchSettings.languagesDisplay)
            }
            if (ctx.getSearchSettings.searchLanguages) {
                ctx.$set(searchModel, 'searchLanguages', ctx.getSearchSettings.searchLanguages)
            }
            if (ctx.getSearchSettings.dataSets) {
                ctx.$set(searchModel, 'datasets', ctx.getSearchSettings.dataSets)
            }
        },
        sortHelper(data, sort = 'name') {
            const ctx = this;
            return data.sort(function (a, b) {
                let x = (ctx.$t(a[sort]) as string).toLowerCase();
                let y = (ctx.$t(b[sort]) as string).toLowerCase();
                return x.localeCompare(y);
            });
        },
    }
});
function UpdateLangAttribute(el, binding) {    
    const currentLanguage = binding.value ? binding.value : document.getElementsByTagName('html')[0].getAttribute('lang');
    let addLangToChildren = (element) => {
        for (let i = 0; i < element.children.length; i++) {
            if (element.children[i]) {
                setLangAttribute(element.children[i])
                addLangToChildren(element.children[i]);
            }
        }
    }
    let setLangAttribute = (element: any): void => {
        element.setAttribute("lang", currentLanguage);
        addAriaLabel(element, 'deprecated');
        addAriaLabel(element, 'superseded');
    }

    setLangAttribute(el);
    if (binding.modifiers.children)
        addLangToChildren(el);

    function addAriaLabel(element: any, text: String): void {
        if (element.getAttribute('class') && element.getAttribute('class').indexOf(text) != -1) {            
            let span = document.createElement("span");
            if (text == 'deprecated')
                span.setAttribute("aria-label", `The text is crossed out.`);
            else if (text == 'superseded')
                span.setAttribute("aria-label", 'strike through text');
            element.parentElement.prepend(span);
        }
    }
}
function updatePaginationButtonRole(el, binding) {
    setTimeout(() => {
        let buttons = el.getElementsByTagName('button');
        for (const element of buttons) {
            let button = element;
            button.removeAttribute('role')
        }
    }, 1000)   
}

Vue.directive('to-lang', {
    inserted: UpdateLangAttribute,
    update: UpdateLangAttribute
});

Vue.directive('set-pagination', {
    inserted: updatePaginationButtonRole,
    update: updatePaginationButtonRole
});

const app = new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
    watch: {
        getCurrentLanguage: {
            handler: function (newLangCode) {
                this.setBodyLanguage(newLangCode)
            }
        },
        currentRoute(val) {
            if (this.$route.params.langCode)
                this.setBodyLanguage(this.$route.params.langCode)
        }
    },
    computed: {
        ...mapGetters(['getCurrentLanguage']),
        currentRoute() {
            return this.$route.name
        },
    },
    created() {
        if (this.$route.params.langCode)
            this.setBodyLanguage(this.$route.params.langCode)
    },
    methods: {
        setBodyLanguage(langCode: string) {
            if (langCode) {
                document.getElementsByTagName('html')[0].setAttribute('lang', langCode);
                let target = document.body.getAttribute('class').split(' ').find(c => c.startsWith("i18"))
                if (target) {
                    document.body.classList.remove(target)
                    document.body.classList.add(`i18n-${langCode}`)
                }
            }
        }
    }

}).$mount("#application");

export default app;
