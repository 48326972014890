var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c("span", { staticClass: "filter-name" }, [
        _vm._v(_vm._s(_vm.title) + ":"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.dataList, function (tag, index) {
        return index < _vm.limit
          ? _c(
              "b-form-tag",
              {
                key: tag,
                staticClass: "mr-1 active",
                attrs: { title: tag, variant: "filterTag" },
                on: {
                  remove: function ($event) {
                    return _vm.remove(tag)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t(tag)))]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.dataList.length > _vm.limit
        ? [
            _c(
              "b-badge",
              {
                staticClass: "mr-1 active",
                attrs: { variant: "filterTag", id: _vm.title + "-more" },
              },
              [
                _vm._v(
                  "\n            +" +
                    _vm._s(_vm.dataList.length - this.limit) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: _vm.title + "-more",
                  triggers: "hover",
                  placement: "bottom",
                  variant: "tags",
                },
              },
              _vm._l(_vm.dataList.slice(_vm.limit), function (tag, index) {
                return index < 5
                  ? _c(
                      "b-form-tag",
                      {
                        key: tag,
                        staticClass: "mr-1 active",
                        attrs: { title: tag, variant: "filterTag" },
                        on: {
                          remove: function ($event) {
                            return _vm.remove(tag)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(tag)))]
                    )
                  : _vm._e()
              }),
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }