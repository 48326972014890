var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    {
      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
      staticClass: "flex-nowrap shadow rounded-lg bg-white",
      attrs: { role: "search", "aria-label": "UN Terminology Database" },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _c("u-image", {
                staticClass: "magnifier bv-d-sm-down-none",
                attrs: { src: "theme/search.svg" },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "search-button",
                  attrs: {
                    variant: "primary",
                    disabled: _vm.disabled,
                    size: "lg",
                  },
                  on: { click: _vm.handleClick },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("search").toUpperCase()) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "misc-item-above",
          fn: function (ref) {
            var suggestions = ref.suggestions
            var query = ref.query
            return [
              _c("div", { staticClass: "misc-item" }, [
                _c(
                  "span",
                  { directives: [{ name: "to-lang", rawName: "v-to-lang" }] },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("searching_for")) +
                        " " +
                        _vm._s(query) +
                        "."
                    ),
                  ]
                ),
              ]),
            ]
          },
        },
        {
          key: "suggestion-item",
          fn: function (ref) {
            var suggestion = ref.suggestion
            var query = ref.query
            return [
              _c(
                "span",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  class: [suggestion.type],
                },
                [_vm._v(_vm._s(suggestion.title))]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userQuery,
            expression: "userQuery",
          },
          {
            name: "shortkey",
            rawName: "v-shortkey.focus",
            value: ["alt", "s"],
            expression: "['alt', 's']",
            modifiers: { focus: true },
          },
        ],
        ref: "searchInput",
        staticClass: "form-control form-control-lg my-2",
        attrs: {
          type: "text",
          placeholder: _vm.$t("search_database") + "...",
          maxlength: 244,
          id: "searchInput",
          "aria-label": _vm.$t("enter_text_search"),
          "aria-describedby": "basic-addon2",
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.userQuery },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.handleEnterKey($event)
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.userQuery = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.hasInput
        ? _c(
            "svg",
            {
              staticClass:
                "search-input-clear pointer hover-red5 svg-inline--fa fa-times-circle fa-w-16 fa-fw fa-lg",
              attrs: {
                "aria-hidden": "true",
                focusable: "false",
                "data-prefix": "fas",
                "data-icon": "times-circle",
                role: "img",
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 512 512",
              },
              on: { click: _vm.clearInput },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z",
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }