<template>
    <div v-if="Record!=null" class="record-info">
        <h5 v-to-lang>{{$t(Record.dbName)}}</h5>
        <ul>
            <li v-to-lang v-for="item in Record.bodies" :title="$t(item)" v-b-tooltip.hover>{{$t(item)}}</li>
            <li v-to-lang v-for="item in Record.subjects" :title="$t(item)" v-b-tooltip.hover>{{$t(item)}}</li>
        </ul>
    </div>
</template>

<script>
    import { subjects } from '../../Lang/subjects'
    import { bodies } from '../../Lang/bodies'

    export default {
        name: "RecordInfo",
        props: ["Record"],
        i18n: {
            messages: {
                en: {
                    ...subjects.en,
                    ...bodies.en
                },
                es: {
                    ...subjects.es,
                    ...bodies.es
                },
                fr: {
                    ...subjects.fr,
                    ...bodies.fr
                },
                ru: {
                    ...subjects.ru,
                    ...bodies.ru
                },
                ar: {
                    ...subjects.ar,
                    ...bodies.ar
                },
                zh: {
                    ...subjects.zh,
                    ...bodies.zh
                }

            }
        }, watch: {},
        methods: {}
    }
</script>

<style scoped>
    .record-info {
        padding-left: 30px;
    }
</style>
