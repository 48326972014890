var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Record != null
    ? _c("div", { staticClass: "record-info" }, [
        _c("h5", { directives: [{ name: "to-lang", rawName: "v-to-lang" }] }, [
          _vm._v(_vm._s(_vm.Record.dbName)),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          [
            _vm._l(_vm.Record.bodies, function (item) {
              return _c(
                "li",
                {
                  directives: [
                    { name: "to-lang", rawName: "v-to-lang" },
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: { title: _vm.$t(item) },
                },
                [_vm._v(_vm._s(_vm.$t(item)))]
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.Record.subjects, function (item) {
              return _c(
                "li",
                {
                  directives: [
                    { name: "to-lang", rawName: "v-to-lang" },
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  attrs: { title: _vm.$t(item) },
                },
                [_vm._v(_vm._s(_vm.$t(item)))]
              )
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }