<template>
    <div class="mb-2">
        <b-row class="filter-control">
            <b-col :class="getCurrentLanguage == 'ar' ? 'text-right':''">
                <b-button v-to-lang variant="link" v-b-toggle.advanced-filters-collapse class="text-dark text-nowrap">
                    <font-awesome-icon :icon="['fa', 'angle-right']" class="mr-1 closed" />
                    <font-awesome-icon :icon="['fa', 'angle-down']" class="mr-1 open" />
                    {{$t('advanced_search')}}
                </b-button>
            </b-col>
            <b-col v-to-lang :class="getCurrentLanguage == 'ar'?'text-left':'text-right'"><b-button class="filter-clear" @click="onClearAllClick">{{$t('clear_all')}}</b-button></b-col>
        </b-row>
        <hr class="mb-2 mt-0" />
        <b-collapse id="advanced-filters-collapse" class="mt-2" ref="advancedFiltersCollapse" @hidden="handleHidden" @show="handleShown">
            <b-row class="mb-2 justify-content-between">
                <b-col sm="auto">
                    <b-form-group id="fieldset-searchIn"
                                  v-to-lang.children
                                  :label="$t('search_in')"
                                  label-class="text-nowrap">
                        <b-button v-for="(lang, idx) in searchInlanguages"
                                  :key="idx"
                                  :id="'d-'+lang.name"
                                  :title="$t(lang.name.toLowerCase())"
                                  :pressed.sync="lang.state"
                                  @click="onSearchInClick"
                                  variant="language" :class="{'ml-1': true, 'd-none': !lang.isOfficial && !searchInOtherLangVisible}">
                            {{ $t(lang.name.toLowerCase()) }}
                        </b-button>
                        <b-button :title="$t('other_languages')"
                                  @click="searchLanguageButtonToggle()"
                                  variant="language" :class="{'ml-1':true,'active':otherSearchInActive}">
                            <font-awesome-icon :icon="['fa', 'angle-right']" :class="{'mr-1':true, 'closed':searchInOtherLangVisible}" />
                            <font-awesome-icon :icon="['fa', 'angle-left']" :class="{'mr-1':true, 'closed':!searchInOtherLangVisible}" />
                            {{$t('other')}}
                        </b-button>
                    </b-form-group>
                </b-col>
                <b-col sm="auto">
                    <b-form-group v-to-lang.children id="fieldset-display-in"
                                  :label="$t('display_results_in')"
                                  label-class="text-nowrap">
                        <b-button v-for="(lang, idx) in displayInLanguages"
                                  :key="idx"
                                  :id="'ds-'+lang.name"
                                  :title="$t(lang.name.toLowerCase())"
                                  :pressed.sync="lang.state"
                                  @click="onDisplayInClick"
                                  variant="language" :class="{'ml-1': true, 'd-none': !lang.isOfficial && !displayInOtherLangVisible}">
                            {{ $t(lang.name.toLowerCase()) }}
                        </b-button>
                        <b-button title="Other languages"
                                  @click="displayLanguageButtonToggle()"
                                  variant="language" :class="{'ml-1':true,'active':otherDisplayActive}">
                            <font-awesome-icon :icon="['fa', 'angle-right']" :class="{'mr-1':true, 'closed':displayInOtherLangVisible}" />
                            <font-awesome-icon :icon="['fa', 'angle-left']" :class="{'mr-1':true, 'closed':!displayInOtherLangVisible}" />
                            {{$t('other')}}
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col>
                    <b-form-group v-to-lang id="datasets">
                        <multiselect-filter v-model="selectedDatasets" :options="datasets" :limit="3" name="datasets"></multiselect-filter>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group v-to-lang id="subjects">
                        <multiselect-filter v-model="selectedSubjects" :options="subjects" :limit="2" name="subjects"></multiselect-filter>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group v-to-lang id="bodies">
                        <multiselect-filter v-model="selectedBodies" :options="bodies" :limit="3" name="bodies"></multiselect-filter>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row align-h="end">
                <b-col v-to-lang offset="11" class="text-right"><b-button variant="primary" @click="onApplyClick">{{$t('apply')}}</b-button></b-col>
            </b-row>
        </b-collapse>
    </div>
</template>

<script>
    import MultiselectFilter from './MultiselectFilter.vue'
    import { SearchModel } from "../../Model/SearchModel";
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { languages as allLanguages } from "../../Model/Constants"
    import * as TerminologyDataService from "../../DataServices/terminologyDataService";
    import * as Actions from '../../Store/Actions'
    import { mapActions, mapGetters } from 'vuex'
    import { faAngleDown, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

    library.add(faAngleDown, faAngleRight, faAngleLeft)


    export default {
        name: "SearchFilters",
        data: function () {
            return {
                searchInOtherLangVisible: false,
                displayInOtherLangVisible: false,
                recordtypes: [
                    { name: 'Terms', code: 'term' },
                    { name: 'Titles', code: 'title' },
                    { name: 'Countries', code: 'country' },
                    { name: 'Phraseology', code: 'phraseology' },
                    { name: 'Footnotes', code: 'footnote' }
                ],
                innerdatasets: []
            }
        },
        components: {
            MultiselectFilter
        },
        mounted() {
            this.checkCache()
        },
        props: {
            value: {
                required: true,
            },
            'disabled': {
                type: Boolean,
                default: false
            },
        },
        computed: {
            otherDisplayActive() {
                return this.displayInOtherLangVisible
                    || this.displayInLanguages
                        .filter(x => x.state)
                        .map(x => x.name)
                        .some(name => allLanguages
                            .filter(x => !x.isOfficial)
                            .map(x => x.name)
                            .includes(name));
            },
            otherSearchInActive() {
                return this.displayInOtherLangVisible
                    || this.searchInlanguages
                        .filter(x => x.state)
                        .map(x => x.name)
                        .some(name => allLanguages
                            .filter(x => !x.isOfficial)
                            .map(x => x.name)
                            .includes(name));
            },
            datasets() {
                let datasources = this.sortHelper(this.getDatasources);
                return datasources.map(v => ({ name: this.$t(v.name), code: v.name }));
            },
            bodies() {
                let bodies = this.sortHelper(this.getBodies);
                return bodies.map(v => ({ name: this.$t(v.name), code: v.code }));
            },
            subjects() {
                let subjects = this.sortHelper(this.getSubjects);
                return subjects.map(v => ({ name: this.$t(v.name), code: v.code }));
            },
            ...mapGetters([
                'getSearchSettings',
                'getDatasources',
                'getBodies',
                'getSubjects',
            ]),
            searchInlanguages: {
                get() {
                    return allLanguages.map(lang => {
                        return {
                            state: this.searchModel.searchLanguages.find(l => l == lang.code) != null,
                            ...lang
                        }
                    })
                },
                set(newVal) {
                    this.searchModel.searchLanguages = newVal.map(x => x.code)
                    this.$emit('input', this.searchModel)
                }
            },
            displayInLanguages: {
                get() {
                    return allLanguages.map(lang => {
                        return {
                            state: this.searchModel.languagesDisplay.find(l => l == lang.code) != null,
                            ...lang
                        }
                    })
                },
                set(newVal) {
                    this.searchModel.languagesDisplay = newVal.map(x => x.code)
                    this.$emit('input', this.searchModel)
                }
            },
            selectedDatasets: {
                get() {
                    return this.datasets.filter(ds => (this.searchModel.datasets||[]).find(d => d == ds.code) != null)
                },
                set(newVal) {
                    this.searchModel.datasets = newVal.map(x => x.code)
                    this.$emit('input', this.searchModel)
                }
            },
            selectedBodies: {
                get() {
                    return this.bodies.filter(ds => (this.searchModel.bodies||[]).find(d => d == ds.code) != null)
                },
                set(newVal) {
                    this.searchModel.bodies = newVal.map(x => x.code)
                    this.$emit('input', this.searchModel)
                }
            },
            selectedSubjects: {
                get() {
                    return this.subjects.filter(ds => (this.searchModel.subjects || []).find(d => d == ds.code) != null)
                },
                set(newVal) {
                    this.searchModel.subjects = newVal.map(x => x.code)
                    this.$emit('input', this.searchModel)
                }
            },
            searchModel: {
                get(): SearchModel {
                    return this.value
                },
                set(newVal: SearchModel) {
                    this.$emit('input', newVal)
                }
            },
        },
        methods: {
            ...mapActions({
                setDatasources: Actions.SET_DATASOURCES,
                setBodies: Actions.SET_BODIES,
                setSubjects: Actions.SET_SUBJECTS,
            }),
            searchLanguageButtonToggle() {
                this.searchInOtherLangVisible = !this.searchInOtherLangVisible;
                if (this.searchInOtherLangVisible)
                    setTimeout(() => document.getElementById('d-German').focus(), 200);
            },
            displayLanguageButtonToggle() {
                this.displayInOtherLangVisible = !this.displayInOtherLangVisible;
                if (this.displayInOtherLangVisible)
                    setTimeout(() => document.getElementById('ds-German').focus(), 200);
            },
            async checkCache() {
                if (this.getDatasources.length == 0) {
                    let result = await TerminologyDataService.getDatasources()
                    this.setDatasources(result)
                }

                if (this.getBodies.length == 0) {
                    let result = await TerminologyDataService.getBodies()
                    let translated = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                    this.setBodies(translated)
                }

                if (this.getSubjects.length == 0) {
                    let result = await TerminologyDataService.getSubjects()
                    let translated = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                    this.setSubjects(translated)
                }
            },
            onClearAllClick() {
                this.value.clearFilters(this.getSearchSettings)
                this.$emit('input', this.value)
                this.$emit('apply', this.value)
            },
            onSearchInClick() {
                this.value.searchLanguages = this.searchInlanguages.reduce((col, lang) => {
                    if (lang.state) {
                        col.push(lang.code);
                    }
                    return col;
                }, [])
                this.$emit('input', this.value)
            },
            onDisplayInClick() {
                this.value.languagesDisplay = this.displayInLanguages.reduce((col, lang) => {
                    if (lang.state) {
                        col.push(lang.code);
                    }
                    return col;
                }, [])
                this.$emit('input', this.value)
            },
            onApplyClick() {
                this.$emit('apply', this.value)
            },
            handleShown() {
                this.$emit("shown")
            },
            handleHidden() {
                this.$emit("hidden")
            }
        }
    }
</script>

<style scoped lang="scss">
    .multiselect::v-deep .multiselect__content-wrapper {
        min-width: 300px;
    }

    .multiselect::v-deep .multiselect__tags {
        cursor: pointer;
    }

    button.collapsed > .open,
    button:not(.collapsed) > .closed {
        display: none;
    }

    .btn.text-dark {
        font: bold 16px/30px Roboto;
        color: #3E3E3E;

        &:focus {
            text-decoration: none;
        }
    }
</style>
