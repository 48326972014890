import { bodies } from './bodies';
import { subjects } from './subjects';

export default {
    "en": {
        ...bodies.en,
        ...subjects.en,
        "about": "About",
        "acronym": "acronym",
        "advanced_search": "Advanced search",
        "all_bodies": "All bodies",
        "all_datasets": "All data sets",
        "all_subjects": "All subjects",
        "all_words": "All words",
        "alternate": "alternate",
        "apply": "Apply",
        "arabic": "Arabic",
        "avoid": "avoid",
        "bodies": "Bodies",
        "boolean": "Boolean",
        "chinese": "Chinese",
        "clear_all": "Clear all",
        "clear_search_history": "Clear search history",
        "combined": "Combined",
        "comment": "Comment",
        "comment_not_sent": "Your comment could not be sent. Please try again.",
        "comment_sent": "Your comment has been sent.",
        "complete": "complete",
        "contact_us": "Contact us",
        "copyright_notice": "Copyright Notice",
        "country": "country",
        "created": "Created",
        "datasets": "Data sets",
        "definition": "Definition",
        "deleted": "deleted",
        "description": "Description",
        "details": "Details",
        "display_results_in": "Display results in",
        "draft": "draft",
        "editorial": "Editorial",
        "email_address": "Email address",
        "english": "English",
        "enter_comment": "Enter comment",
        "enter_email": "Enter email",
        "enter_name": "Enter name",
        "error": "Error!",
        "success": "Success",
        "error_executing_search": "Error while executing search, please try again",
        "exact_phrase": "Exact phrase",
        "footnote": "footnote",
        "french": "French",
        "fuzzy": "Fuzzy",
        "german": "German",
        "help": "Help",
        "home": "Home",
        "internal_record": "This is an internal record",
        "language_internally_validated": "The language has been internally validated",
        "language_not_validated": "The language is not validated",
        "language_under_review": "The language is under review",
        "language_validated": "The language has been validated",
        "less": "Less",
        "link": "Link",
        "loading": "Loading",
        "log_in": "Log in",
        "more": "More",
        "never_share_your_email": "We will never share your email with anyone else.",
        "note": "Note",
        "obsolete": "obsolete",
        "obsolete_candidate": "obsolete candidate",
        "ok": "ok",
        "open": "Open",
        "other": "Other",
        "other_languages": "Other languages",
        "phrase": "phrase",
        "phraseology": "phraseology",
        "please_read": "Please read carefully the United Nations {terms_of_use_link} and {copyright_notice_link}.",
        "portuguese": "Portuguese",
        "proper_name": "proper name",
        "public_record": "This is a public record.",
        "record_complete": "This record is complete.",
        "record_distribution": "Record distribution",
        "record_obsolete": "This record is obsolete.",
        "record_status": "Record status",
        "references": "References",
        "rejected": "rejected",
        "remark": "Remark",
        "reset": "Reset",
        "resources": "Resources",
        "results": "Results {0} of {1}",
        "review_draft": "review draft",
        "review_draft_priority": "review draft priority",
        "review_suggestion": "review suggestion",
        "review_suggestion_priority": "review suggestion priority",
        "russian": "Russian",
        "search": "Search",
        "enter_search_query": "Please enter a search query",
        "no_result_found": 'No Results Found for',
        "search_database": "Search database",
        "search_failed": "Search Failed",
        "search_in": "Search in",
        "searching_for": "You are searching for",
        "send_feedback": "Send feedback",
        "send_us_feedback": "Send us feedback about this record",
        "feedback_success":"Feedback submitted successfully.",
        "feedback_error":"Failed To submit feedback.",
        "share_by_email": "Share a link to this record by email",
        "short_form": "short form",
        "source": "Source",
        "sources": "Sources",
        "spanish": "Spanish",
        "standardized": "standardized",
        "subjects": "Subjects",
        "subject": "Subject",
        "submit": "Submit",
        "suggestion": "suggestion",
        "superseded": "superseded",
        "suspended": "suspended",
        "synonym": "synonym",
        "term": "Term",
        "terms_of_use": "Terms of Use",
        "title": "Title",
        "updated": "Updated",
        "user_guide": "User guide",
        "validation_error_captcha": "Please complete the CAPTCHA challenge.",
        "validation_error_comment": "A comment is required.",
        "validation_error_email": "A valid email address is required.",
        "validation_error_suggestion": "Your suggestion is required",
        "validation_error_term": "Term source is required",
        "validation_error_name": "Your name is required.",
        "variant": "variant",
        "verification": "verification",
        "your_name": "Your name",
        "lanugage_inquiry_message": "Did you search in a language other than English?",
        "search_in_language_message": "Make sure you have the appropriate language(s) selected in the options available in Advanced Search",
        "currently_searching_in": "You are currently searching in",
        "country short": "country short",
        are_important_entries_missing: "Are important entries missing?",
        send_your_suggestions: "Please send us your suggestion!",
        databases: "Databases",
        domains: "Domains",
        press_space_bar: "To activate press space bar",
        enter_text_search: "Enter text and press Enter to search.",
        exit_suggestions: "Press Escape to exit suggestion list",
        filter: "Filter",
        country_name: "Country names",
        download_country_name: "Download country names",
        currency_designation: "Currency Designation",
        currency_symbol: "Currency Symbol",
        fractional_unit: "Fractional Unit",
        inhabitant: "Inhabitant",
        capital_inhabitants: "Capital Inhabitants",
        monetary_unit_plural: "Monetary Unit Plural",
        languages: "Languages",
        adjective: "Adjective",
        capital_city: "Capital City",
        monetary_unit: "Monetary Unit",
        settings: "Settings",
        fill_in_the_form_to_suggest_a_new_entry: 'Fill in the form to suggest a new entry',
        source_language: 'Source Language',
        context: 'Context',
        target_language: 'Target Language',
        body: 'Body',
        your_email: 'Your Email',
        validation_suggestion: 'Your suggestion is required',
        validation_source: 'Term source is required',
        target: "Target",
        update_default_settings: 'Update Default Settings',
        settings_saved_in_cookie: "Settings saved in cookie",
        submit_feedback: 'Submit Feedback',
        which_languages_feedback_for: "Which languages do you want to provide feedback for?",
        email: 'Email',
        suggested_term: 'Suggested term',
        term_source: 'Term source',
        additional_fields: 'Additional fields',
        select: 'Select',
        comments: 'Comments',
        short: 'Short',
        home_title: 'The United Nations Terminology Database',
        remove: "Remove",
        headerTitle1: "The United Nations",
        headerTitle2: "Department for General Assembly<br/>and Conference Management",
        country_not_found_message: "No country found matching provided search criteria",
        no_elements_found: "No element found. Please consider changing the query content.",
        record_superseded: 'This record has been superseded.',
        record_ok: 'Record Ok',
    }
}
