<template>
    <div class="contentsOnly">
        <b-row class="mt-2">
            <b-col cols="11">
                <template v-if="data!=null">
                    <ul v-for="term in terms" :dir="data.isRTL ? 'rtl':'ltr'">
                        <li v-if="term.termStatus!='open'">
                            <term :LanguageCode="LanguageCode" :data="term" :record="record" />
                        </li>
                    </ul>
                    <template v-if="data.definition">
                        <h4 v-to-lang> {{ $t('definition') }}</h4>
                        <div :dir="data.isRTL ? 'rtl':'ltr'" v-to-lang.children="LanguageCode" v-html="data.definition"></div>
                    </template>
                </template>
                <template v-if="data==null">
                    &nbsp;
                </template>
            </b-col>
            <b-col class="status-icons" v-to-lang.children>
                <i :title="$t('language_validated')" :class="['info', 'languagestatus-validated']" v-if="isValidated" v-b-tooltip.hover>
                    <span :aria-label="$t('language_validated')"></span>
                </i>
                <i :title="$t('record_obsolete')" :class="['info', 'recordstatus-obsolete']" v-if="showObsolete" v-b-tooltip.hover>
                    <span :aria-label="$t('record_obsolete')"></span>
                </i>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Term from "./TermPreview.vue"

    export default {
        name: "Language",
        data: function () {
            return {
            }
        },
        props: ["data", "record", "LanguageCode"],
        computed: {
            terms() {
                var _termSortExpression = (status) => {
                    switch (status.toLowerCase()) {
                        case 'standardized':
                            return 0;
                        case 'preferred':
                            return 1;
                        case 'admitted':
                            return 2;
                        case 'superseded':
                            return 3;
                        case 'deprecated':
                            return 4;
                    }
                    return 99;
                }
                return this.data.terms.sort(function (a, b) {
                    return _termSortExpression(a.termStatus) - _termSortExpression(b.termStatus)
                })
            },
            isValidated: {
                get(): Boolean {
                    if (this.data.validationStatus == null) return false
                    return this.data.validationStatus.toLowerCase() === 'validated'
                }
            },
            showObsolete: {
                get(): Boolean {
                    return this.index == 0 && (this.record.status.toLowerCase() == 'obsolete')
                }
            }
        },
        components: { Term },
        watch: {},
        methods: {}
    }
</script>

<style scoped type="text/less" lang="scss">
    ul {
        list-style: none;
        padding: 0;

        li {
            font-size: 1em;
            color: #3E3E3E;
        }
    }

    h4 {
        font-size: 1.1em;
    }
</style>
