//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as NProgress from "nprogress"
import * as TerminologyDataService from "../../DataServices/terminologyDataService"
import LanguageResult from "./LanguageResult.vue"
import RecordPreview from "./RecordPreview.vue"
import RecordInfo from "./RecordInfo.vue"
import { mapActions, mapGetters } from 'vuex'
import * as Actions from '../../Store/Actions'
import { SearchResults } from "../../Model/SearchResults"
import SearchInput from "./SearchInput.vue"
import SearchFilters from "./SearchFilters.vue"
import SearchFiltersPreview from "./SearchFiltersPreview.vue"
import SidePanel from "./SidePanel.vue"
import { languages } from "../../Model/Constants"
import { SearchModel } from "../../Model/SearchModel"
import Multiselect from 'vue-multiselect'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faAngleUp } from '@fortawesome/free-solid-svg-icons'

library.add(faEnvelope)
library.add(faAngleUp)

export default {
    name: "SearchPane",
    data: function () {
        return {
            searchModel: new SearchModel(),
            loading: false,
            noResultsText: 'No results yet',
            languageOptions: [...languages],
            advancedFiltersVisible: false,
            searchTerm: null,
            highlightData: null
        }
    },
    components: {
        LanguageResult,
        RecordInfo,
        RecordPreview,
        SearchInput,
        SearchFilters,
        SearchFiltersPreview,
        Multiselect,
        SidePanel
    },
    mounted() {
        this.setDefaultLanguage(this.searchModel);
        let prevSearch = this.getSearchModel
        if (prevSearch != null && prevSearch.hasSearchTerm()) {
            this.searchModel = prevSearch
        }
        if (this.queryTerm) {
            let query = this.$route.query
            //Handle array query parameters with single value
            let assignProp = (arrayprop) => {
                if (typeof (query[arrayprop]) === 'string') {
                    query[arrayprop] = [query[arrayprop]]
                }
                if (typeof (query[arrayprop]) !== 'undefined') {
                    this.searchModel[arrayprop] = query[arrayprop]
                }
            }
            this.setDefaultLanguage(this.searchModel);
            if (query.searchType) this.searchModel.searchType = parseInt(query.searchType)
            assignProp("datasets")
            assignProp("subjects")
            assignProp("bodies")
            assignProp("languagesDisplay")
            assignProp("searchLanguages")

            this.searchModel.searchTerm = this.queryTerm

            if (this.searchModel.searchTerm.trim() != "") {
                this.doSearch()
            }
        }
    },
    computed: {
        ...mapGetters([
            'getSearchResults',
            'getCurrentPage',
            'getSearchModel',
            'getTotalHits',
            'getPageSize',
            'getSearchFacets',
            'getCurrentLanguage',
            'getSearchSettings'
        ]),
        availableFacets() {
            let { subjects, domains, dbNames } = this.getSearchFacets || { subjects: [], domains: [], dbNames: [] };
            return subjects.length + domains.length + dbNames.length;
        },
        queryTerm() {
            return this.$route.query.searchTerm;
        },
        selectedLangs() {
            return languages.filter(lang => { return this.searchModel.searchLanguages.find(x => x == lang.code) })
        },
        CurrentPage: {
            get() { return this.getCurrentPage + 1 },
            set(value) { this.changePage(value - 1) }
        },
        tableFields: {
            get() {
                const selectedLangs = languages.filter(lang => { return this.searchModel.languagesDisplay.find(x => x == lang.code) })
                return [
                    { key: 'actions', label: '', sortable: false },
                    ...selectedLangs.map(l => { return { key: l.name.toLowerCase(), langCode: l.code, sortable: true, isLang: true, headerTitle: this.$t(l.name.toLowerCase()) } }),
                    { key: 'recordinfo', label: '', sortable: false, langCode: '', isLang: false }
                ]
            }
        },
        facettingRequest() {
            const searchModel: SearchModel = this.searchModel;
            return searchModel.subjects.length > 0 || searchModel.bodies.length > 0
                || searchModel.datasets.length > 0 || this.getCurrentPage > 0;
        }
    },
    watch: {
        getSearchSettings() {
            this.setDefaultLanguage(this.searchModel);
        },
        loading(val: boolean) {
            if (val) {
                NProgress.start();
            } else {
                NProgress.done();
            }
        }
    },
    methods: {
        ...mapActions({
            setSearchResults: Actions.SET_SEARCH_RESULTS,
            changePage: Actions.SEARCH_CHANGE_PAGE,
            setSearchModel: Actions.SET_SEARCH_MODEL,
        }),
        highlightRow(data) {
            this.highlightData = data;
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (this.highlightData && this.highlightData.name == 'dataset') {
                return item.dbName == this.highlightData.value ? 'highlight-row' : '';
            }
            if (this.highlightData && this.highlightData.name == 'body') {
                return item.bodies.includes(this.highlightData.value) ? 'highlight-row' : '';
            }
            if (this.highlightData && this.highlightData.name == 'subject') {
                return item.subjects.includes(this.highlightData.value) ? 'highlight-row' : '';
            }
            return;
        },
        doSearch() {
            document.querySelector('th')?.click()
            this.searchTerm = this.searchModel.searchTerm.trim()
            this.setSearchModel(this.searchModel)
            this.changePage(0)
            this.setSearchUrl(this.searchTerm)
            this.fetchData()
        },
        setSearchUrl(searchTerm) {
            let resolved = this.$router.resolve({
                params: { langCode: this.getCurrentLanguage }, query: this.searchModel
            })
            let currentHref = window.location.href.replace(window.location.origin, "");
            if (currentHref != resolved.href) {
                this.$router.push({
                    params: { langCode: this.getCurrentLanguage }, query: this.searchModel
                }, null, function () { console.log("Navigation aborted" + arguments) })
            }
        },
        async fetchData() {
            if (this.searchModel.searchTerm.trim() === '') {
                this.setSearchResults(new SearchResults())
                return
            }

            this.$data.loading = true;

            try {
                let results = await TerminologyDataService.searchTerm(this.getSearchModel, this.getCurrentPage, this.facettingRequest);
                this.setSearchResults(results)
                this.$data.loading = false
                if (this.getSearchResults.results.length == 0) {
                    this.$data.noResultsText = "No terms found matching provided search criteria"
                }
            } catch (e) {
                console.log('error', e);
                this.setSearchResults(null)
                this.$data.noResultsText = this.$t('error_executing_search')
                this.$bvToast.toast(this.$t('search_failed'), {
                    title: 'Error!',
                    variant: "danger",
                    autoHideDelay: 3000,
                    appendToast: false
                })
                this.$data.loading = false
            }
        },
        handlePageChange(pageNumber) {
            this.changePage(pageNumber - 1);
            this.fetchData();
        },
        viewTerm(item) {
            const resolved = this.$router.resolve({ name: 'viewTerm', params: { recordId: item.recordID, langCode: this.getCurrentLanguage } })
            window.open(resolved.href, "record-" + item.recordID)
        },
        handleSorting(ctx) {
            if (ctx.sortBy) {
                this.searchModel.sortBy = this.languageOptions.find(x => x.name.toLowerCase() == ctx.sortBy.toLowerCase()).code;
                this.searchModel.sortDirection = ctx.sortDesc ? "desc" : "asc"
                this.handlePageChange(1)
            } else {
                this.searchModel.sortBy = '';
                this.searchModel.sortDirection = ''
            }
        },
        setAdvancedSearchVisible(visible: boolean) {
            this.advancedFiltersVisible = visible
        },
        goToSuggestions() {
            this.$router.push({
                name: 'suggestion',
                query: { q: this.searchTerm }
            })
        }
    }
}
