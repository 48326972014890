var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-simple-suggest search-input designed",
      class: { focus: _vm.suggestionsShowing },
    },
    [
      _c(
        "div",
        {
          staticClass: "input-wrapper",
          attrs: {
            role: "combobox",
            "aria-haspopup": "listbox",
            "aria-owns": "61-suggestions",
            "aria-expanded": _vm.suggestionsShowing,
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "shortkey",
                rawName: "v-shortkey.focus",
                value: ["alt", "s"],
                expression: "['alt', 's']",
                modifiers: { focus: true },
              },
            ],
            ref: "searchInput",
            staticClass: "form-control form-control-lg my-2",
            attrs: {
              id: "autoComplete",
              type: "text",
              autocomplete: "off",
              placeholder: _vm.$t("search_database") + "...",
              maxlength: 244,
              "aria-label": _vm.$t("enter_text_search"),
              "aria-describedby": "basic-addon2",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.userQuery },
            on: {
              input: _vm.updateValue,
              keydown: _vm.handleListNavigation,
              blur: function ($event) {
                _vm.suggestionsShowing = _vm.hoveringSuggestions
              },
              focus: _vm.showSuggestions,
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                ) {
                  return null
                }
                _vm.suggestionsShowing = false
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { height: "1px", width: "10px" },
              attrs: { id: "clear-input" },
              on: { click: _vm.clearInput },
            },
            [
              _vm.hasInput
                ? _c(
                    "svg",
                    {
                      staticClass:
                        "search-input-clear pointer hover-red5 svg-inline--fa fa-times-circle fa-w-16 fa-fw fa-lg",
                      attrs: {
                        "aria-hidden": "true",
                        focusable: "false",
                        "data-prefix": "fas",
                        "data-icon": "times-circle",
                        role: "img",
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 512 512",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "currentColor",
                          d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("span", {
            staticClass: "hidden1",
            staticStyle: { display: "none" },
            attrs: { "aria-label": _vm.$t("exit_suggestions") },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.suggestionsShowing && _vm.suggestions.length
        ? _c(
            "ul",
            {
              staticClass: "suggestions",
              attrs: {
                id: "61-suggestions",
                role: "listbox",
                "aria-labelledby": "61-suggestions",
              },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  ) {
                    return null
                  }
                  _vm.suggestionsShowing = false
                },
              },
            },
            [
              _c("li", { attrs: { id: "searching-for" } }, [
                _c("div", { staticClass: "misc-item" }, [
                  _c(
                    "span",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      attrs: {
                        role: "alert",
                        "aria-label": _vm.$t("exit_suggestions"),
                        title: "united",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("searching_for")) +
                          " "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.userQuery))]),
                      _vm._v(".\n                "),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.suggestions, function (item, index) {
                return [
                  item.type == "history"
                    ? _c(
                        "li",
                        {
                          key: index,
                          staticClass: "suggest-item",
                          attrs: {
                            role: "option",
                            "aria-selected": "false",
                            id: "61-suggestions-suggestion-history-" + index,
                          },
                        },
                        [
                          _c("div", { staticClass: "misc-item" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  { name: "to-lang", rawName: "v-to-lang" },
                                ],
                                class: item.type,
                                attrs: { title: "unite" },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.title) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.type == "search"
                    ? _c(
                        "li",
                        {
                          key: index,
                          staticClass: "suggest-item",
                          attrs: {
                            role: "option",
                            "aria-selected": "false",
                            id: "61-suggestions-suggestion-search-" + index,
                          },
                        },
                        [
                          _c("div", { staticClass: "misc-item" }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  { name: "to-lang", rawName: "v-to-lang" },
                                ],
                                class: item.type,
                                attrs: { title: item.title },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(item.title) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "clear-history",
                  attrs: { id: "clear-history", role: "button" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "misc-item",
                      on: {
                        click: function () {
                          return _vm.clearSearchHistory()
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            { name: "to-lang", rawName: "v-to-lang" },
                          ],
                          staticStyle: {
                            color: "dodgerblue",
                            "text-decoration": "underline",
                            cursor: "pointer",
                          },
                          attrs: { role: "button" },
                        },
                        [_vm._v(_vm._s(_vm.$t("clear_search_history")))]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }