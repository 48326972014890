export default {
    ar: {
        'what_is_unterm_title': 'بوابة <span class="text-secondary">UNTERM</span>',
                'what_is_unterm': 'بوابة <b>UNTERM</b> هي قاعدة بيانات متعددة اللغات تتضمن المصطلحات الرسمية ذات الصلة بعمل الأمم المتحدة. وهي موقع إلكتروني متاح للجمهور يستخدمه مندوبو الأمم المتحدة وموظفوها، إلى جانب مستخدمين آخرين مهتمين بعمل الأمم المتحدة وأنشطتها في جميع أنحاء العالم.',
        'multilingual_content_title': 'محتوى متعدد اللغات',
        'multilingual_content': 'ترد المصطلحات الرسمية باللغات الرسمية الست للأمم المتحدة - الإسبانية والإنكليزية والروسية والصينية والعربية والفرنسية - تمشيا مع ولاية الجمعية العامة بشأن تعدد اللغات.',
        'global_management_title': 'الإدارة على الصعيد العالمي',
        'global_management': 'تتضمن بوابة <b>UNTERM</b>  مصطلحات من كيانات مختلفة على نطاق منظومة الأمم المتحدة. ولضمان الاتساق على نطاق منظومة الأمم المتحدة، يتولى إدارة قاعدة البيانات موظفو لغات متخصصون في كل موضوع من موظفي الأمم المتحدة.',
    }
}



