//
//
//
//
//
//
//
//
//
//

import { subjects } from '../../Lang/subjects'
import { bodies } from '../../Lang/bodies'

export default {
    name: "RecordInfo",
    props: ["Record"],
    i18n: {
        messages: {
            en: {
                ...subjects.en,
                ...bodies.en
            },
            es: {
                ...subjects.es,
                ...bodies.es
            },
            fr: {
                ...subjects.fr,
                ...bodies.fr
            },
            ru: {
                ...subjects.ru,
                ...bodies.ru
            },
            ar: {
                ...subjects.ar,
                ...bodies.ar
            },
            zh: {
                ...subjects.zh,
                ...bodies.zh
            }

        }
    }, watch: {},
    methods: {}
}
