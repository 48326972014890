var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contentsOnly" },
    [
      _vm.data != null
        ? [
            _c("div", { staticClass: "flex-parent-element" }, [
              _c("span", {
                directives: [
                  {
                    name: "to-lang",
                    rawName: "v-to-lang.children",
                    value: _vm.LanguageCode,
                    expression: "LanguageCode",
                    modifiers: { children: true },
                  },
                ],
                class: [
                  _vm.data.termStatus,
                  _vm.data.termType,
                  _vm.record.recordType,
                ],
                attrs: { role: "heading", "aria-level": "3" },
                domProps: { innerHTML: _vm._s(_vm.data.term) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "flex-child-element" }, [
                _c(
                  "small",
                  {
                    directives: [
                      {
                        name: "to-lang",
                        rawName: "v-to-lang",
                        value: _vm.LanguageCode,
                        expression: "LanguageCode",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t(_vm.displayType)))]
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.data == null ? [_vm._v("\n         \n    ")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }