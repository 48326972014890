var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Record != null
    ? _c(
        "div",
        { staticClass: "contentsOnly record-preview" },
        _vm._l(_vm.previewLanguages, function (lang, ix) {
          return _vm.hasLanguage(lang.name)
            ? _c(
                "div",
                { key: lang.code },
                [
                  ix > 0 ? _c("hr") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "2" } }, [
                        _c(
                          "h2",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                            staticClass: "language-name-title",
                            attrs: { title: _vm.$t(lang.name.toLowerCase()) },
                          },
                          [_vm._v(_vm._s(_vm.$t(lang.name.toLowerCase())))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("language", {
                            attrs: {
                              data: _vm.Record[lang.name.toLowerCase()],
                              record: _vm.Record,
                              LanguageCode: lang.code,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e()
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }