<template>
    <img :src="basePath + 'images/' + src" :alt="alt?alt:''" />
</template>
<script>
    declare var UNTERM: any;

    export default {
        computed: {
            basePath(){return UNTERM.BasePath},
        },
        props: {
            alt: {
                type: String,
            },
            src: {
                type: String,
                required: true
            },
        }
    }
</script>
<style lang="scss">
</style>
