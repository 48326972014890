<template>
    <multiselect v-model="selection"
                 label="name"
                 placeholder=""
                 track-by="code"
                 lang="en"
                 :aria-label="`Type to search ${name} or use the arrow to navigate through the list. To select press spacebar.`"
                 :close-on-select="false"
                 :limit="limit"
                 :searchable="true"
                 :showLabels="false"
                 :options="options"
                 :multiple="true">
        <template v-to-lang slot="placeholder" slot-scope="props">
            {{$t('all_'+name)}}
        </template>
        <template slot="option" slot-scope="props">
            <span v-to-lang>
                <span v-to-lang :aria-label="(selection.map(x=>x.name).includes(props.option.name)?'Unselect ':'Select ')+ props.option.name"></span> {{ props.option.name }}
            </span>
            <span class="check">
                <font-awesome-icon :icon="['far', 'square']" class="unchecked" />
                <font-awesome-icon :icon="['far', 'check-square']" class="checked" />
            </span>
        </template>
        <template slot="tag" slot-scope="{ option, remove }">
            <span :title="option.name" class="badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 active badge-filterTag" role="alert" :aria-label="`${option.name} selected`">
                <span v-to-lang class="b-form-tag-content flex-grow-1 text-truncate">
                    {{option.name}}
                </span>
                <button @click="remove(option)" aria-keyshortcuts="Delete" type="button" aria-label="press space to remove" class="close b-form-tag-remove">×</button>
            </span>
        </template>
        <template slot="limit">
            <b-badge variant="filterTag" class="mr-1 active">
                +{{this.value.length-this.limit}}
            </b-badge>
        </template>
        <span slot="noResult">{{$t('no_elements_found')}}</span>
    </multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'

    library.add(faSquare, faCheckSquare)

    export default {
        name: "MultiselectFilter",
        data: function () {
            return {
            }
        },
        props: {
            value: {
                required: true
            },
            name: {
                type: String,
                default: ""
            },
            options: {
                required: true
            },
            limit: {
                type: Number,
                default: 3
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        components: { Multiselect },
        computed: {
            selection: {
                get() {
                    return this.value
                },
                set(newVal) {
                    //this.searchModel.datasets = newVal.map(x => x.code)
                    this.$emit('input', newVal)
                }
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>
