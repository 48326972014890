//
//
//

declare var UNTERM: any;

export default {
    computed: {
        basePath(){return UNTERM.BasePath},
    },
    props: {
        alt: {
            type: String,
        },
        src: {
            type: String,
            required: true
        },
    }
}
