export default {
    es: {
        'what_is_unterm_title': 'Portal <span class="text-secondary">UNTERM</span>',
                'what_is_unterm': '<b>UNTERM</b> es una base de datos multilingüe de terminología oficial relacionada con la labor de las Naciones Unidas. Se trata de un sitio web público que utilizan las delegaciones y el personal de la Organización, así como otras personas interesadas en la labor y las actividades de las Naciones Unidas en todo el mundo.',
        'multilingual_content_title': 'Contenido multilingüe',
        'multilingual_content': '<b>UNTERM</b> recoge la terminología oficial en los seis idiomas oficiales de las Naciones Unidas —árabe, chino, español, francés, inglés y ruso—, conforme al mandato de la Asamblea General sobre el multilingüismo.',
        'global_management_title': 'Gestión global',
        'global_management': '<b>UNTERM</b> tiene entradas terminológicas de entidades de todo el sistema de las Naciones Unidas. Para garantizar la coherencia a ese nivel, es el personal lingüístico de las Naciones Unidas especializado en cada tema quien se ocupa de gestionar la base de datos.',
    }
}

