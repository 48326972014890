export const subjects = {
    en: {
        'Accounting': 'Accounting',
        'Administrative/staff matters': 'Administrative/staff matters',
        'Agriculture': 'Agriculture',
        'Agro-industry': 'Agro-industry',
        'Agriculture and agro-industry': '@:Agriculture and @.lower:Agro-industry',
        'AIDS': 'AIDS',
        'Automobile / Road safety': 'Automobile / Road safety',
        'Biodiversity': 'Biodiversity',
        'Biological weapons': 'Biological weapons',
        'Budget': 'Budget',
        'Cartography': 'Cartography',
        'Chemical and waste management': 'Chemical and waste management',
        'Chemical weapons': 'Chemical weapons',
        'Chemistry and chemicals': 'Chemistry and chemicals',
        'Civil and political rights, torture': 'Civil and political rights, torture',
        'Climate change': 'Climate change',
        'Conventional weapons': 'Conventional weapons',
        'Country names': 'Country names',
        'Crime & criminal justice': 'Crime & criminal justice',
        'Criminal law': 'Criminal law',
        'Culture': 'Culture',
        'Dangerous goods transportation': 'Dangerous goods transportation',
        'Desertification': 'Desertification',
        'Development (general)': 'Development (general)',
        'Disabilities': 'Disabilities',
        'Disarmament (general)': 'Disarmament (general)',
        'Disaster prevention and management': 'Disaster prevention and management',
        'Drugs': 'Drugs',
        'Economic development': 'Economic development',
        'Economics': 'Economics',
        'Education': 'Education',
        'Energy/fuel': 'Energy/fuel',
        'Environment (general)': 'Environment (general)',
        'Finance': 'Finance',
        'Finance for development': 'Finance for development',
        'Financial management': 'Financial management',
        'Food and related issues': 'Food and related issues',
        'Forestry': 'Forestry',
        'Gender issues': 'Gender issues',
        'Geography': 'Geography',
        'Government and governance, public administration': 'Government and governance, public administration',
        'Health and medicine': 'Health and medicine',
        'Human rights (general)': 'Human rights (general)',
        'Human rights mechanisms titles': 'Human rights mechanisms titles',
        'Humanitarian issues': 'Humanitarian issues',
        'Hydrology': 'Hydrology',
        'Industrial development': 'Industrial development',
        'Industry (general)': 'Industry (general)',
        'Information and communication technology': 'Information and communication technology',
        'Inland waterways': 'Inland waterways',
        'Intellectual property': 'Intellectual property',
        'International law': 'International law',
        'International relations (general)': 'International relations (general)',
        'International trade': 'International trade',
        'Investments': 'Investments',
        'Job/functional titles': 'Job/functional titles',
        'Labour': 'Labour',
        'Land use and human settlements': 'Land use and human settlements',
        'Landmines': 'Landmines',
        'Latin words/phrases': 'Latin words/phrases',
        'Law (general)': 'Law (general)',
        'Legal instruments': 'Legal instruments',
        'Logistics and supplies': 'Logistics and supplies',
        'Marine insurance': 'Marine insurance',
        'Meetings and other events': 'Meetings and other events',
        'Meteorology': 'Meteorology',
        'Migrations, refugees and displaced persons': 'Migrations, refugees and displaced persons',
        'Mining': 'Mining',
        'Multimodal transport': 'Multimodal transport',
        'Names of NGOs': 'Names of NGOs',
        'Names of non-UN international organizations': 'Names of non-UN international organizations',
        'Names of UN system organizations': 'Names of UN system organizations',
        'National/Subnational institutions': 'National/Subnational institutions',
        'Natural resources': 'Natural resources',
        'Nuclear weapons': 'Nuclear weapons',
        'Organizational matters': 'Organizational matters',
        'Organizational structure': 'Organizational structure',
        'Ozone layer': 'Ozone layer',
        'Peacekeeping and peacebuilding': 'Peacekeeping and peacebuilding',
        'Place names': 'Place names',
        'Political and democratic life': 'Political and democratic life',
        'Pollutants': 'Pollutants',
        'Population and demographics': 'Population and demographics',
        'Postal services': 'Postal services',
        'procurement': 'Procurement',
        'Railways': 'Railways',
        'Real estate': 'Real estate',
        'Regional matters': 'Regional matters',
        'Regional/sub-regional institutions': 'Regional/sub-regional institutions',
        'Religion': 'Religion',
        'Rights of child': 'Rights of child',
        'Rights of indigenous peoples': 'Rights of Indigenous Peoples',
        'Rights of persons with disabilities': 'Rights of persons with disabilities',
        'Safety and security issues': 'Safety and security issues',
        'Science and technology (general)': 'Science and technology (general)',
        'Seas and oceans': 'Seas and oceans',
        'Shipping': 'Shipping',
        'Social sciences (general)': 'Social sciences (general)',
        'Space and geospatial science': 'Space and geospatial science',
        'Space law': 'Space law',
        'Sports': 'Sports',
        'Statistics': 'Statistics',
        'Steel industry': 'Steel industry',
        'Telecommunications': 'Telecommunications',
        'Terrorism': 'Terrorism',
        'Tourism': 'Tourism',
        'Trade and commodities': 'Trade and commodities',
        'Trade and development': 'Trade and development',
        'Trade facilitation and E-commerce': 'Trade facilitation and E-commerce',
        'Trade law': 'Trade law',
        'Transboundary pollution': 'Transboundary pollution',
        'Transportation (general)': 'Transportation (general)',
        'UN jargon': 'UN jargon',
        'UN programmes and initiatives': 'UN programmes and initiatives',
        'UN publications and other documents': 'UN publications and other documents',
        'UNCC issues': 'UNCC issues',
        'Water and sanitation': 'Water and sanitation',
        'Conflict': "Conflict",
        'Management': "Management",
        'Names of international organizations': "Names of international organizations",
        'other UN titles': "Other UN titles",
        'Proper names': "Proper names",
        'Religion, communications and culture': "Religion Communication and culture",
        'Travel and leisure': "Travel and leisure",
        'World Heritage': "World heritage",
        'Cartography, geography': 'Cartography, geography',
        'UNHQ': 'UNHQ',
        'GLOBAL': 'GLOBAL',
        'DETERM': 'DETERM',
        'Air navigation': 'Air navigation',
        'Air transport': 'Air transport',
        'Aviation': 'Aviation',
        'Civil and political rights': 'Civil and political rights',
        'Energy resources': 'Energy resources',
        'Evaluation': 'Evaluation',
        'Food and nutrition': 'Food and nutrition',
        'Health education and learning': 'Health education and learning',
        'Water resources': 'Water resources',
        'WHO programmes and initiatives': 'WHO programmes and initiatives'
    },
    es: {
        'Accounting': 'Contabilidad',
        'Administrative/staff matters': 'Asuntos administrativos y de personal',
        'Agriculture': 'Agricultura',
        'Agro-industry': 'Agroindustria',
        'Agriculture and agro-industry': '@:Agriculture y @.lower:Agro-industry',
        'AIDS': 'Sida',
        'Automobile / Road safety': 'Seguridad automovilística y vial',
        'Biodiversity': 'Biodiversidad',
        'Biological weapons': 'Armas biológicas',
        'Budget': 'Presupuesto',
        'Cartography': 'Cartografía',
        'Chemical and waste management': 'Gestión de productos químicos y desechos',
        'Chemical weapons': 'Armas químicas',
        'Chemistry and chemicals': 'Química y sustancias químicas',
        'Civil and political rights, torture': 'Derechos civiles y políticos, tortura',
        'Climate change': 'Cambio climático',
        'Conventional weapons': 'Armas convencionales',
        'Country names': 'Nombres de países',
        'Crime & criminal justice': 'Delito y justicia penal',
        'Criminal law': 'Derecho penal',
        'Culture': 'Cultura',
        'Dangerous goods transportation': 'Transporte de mercancías peligrosas',
        'Desertification': 'Desertificación',
        'Development (general)': 'Desarrollo (general)',
        'Disabilities': 'Discapacidad',
        'Disarmament (general)': 'Desarme (general)',
        'Disaster prevention and management': 'Prevención y gestión de desastres',
        'Drugs': 'Drogas',
        'Economic development': 'Desarrollo económico',
        'Economics': 'Economía',
        'Education': 'Educación',
        'Energy/fuel': 'Energía y combustibles',
        'Environment (general)': 'Medio ambiente (general)',
        'Finance': 'Finanzas',
        'Finance for development': 'Financiación para el desarrollo',
        'Financial management': 'Gestión financiera',
        'Food and related issues': 'Cuestiones alimentarias y afines',
        'Forestry': 'Silvicultura',
        'Gender issues': 'Cuestiones de género',
        'Geography': 'Geografía',
        'Government and governance, public administration': 'Gobierno y gobernanza, administración pública',
        'Health and medicine': 'Salud y medicina',
        'Human rights (general)': 'Derechos humanos (general)',
        'Human rights mechanisms titles': 'Nombres de mecanismos de derechos humanos',
        'Humanitarian issues': 'Cuestiones humanitarias',
        'Hydrology': 'Hidrología',
        'Industrial development': 'Desarrollo industrial',
        'Industry (general)': 'Industria (general)',
        'Information and communication technology': 'Tecnología de la información y las comunicaciones',
        'Inland waterways': 'Vías de navegación interior',
        'Intellectual property': 'Propiedad intelectual',
        'International law': 'Derecho internacional',
        'International relations (general)': 'Relaciones internacionales (general)',
        'International trade': 'Comercio internacional',
        'Investments': 'Inversiones',
        'Job/functional titles': 'Nombres de cargos y puestos',
        'Labour': 'Cuestiones laborales',
        'Land use and human settlements': 'Uso de la tierra y asentamientos humanos',
        'Landmines': 'Minas terrestres',
        'Latin words/phrases': 'Latinismos',
        'Law (general)': 'Derecho (general)',
        'Legal instruments': 'Instrumentos jurídicos',
        'Logistics and supplies': 'Logística y suministros',
        'Marine insurance': 'Seguro marítimo',
        'Meetings and other events': 'Reuniones y otros eventos',
        'Meteorology': 'Meteorología',
        'Migrations, refugees and displaced persons': 'Migraciones, refugiados y desplazados',
        'Mining': 'Minería',
        'Multimodal transport': 'Transporte multimodal',
        'Names of NGOs': 'Nombres de ONG',
        'Names of non-UN international organizations': 'Nombres de organizaciones internacionales ajenas a la ONU',
        'Names of UN system organizations': 'Nombres de organizaciones del sistema de la ONU',
        'National/Subnational institutions': 'Instituciones nacionales y subnacionales',
        'Natural resources': 'Recursos naturales',
        'Nuclear weapons': 'Armas nucleares',
        'Organizational matters': 'Cuestiones organizacionales',
        'Organizational structure': 'Organigrama',
        'Ozone layer': 'Capa de ozono',
        'Peacekeeping and peacebuilding': 'Mantenimiento y consolidación de la paz',
        'Place names': 'Topónimos',
        'Political and democratic life': 'Vida política y democrática',
        'Pollutants': 'Contaminantes',
        'Population and demographics': 'Población y demografía',
        'Postal services': 'Servicios postales',
        'procurement': 'Adquisiciones',
        'Railways': 'Ferrocarriles',
        'Real estate': 'Bienes inmuebles',
        'Regional matters': 'Asuntos regionales',
        'Regional/sub-regional institutions': 'Instituciones regionales y subregionales',
        'Religion': 'Religión',
        'Rights of child': 'Derechos del niño',
        'Rights of indigenous peoples': 'Derechos de los Pueblos Indígenas',
        'Rights of persons with disabilities': 'Derechos de las personas con discapacidad',
        'Safety and security issues': 'Cuestiones de seguridad',
        'Science and technology (general)': 'Ciencia y tecnología (general)',
        'Seas and oceans': 'Mares y océanos',
        'Shipping': 'Transporte marítimo',
        'Social sciences (general)': 'Ciencias sociales (general)',
        'Space and geospatial science': 'Ciencia espacial y geoespacial',
        'Space law': 'Derecho del espacio',
        'Sports': 'Deportes',
        'Statistics': 'Estadística',
        'Steel industry': 'Siderurgia',
        'Telecommunications': 'Telecomunicaciones',
        'Terrorism': 'Terrorismo',
        'Tourism': 'Turismo',
        'Trade and commodities': 'Comercio y productos básicos',
        'Trade and development': 'Comercio y desarrollo',
        'Trade facilitation and E-commerce': 'Facilitación del comercio y comercio electrónico',
        'Trade law': 'Derecho mercantil',
        'Transboundary pollution': 'Contaminación transfronteriza',
        'Transportation (general)': 'Transporte (general)',
        'UN jargon': 'Jerga de la ONU',
        'UN programmes and initiatives': 'Programas e iniciativas de la ONU',
        'UN publications and other documents': 'Publicaciones y otros documentos de la ONU',
        'UNCC issues': 'Cuestiones de la CINU',
        'Water and sanitation': 'Agua y saneamiento',
        'Conflict': "Conflictos",
        'Management': "Gestión",
        'Names of international organizations': "Nombres de organizaciones internacionales",
        'other UN titles': "Otros títulos de las Naciones Unidas",
        'Proper names': "Nombres propios",
        'Religion, communications and culture': "Religión, comunicaciones y cultura",
        'Travel and leisure': "Viajes y ocio",
        'World Heritage': "Patrimonio Mundial",
        'UNHQ': 'ONUNY',
        'GLOBAL': 'GLOBAL',
        'DETERM': 'DETERM',
        'Air navigation': 'Navegación aérea',
        'Air transport': 'Transporte aéreo',
        'Aviation': 'Aviación',
        'Civil and political rights': 'Derechos civiles y políticos',
        'Energy resources': 'Recursos energéticos',
        'Evaluation': 'Evaluación',
        'Food and nutrition': 'Alimentación y nutrición',
        'Health education and learning': 'Educación y aprendizaje sobre la salud',
        'Water resources': 'Recursos hídricos',
        'WHO programmes and initiatives': 'Programas e iniciativas de la OMS'

    },
    fr: {
        'Accounting': 'Comptabilité',
        'Administrative/staff matters': 'Questions administratives et de personnel',
        'Agriculture': 'Agriculture',
        'Agro-industry': 'Agro-industrie',
        'Agriculture and agro-industry': '@:Agriculture et @.lower:Agro-industry',
        'AIDS': 'Sida',
        'Automobile / Road safety': 'Automobile et sécurité routière',
        'Biodiversity': 'Biodiversité',
        'Biological weapons': 'Armes biologiques',
        'Budget': 'Budget',
        'Cartography': 'Cartographie',
        'Chemical and waste management': 'Gestion des produits chimiques et des déchets',
        'Chemical weapons': 'Armes chimiques',
        'Chemistry and chemicals': 'Chimie et produits chimiques',
        'Civil and political rights, torture': 'Droits civils et politiques, torture',
        'Climate change': 'Changements climatiques',
        'Conventional weapons': 'Armes classiques',
        'Country names': 'Noms de pays',
        'Crime & criminal justice': 'Criminalité et justice pénale',
        'Criminal law': 'Droit pénal',
        'Culture': 'Culture',
        'Dangerous goods transportation': 'Transport de marchandises dangereuses',
        'Desertification': 'Désertification',
        'Development (general)': 'Développement (général)',
        'Disabilities': 'Handicaps',
        'Disarmament (general)': 'Désarmement (général)',
        'Disaster prevention and management': 'Prévention et gestion des catastrophes',
        'Drugs': 'Drogues',
        'Economic development': 'Développement économique',
        'Economics': 'Économie',
        'Education': 'Éducation',
        'Energy/fuel': 'Énergie et combustibles',
        'Environment (general)': 'Environnement (général)',
        'Finance': 'Finances',
        'Finance for development': 'Financement du développement',
        'Financial management': 'Gestion financière',
        'Food and related issues': 'Alimentation et questions connexes',
        'Forestry': 'Foresterie',
        'Gender issues': 'Questions de genre',
        'Geography': 'Géographie',
        'Government and governance, public administration': 'Gouvernement et gouvernance, administration publique',
        'Health and medicine': 'Santé et médecine',
        'Human rights (general)': 'Droits humains (général)',
        'Human rights mechanisms titles': 'Mécanismes droits humains',
        'Humanitarian issues': 'Questions humanitaires',
        'Hydrology': 'Hydrologie',
        'Industrial development': 'Développement industriel',
        'Industry (general)': 'Industrie (général)',
        'Information and communication technology': 'Informatique et communications',
        'Inland waterways': 'Voies de navigation intérieure',
        'Intellectual property': 'Propriété intellectuelle',
        'International law': 'Droit international',
        'International relations (general)': 'Relations internationales (général)',
        'International trade': 'Commerce international',
        'Investments': 'Investissements',
        'Job/functional titles': 'Titres ou fonctions',
        'Labour': 'Emploi',
        'Land use and human settlements': 'Utilisation des terres et établissements humains',
        'Landmines': 'Mines terrestres',
        'Latin words/phrases': 'Mots et expressions en latin',
        'Law (general)': 'Droit (général)',
        'Legal instruments': 'Instruments juridiques',
        'Logistics and supplies': 'Logistique et approvisionnement',
        'Marine insurance': 'Assurance maritime',
        'Meetings and other events': 'Réunions et autres événements',
        'Meteorology': 'Météorologie',
        'Migrations, refugees and displaced persons': 'Migrants, réfugiés et personnes déplacées',
        'Mining': 'Extraction',
        'Multimodal transport': 'Transport multimodal',
        'Names of NGOs': 'Noms d\'ONG',
        'Names of non-UN international organizations': 'Autres organisations internationales',
        'Names of UN system organizations': 'Noms d\'organisations du système des Nations Unies',
        'National/Subnational institutions': 'Institutions nationales/infranationales',
        'Natural resources': 'Ressources naturelles',
        'Nuclear weapons': 'Armes nucléaires',
        'Organizational matters': 'Questions d\'organisation',
        'Organizational structure': 'Structure institutionnelle',
        'Ozone layer': 'Couche d\'ozone',
        'Peacekeeping and peacebuilding': 'Maintien et consolidation de la paix',
        'Place names': 'Noms de lieux',
        'Political and democratic life': 'Vie politique et démocratique',
        'Pollutants': 'Polluants',
        'Population and demographics': 'Population et démographie',
        'Postal services': 'Services postaux',
        'procurement': 'Achats',
        'Railways': 'Chemins de fer',
        'Real estate': 'Immobilier',
        'Regional matters': 'Questions régionales',
        'Regional/sub-regional institutions': 'Institutions régionales/infrarégionales',
        'Religion': 'Religion',
        'Rights of child': 'Droits de l\'enfant',
        'Rights of indigenous peoples': 'Droits des peuples autochtones',
        'Rights of persons with disabilities': 'Droits des personnes handicapées',
        'Safety and security issues': 'Sûreté et sécurité',
        'Science and technology (general)': 'Science et technologie (général)',
        'Seas and oceans': 'Mers et océans',
        'Shipping': 'Transport maritime',
        'Social sciences (general)': 'Sciences sociales (général)',
        'Space and geospatial science': 'Sciences spatiales et géospatiales',
        'Space law': 'Droit de l\'espace',
        'Sports': 'Sports',
        'Statistics': 'Statistiques',
        'Steel industry': 'Industrie sidérurgique',
        'Telecommunications': 'Télécommunication',
        'Terrorism': 'Terrorisme',
        'Tourism': 'Tourisme',
        'Trade and commodities': 'Commerce et produits de base',
        'Trade and development': 'Commerce et développement',
        'Trade facilitation and E-commerce': 'Facilitation du commerce et commerce électronique',
        'Trade law': 'Droit commercial',
        'Transboundary pollution': 'Pollution transfrontière',
        'Transportation (general)': 'Transport (général)',
        'UN jargon': 'Jargon onusien',
        'UN programmes and initiatives': 'Programmes et initiatives des Nations Unies',
        'UN publications and other documents': 'Publications et autres documents des Nations Unies',
        'UNCC issues': 'Questions relatives à la Commission d\'indemnisation',
        'Water and sanitation': 'Eau et assainissement',
        'Conflict': "Conflits",
        'Management': "Gestion",
        'Names of international organizations': "Noms d’organisations internationales",
        'other UN titles': "Autres titres ONU",
        'Proper names': "Noms propres",
        'Religion, communications and culture': "Religion, communication et culture",
        'Travel and leisure': "Voyages et loisirs",
        'World Heritage': "Patrimoine mondial",
        'UNHQ': 'UNHQ',
        'GLOBAL': 'GLOBAL',
        'DETERM': 'DETERM',
        'Air navigation': 'Navigation aérienne',
        'Air transport': 'Transport aérien',
        'Aviation': 'Aviation',
        'Civil and political rights': 'Droits civils et politiques',
        'Energy resources': 'Ressources énergétiques',
        'Evaluation': 'Évaluation',
        'Food and nutrition': 'Alimentation et nutrition',
        'Health education and learning': 'Santé (éducation et apprentissage)',
        'Water resources': 'Ressources en eau',
        'WHO programmes and initiatives': 'Programmes et initiatives de l’OMS'

    },
    ar: {
        'Accounting': 'المحاسبة',
        'Administrative/staff matters': 'المسائل الإدارية/شؤون الموظفين',
        'Agriculture': 'الزراعة',
        'Agro-industry': 'الصناعة الزراعية',
        'Agriculture and agro-industry': '@:Agriculture و @.lower:Agro-industry',
        'AIDS': 'الإيدز',
        'Automobile / Road safety': 'السيارات/السلامة على الطرق',
        'Biodiversity': 'التنوع البيولوجي',
        'Biological weapons': 'الأسلحة البيولوجية',
        'Budget': 'الميزانية',
        'Cartography': 'علم الخرائط',
        'Chemical and waste management': 'إدارة المواد الكيميائية والنفايات',
        'Chemical weapons': 'الأسلحة كيميائية',
        'Chemistry and chemicals': 'الكيمياء والمواد الكيميائية',
        'Civil and political rights, torture': 'الحقوق المدنية والسياسية والتعذيب',
        'Climate change': 'تغير المناخ',
        'Conventional weapons': 'الأسلحة التقليدية',
        'Country names': 'أسماء البلدان',
        'Crime & criminal justice': 'الجريمة والعدالة الجنائية',
        'Criminal law': 'القانون الجنائي',
        'Culture': 'الثقافة',
        'Dangerous goods transportation': 'نقل البضائع الخطرة',
        'Desertification': 'التصحّر',
        'Development (general)': 'التنمية (عامة)',
        'Disabilities': 'الإعاقات',
        'Disarmament (general)': 'التنمية (عامة)',
        'Disaster prevention and management': 'الوقاية من الكوارث وإدارتها',
        'Drugs': 'المخدرات',
        'Economic development': 'التنمية اقتصادية',
        'Economics': 'علم الاقتصاد',
        'Education': 'التعليم',
        'Energy/fuel': 'الطاقة/الوقود',
        'Environment (general)': 'التنمية (عامة)',
        'Finance': 'الشؤون المالية',
        'Finance for development': 'تمويل التنمية',
        'Financial management': 'إدارة الشؤون المالية',
        'Food and related issues': 'الأغذية والمسائل ذات الصلة',
        'Forestry': 'الحراجة',
        'Gender issues': 'القضايا الجنسانية',
        'Geography': 'الجغرافيا',
        'Government and governance, public administration': 'الحكومة والحكم والإدارة العامة',
        'Health and medicine': 'الصحة والطب',
        'Human rights (general)': 'حقوق الإنسان (عامة)',
        'Human rights mechanisms titles': 'عناوين آليات حقوق الإنسان',
        'Humanitarian issues': 'المسائل الإنسانية',
        'Hydrology': 'علم المياه',
        'Industrial development': 'التنمية الصناعية',
        'Industry (general)': 'الصناعة (عامة)',
        'Information and communication technology': 'تكنولوجيا المعلومات والاتصالات',
        'Inland waterways': 'الطرق المائية الداخلية',
        'Intellectual property': 'الملكية الفكرية',
        'International law': 'القانون الدولي',
        'International relations (general)': 'العلاقات الدولية (عامة)',
        'International trade': 'التجارة الدولية',
        'Investments': 'الاستثمارات',
        'Job/functional titles': 'الألقاب الوظيفية',
        'Labour': 'العمل',
        'Land use and human settlements': 'استخدام الأراضي والمستوطنات البشرية',
        'Landmines': 'الألغام الأرضية',
        'Latin words/phrases': 'كلمات/عبارات لاتينية',
        'Law (general)': 'القانون (عام)',
        'Legal instruments': 'صكوك قانونية',
        'Logistics and supplies': 'اللوجستيات والإمدادات',
        'Marine insurance': 'التأمين البحري',
        'Meetings and other events': 'اجتماعات ومناسبات أخرى',
        'Meteorology': 'الأرصاد الجوية',
        'Migrations, refugees and displaced persons': 'المهاجرون واللاجئون والمشردون',
        'Mining': 'التعدين',
        'Multimodal transport': 'النقل المتعدد الوسائط',
        'Names of NGOs': 'أسماء المنظمات غير الحكومية',
        'Names of non-UN international organizations': 'أسماء المنظمات الدولية غير التابعة للأمم المتحدة',
        'Names of UN system organizations': 'أسماء مؤسسات منظومة الأمم المتحدة',
        'National/Subnational institutions': 'المؤسسات الوطنية/دون الوطنية',
        'Natural resources': 'موارد طبيعية',
        'Nuclear weapons': 'أسلحة نووية',
        'Organizational matters': 'مسائل تنظيمية',
        'Organizational structure': 'الهيكل التنظيمي',
        'Ozone layer': 'طبقة الأوزون',
        'Peacekeeping and peacebuilding': 'حفظ السلام وبناء السلام',
        'Place names': 'أسماء الأماكن',
        'Political and democratic life': 'الحياة السياسية والديمقراطية',
        'Pollutants': 'الملوثات',
        'Population and demographics': 'السكان والتركيبة السكانية',
        'Postal services': 'خدمات بريدية',
        'procurement': 'الشراء',
        'Railways': 'السكك الحديدية',
        'Real estate': 'عقارات',
        'Regional matters': 'مسائل إقليمية',
        'Regional/sub-regional institutions': 'مؤسسات إقليمية/دون إقليمية',
        'Religion': 'الدين',
        'Rights of child': 'حقوق الطفل',
        'Rights of indigenous peoples': 'حقوق الشعوب الأصلية',
        'Rights of persons with disabilities': 'حقوق الأشخاص ذوي الإعاقة',
        'Safety and security issues': 'مسائل متصلة بالسلامة والأمن',
        'Science and technology (general)': 'العلم والتكنولوجيا (عامة)',
        'Seas and oceans': 'البحار والمحيطات',
        'Shipping': 'النقل البحري',
        'Social sciences (general)': 'العلوم الاجتماعية (عامة)',
        'Space and geospatial science': 'الفضاء وعلم الجغرافيا المكانية',
        'Space law': 'قانون الفضاء',
        'Sports': 'الرياضة',
        'Statistics': 'الإحصاءات',
        'Steel industry': 'صناعة الصلب',
        'Telecommunications': 'الاتصالات',
        'Terrorism': 'الإرهاب',
        'Tourism': 'السياحة',
        'Trade and commodities': 'التجارة والسلع الأساسية',
        'Trade and development': 'التجارة والتنمية',
        'Trade facilitation and E-commerce': 'تيسير التجارة والتجارة الإلكترونية',
        'Trade law': 'القانون التجاري',
        'Transboundary pollution': 'تلوث عابر للحدود',
        'Transportation (general)': 'النقل (عام)',
        'UN jargon': 'لغة اصطلاحية تستخدمها الأمم المتحدة',
        'UN programmes and initiatives': 'برامج الأمم المتحدة ومبادراتها',
        'UN publications and other documents': 'منشورات الأمم المتحدة ووثائقها الأخرى',
        'UNCC issues': 'المسائل المتصلة بلجنة الأمم المتحدة للتعويضات',
        'Water and sanitation': 'المياه والصرف الصحي',
        'Conflict': "النزاع",
        'Management': "الإدارة",
        'Names of international organizations': "أسماء المنظمات الدولية",
        'other UN titles': "الأمم المتحدة عناوين أخرى ذات صلة بعمل",
        'Proper names': "أسماء العلم",
        'Travel and leisure': "السفر والاستجمام",
        'World Heritage': "التراث العالمي",
        "Religion, communications and culture": "الدين والتواصل والثقافة",
        'UNHQ': 'مقر الأمم المتحدة',
        'GLOBAL': 'قاعدة المصطلحات العالمية',
        'DETERM': 'قاعدة المصطلحات الألمانية',
        'Air navigation': 'الملاحة الجوية',
        'Air transport': 'النقل الجوي',
        'Aviation': 'الطيران',
        'Civil and political rights': 'الحقوق المدنية والسياسية',
        'Energy resources': 'موارد الطاقة',
        'Evaluation': 'التقييم',
        'Food and nutrition': 'الأغذية والتغذية',
        'Health education and learning': 'التثقيف والتعلم الصحي',
        'Water resources': 'الموارد المائية',
        'WHO programmes and initiatives': 'برامج ومبادرات منظمة الصحة العالمية'
    },
    ru: {
        'Accounting': 'Бухгалтерский учет',
        'Administrative/staff matters': 'Административные и кадровые вопросы',
        'Agriculture': 'Сельское хозяйство',
        'Agro-industry': 'Агропромышленность',
        'Agriculture and agro-industry': '@:Agriculture и @.lower:Agro-industry',
        'AIDS': 'СПИД',
        'Automobile / Road safety': 'Автомобильная безопасность и безопасность дорожного движения',
        'Biodiversity': 'Биоразнообразие',
        'Biological weapons': 'Биологическое оружие',
        'Budget': 'Бюджет',
        'Cartography': 'Картография',
        'Chemical and waste management': 'Обращение с химическими веществами и отходами',
        'Chemical weapons': 'Химическое оружие',
        'Chemistry and chemicals': 'Химия и химические вещества',
        'Civil and political rights, torture': 'Гражданские и политические права, пытки',
        'Climate change': 'Изменение климата',
        'Conventional weapons': 'Обычные вооружения',
        'Country names': 'Названия стран',
        'Crime & criminal justice': 'Преступность и уголовное правосудие',
        'Criminal law': 'Уголовное право',
        'Culture': 'Культура',
        'Dangerous goods transportation': 'Транспортировка опасных грузов',
        'Desertification': 'Опустынивание',
        'Development (general)': 'Развитие (общ.)',
        'Disabilities': 'Инвалидность',
        'Disarmament (general)': 'Разоружение (общ.)',
        'Disaster prevention and management': 'Предотвращение бедствий и ликвидация их последствий',
        'Drugs': 'Наркотики',
        'Economic development': 'Экономическое развитие',
        'Economics': 'Экономика',
        'Education': 'Образование',
        'Energy/fuel': 'Энергетика/топливо',
        'Environment (general)': 'Окружающая среда (общ.)',
        'Finance': 'Финансы',
        'Finance for development': 'Финансирование развития',
        'Financial management': 'Финансовое управление',
        'Food and related issues': 'Продовольствие и связанные с ним вопросы',
        'Forestry': 'Лесное хозяйство',
        'Gender issues': 'Гендерная проблематика',
        'Geography': 'География',
        'Government and governance, public administration': 'Правительство и государственное и иное управление',
        'Health and medicine': 'Здравоохранение и медицина',
        'Human rights (general)': 'Права человека (общ.)',
        'Human rights mechanisms titles': 'Названия правозащитных механизмов',
        'Humanitarian issues': 'Гуманитарные вопросы',
        'Hydrology': 'Гидрология',
        'Industrial development': 'Промышленное развитие',
        'Industry (general)': 'Промышленность (общ.)',
        'Information and communication technology': 'Информационно-коммуникационные технологии',
        'Inland waterways': 'Внутренние водные пути',
        'Intellectual property': 'Интеллектуальная собственность',
        'International law': 'Международное право',
        'International relations (general)': 'Международные отношения (общ.)',
        'International trade': 'Международная торговля',
        'Investments': 'Инвестиции',
        'Job/functional titles': 'Должности и их наименования',
        'Labour': 'Вопросы труда',
        'Land use and human settlements': 'Землепользование и населенные пункты',
        'Landmines': 'Наземные мины',
        'Latin words/phrases': 'Латинские слова и словосочетания',
        'Law (general)': 'Право (общ.)',
        'Legal instruments': 'Правовые документы',
        'Logistics and supplies': 'Материально-техническое обеспечение и снабжение',
        'Marine insurance': 'Морское страхование',
        'Meetings and other events': 'Встречи и другие мероприятия',
        'Meteorology': 'Метеорология',
        'Migrations, refugees and displaced persons': 'Миграция, беженцы и перемещенные лица',
        'Mining': 'Добыча полезных ископаемых',
        'Multimodal transport': 'Мультимодальные перевозки',
        'Names of NGOs': 'Названия НПО',
        'Names of non-UN international organizations': 'Названия международных организаций, не входящих в систему ООН',
        'Names of UN system organizations': 'Названия организаций системы ООН',
        'National/Subnational institutions': 'Национальные/субнациональные учреждения',
        'Natural resources': 'Природные ресурсы',
        'Nuclear weapons': 'Ядерное оружие',
        'Organizational matters': 'Организационные вопросы',
        'Organizational structure': 'Организационная структура',
        'Ozone layer': 'Озоновый слой',
        'Peacekeeping and peacebuilding': 'Миротворчество и миростроительство',
        'Place names': 'Топонимы',
        'Political and democratic life': 'Политическая и общественная жизнь',
        'Pollutants': 'Загрязняющие вещества',
        'Population and demographics': 'Население и демография',
        'Postal services': 'Почтовые услуги',
        'procurement': 'Закупки',
        'Railways': 'Железнодорожное сообщение',
        'Real estate': 'Недвижимость',
        'Regional matters': 'Региональные вопросы',
        'Regional/sub-regional institutions': 'Региональные/субрегиональные учреждения',
        'Religion': 'Религия',
        'Rights of child': 'Права ребенка',
        'Rights of indigenous peoples': 'Права коренных народов',
        'Rights of persons with disabilities': 'Права инвалидов',
        'Safety and security issues': 'Вопросы охраны и безопасности',
        'Science and technology (general)': 'Наука и техника (общ.)',
        'Seas and oceans': 'Моря и океаны',
        'Shipping': 'Отправка и получение грузов',
        'Social sciences (general)': 'Социальные науки (общ.)',
        'Space and geospatial science': 'Космос и геопространственные данные',
        'Space law': 'Космическое право',
        'Sports': 'Спорт',
        'Statistics': 'Статистика',
        'Steel industry': 'Металлургия',
        'Telecommunications': 'Телекоммуникации',
        'Terrorism': 'Терроризм',
        'Tourism': 'Туризм',
        'Trade and commodities': 'Торговля и сырьевые товары',
        'Trade and development': 'Торговля и развитие',
        'Trade facilitation and E-commerce': 'Упрощение процедур торговли и электронная торговля',
        'Trade law': 'Торговое право',
        'Transboundary pollution': 'Трансграничное загрязнение',
        'Transportation (general)': 'Транспорт (общ.)',
        'UN jargon': 'Жаргон ООН',
        'UN programmes and initiatives': 'Программы и инициативы ООН',
        'UN publications and other documents': 'Публикации и другие документы ООН',
        'UNCC issues': 'Вопросы ККООН',
        'Water and sanitation': 'Водоснабжение и санитария',
        'Conflict': "Конфликт",
        'Management': "Управление",
        'Names of international organizations': "Названия международных организаций",
        'other UN titles': "Другие названия ООН",
        'Proper names': "Имена собственные",
        'Religion, communications and culture': "Религиозные коммуникации и культура",
        'Travel and leisure': "Путешествия и отдых",
        'World Heritage': "Всемирное наследие",
        'DETERM': "ДЕТЕРМ",
        'GLOBAL': "ГЛОБАЛ",
        'UNHQ': "ЦУООН",
        'Cartography, geography': 'Картография, география',
        'Air navigation': 'Воздушная навигация',
        'Air transport': 'Воздушный транспорт',
        'Aviation': 'Авиация',
        'Civil and political rights': 'Гражданские и политические права',
        'Energy resources': 'Энергоресурсы',
        'Evaluation': 'Оценка',
        'Food and nutrition': 'Продовольствие и питание',
        'Health education and learning': 'Образование и обучение в сфере здравоохранения',
        'Water resources': 'Водные ресурсы',
        'WHO programmes and initiatives': 'Программы и инициативы ВОЗ'
    },
    zh: {
        'Accounting': '会计',
        'Administrative/staff matters': '行政/工作人员事项',
        'Agriculture': '农业',
        'Agro-industry': '农产品加工业',
        'Agriculture and agro-industry': '农业和农产品加工业',
        'AIDS': '艾滋病',
        'Automobile / Road safety': '汽车/道路安全',
        'Biodiversity': '生物多样性',
        'Biological weapons': '生物武器',
        'Budget': '预算',
        'Cartography': '地图学',
        'Chemical and waste management': '化学品和废物管理',
        'Chemical weapons': '化学武器',
        'Chemistry and chemicals': '化学与化学品',
        'Civil and political rights, torture': '公民及政治权利和酷刑',
        'Climate change': '气候变化',
        'Conventional weapons': '常规武器',
        'Country names': '国名',
        'Crime & criminal justice': '犯罪和刑事司法',
        'Criminal law': '刑法',
        'Culture': '文化',
        'Dangerous goods transportation': '危险货物运输',
        'Desertification': '荒漠化',
        'Development (general)': '发展(总括)',
        'Disabilities': '残疾',
        'Disarmament (general)': '裁军(总括)',
        'Disaster prevention and management': '灾害预防及管理',
        'Drugs': '毒品',
        'Economic development': '经济发展',
        'Economics': '经济学',
        'Education': '教育',
        'Energy/fuel': '能源/燃料',
        'Environment (general)': '环境(总括)',
        'Finance': '金融',
        'Finance for development': '发展筹资',
        'Financial management': '财务管理',
        'Food and related issues': '粮食及有关问题',
        'Forestry': '林业',
        'Gender issues': '性别问题',
        'Geography': '地理',
        'Government and governance, public administration': '政府与治理、公共行政',
        'Health and medicine': '医药卫生',
        'Human rights (general)': '人权(总括)',
        'Human rights mechanisms titles': '人权机制专用名称',
        'Humanitarian issues': '人道主义问题',
        'Hydrology': '水文学',
        'Industrial development': '工业发展',
        'Industry (general)': '工业(总括)',
        'Information and communication technology': '信息和通信技术',
        'Inland waterways': '内陆水道',
        'Intellectual property': '知识产权',
        'International law': '国际法',
        'International relations (general)': '国际关系(总括)',
        'International trade': '国际贸易',
        'Investments': '投资',
        'Job/functional titles': '职位/职能名称',
        'Labour': '劳工',
        'Land use and human settlements': '土地使用与人类住区',
        'Landmines': '地雷',
        'Latin words/phrases': '拉丁词/短语',
        'Law (general)': '法律(总括)',
        'Legal instruments': '法律文书',
        'Logistics and supplies': '后勤和供应',
        'Marine insurance': '海上保险',
        'Meetings and other events': '会议和其他活动',
        'Meteorology': '气象',
        'Migrations, refugees and displaced persons': '移民、难民和流离失所者',
        'Mining': '采矿',
        'Multimodal transport': '多式联运',
        'Names of NGOs': '非政府组织名称',
        'Names of non-UN international organizations': '非联合国国际组织名称',
        'Names of UN system organizations': '联合国系统组织名称',
        'National/Subnational institutions': '国家/国家以下各级机构',
        'Natural resources': '自然资源',
        'Nuclear weapons': '核武器',
        'Organizational matters': '组织事项',
        'Organizational structure': '组织结构',
        'Ozone layer': '臭氧层',
        'Peacekeeping and peacebuilding': '维持和平与建设和平',
        'Place names': '地名',
        'Political and democratic life': '政治和民主生活',
        'Pollutants': '污染物',
        'Population and demographics': '人口与人口统计',
        'Postal services': '邮政服务',
        'procurement': '采购',
        'Railways': '铁路',
        'Real estate': '不动产',
        'Regional matters': '区域事项',
        'Regional/sub-regional institutions': '区域/次区域机构',
        'Religion': '宗教',
        'Rights of child': '儿童权利',
        'Rights of indigenous peoples': '土著人民权利',
        'Rights of persons with disabilities': '残疾人权利',
        'Safety and security issues': '安全和安保问题',
        'Science and technology (general)': '科学技术(总括)',
        'Seas and oceans': '海洋',
        'Shipping': '航运',
        'Social sciences (general)': '社会科学(总括)',
        'Space and geospatial science': '空间与地理空间科学',
        'Space law': '空间法',
        'Sports': '体育运动',
        'Statistics': '统计',
        'Steel industry': '钢铁业',
        'Telecommunications': '电信',
        'Terrorism': '恐怖主义',
        'Tourism': '旅游业',
        'Trade and commodities': '贸易与商品',
        'Trade and development': '贸易与发展',
        'Trade facilitation and E-commerce': '贸易便利化与电子商务',
        'Trade law': '贸易法',
        'Transboundary pollution': '越境污染',
        'Transportation (general)': '运输(总括)',
        'UN jargon': '联合国专门术语',
        'UN programmes and initiatives': '联合国方案和倡议',
        'UN publications and other documents': '联合国出版物和其他文件',
        'UNCC issues': '赔偿委员会问题',
        'Water and sanitation': '水和环境卫生',
        'Conflict': "冲突",
        'Management': "管理",
        'Names of international organizations': "国际组织名称",
        'other UN titles': "联合国其他专用名称",
        'Proper names': "专有名称",
        //'Religion, communications and culture': "宗教交流与文化",
        'Travel and leisure': "旅行和休闲",
        'World Heritage': "世界遗产",
        "Religion, communications and culture": "宗教、传播和文化",
        "GLOBAL": "全局通用",
        "UNHQ": "联合国总部",
        'DETERM': "英德双语库",
        "Cartography, geography": "地图学、地理",
        'Air navigation': '空中导航',
        'Air transport': '航空运输',
        'Aviation': '航空',
        'Civil and political rights': '公民权利和政治权利',
        'Energy resources': '能源资源',
        'Evaluation': '评价',
        'Food and nutrition': '粮食和营养',
        'Health education and learning': '卫生教育和学习',
        'Water resources': '水资源',
        'WHO programmes and initiatives': '世卫组织方案和举措'
    }
}
