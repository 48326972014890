//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppNavBar from "./AppNavBar.vue"
import { mapGetters, mapActions } from 'vuex'
import * as TerminologyDataService from "../DataServices/terminologyDataService"
import * as Actions from '../Store/Actions'
export default {
    components: { AppNavBar },
    watch: {
        '$route'(to, from) {
            let title = 'UNTERM'
            if (to.meta.title) {
                title += ` - ${to.meta.title}`
            }
            if (to.meta.accessabilityFocusElementId) {
                setTimeout(() => {
                    document.getElementById(to.meta.accessabilityFocusElementId).focus();
                }, 300)
            }
            document.title = title
        }
    },
    methods: {
        ...mapActions([Actions.SET_SEARCH_SETTINGS]),
    },
    async mounted() {
        const settings = await TerminologyDataService.getClientSettings()
        if (settings.searchSettings) {
            this.setSearchSettings(settings.searchSettings)                
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentLanguage'
        ]),
        termsUrl() {
            return "https://www.un.org/" + this.getCurrentLanguage + "/about-us/terms-of-use"
        },
        copyrightUrl() {
            return "https://www.un.org/" + this.getCurrentLanguage + "/about-us/copyright"
        }
    }
}
