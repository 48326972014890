//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Term from "./TermPreview.vue"

export default {
    name: "Language",
    data: function () {
        return {
        }
    },
    props: ["data", "record", "LanguageCode"],
    computed: {
        isValidated: {
            get(): Boolean {
                if (this.data.validationStatus == null) return false
                return this.data.validationStatus.toLowerCase() === 'validated'
            }
        },
        showObsolete: {
            get(): Boolean {
                return this.index == 0 && (this.record.status.toLowerCase() == 'obsolete')
            }
        }
    },
    components: { Term },
    watch: {},
    methods: {}
}
