<template>
    <b-container class="p-0 mb-2">
        <b-form class="mt-3" v-to-lang.children @submit.prevent="onSubmit" @reset="onReset" v-if="show" novalidate>
            <h1 v-if="submitted">Feedback submitted</h1>
            <h1 id="error" class="error" v-to-lang v-if="failedToSubmit">{{$t('error')}}</h1>
            <h1 tabindex="-1" id="suggestion-page-heading" class="mb-5">{{$t('fill_in_the_form_to_suggest_a_new_entry')}}</h1>
            <b-form-group id="source-language" :label="`${$t('source_language')}`" label-for="input-sourceLang" class="required" label-class="title" label-cols="3">
                <b-form-select id="input-sourceLang" v-model="form.language" :options="languages"></b-form-select>
            </b-form-group>

            <b-form-group id="term-title" :label="`${$t('term')}/${$t('title')}`" label-for="input-suggestion" class="required" label-cols="3">
                <b-form-textarea id="input-suggestion"
                                 v-model="$v.form.suggestion.$model"
                                 ref="suggestion"
                                 rows="1"
                                 :state="getValidationState($v.form.suggestion)"
                                 required></b-form-textarea>
                <b-form-invalid-feedback>
                    <template v-if="!$v.form.suggestion.$required">
                        {{$t('validation_suggestion')}}
                    </template>
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="source" :label="$t('source')" class="required" label-for="input-source" label-cols="3">
                <b-form-input id="input-source"
                              v-model="form.source"
                              ref="source"
                              :state="getValidationState($v.form.source)"
                              required
                              :placeholder="$t('source')"></b-form-input>
                <b-form-invalid-feedback>
                    <template v-if="!$v.form.source.$required">
                        {{$t('validation_source')}}
                    </template>
                </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="defination" :label="`${$t('definition')}/${$t('description').toLowerCase()}`" label-for="input-description" label-cols="3">
                <b-form-input id="input-description"
                              v-model="form.description"
                              ref="description"
                              rows="1"
                              :state="getValidationState($v.form.description)"
                              :placeholder="`${$t('definition')}/${$t('description').toLowerCase()}`"></b-form-input>
            </b-form-group>

            <b-form-group id="context" :label="`${$t('context')}`" label-for="input-context" label-cols="3">
                <b-form-input id="input-context"
                              v-model="form.context"
                              ref="context"
                              rows="1"
                              :state="getValidationState($v.form.context)"
                              :placeholder="`${$t('context')}`"></b-form-input>
            </b-form-group>

            <hr />

            <b-form-group id="target-language" :label="`${$t('target_language')}`" label-for="input-targetLang" class="required" label-class="title" label-cols="3">
                <b-form-select id="input-targetLang" v-model="form.targetLanguage" :options="languages"></b-form-select>
            </b-form-group>

            <b-form-group id="target-title" :label="`${$t('target')} ${$t('term')}/${$t('title')}`" label-for="input-t-title" label-cols="3">
                <b-form-textarea id="input-t-title"
                                 v-model="form.targetTitle"
                                 ref="targetTitle"
                                 rows="1"
                                 :state="getValidationState($v.form.targetTitle)"
                                 :placeholder="`${$t('term')}/${$t('title')}`"></b-form-textarea>
            </b-form-group>

            <b-form-group id="target-source" :label="`${$t('source')}`" label-for="input-t-source" label-cols="3">
                <b-form-input id="input-t-source"
                              v-model="form.targetSource"
                              ref="targetSource"
                              rows="1"
                              :state="getValidationState($v.form.targetSource)"
                              :placeholder="`${$t('source')}`"></b-form-input>
            </b-form-group>

            <b-form-group id="target-context" :label="`${$t('context')}`" label-for="input-t-context" label-cols="3">
                <b-form-input id="input-t-context"
                              v-model="form.targetContext"
                              ref="targetContext"
                              rows="1"
                              :state="getValidationState($v.form.targetContext)"
                              :placeholder="`${$t('context')}`"></b-form-input>
            </b-form-group>

            <hr />

            <b-form-group id="target-subject" :label="`${$t('subject')}`" label-for="input-subject" label-class="subject" label-cols="3">
                <b-form-select id="input-subject" v-model="form.subject" :options="subjects"></b-form-select>
            </b-form-group>

            <b-form-group id="target-body" :label="`${$t('body')}`" label-for="input-body" label-class="body" label-cols="3">
                <b-form-select id="input-body" v-model="form.body" :options="bodies"></b-form-select>
            </b-form-group>

            <hr />
            <input type="hidden" name="captcha" ref="captcha" />
            <b-form-group id="comment" :label="`${$t('comment')}`" label-for="input-comment" label-class="title" label-cols="3">
                <b-form-textarea id="input-comment"
                                 v-model="form.comment"
                                 required
                                 ref="targetComment"
                                 rows="3"
                                 :placeholder="`${$t('comment')}...`"></b-form-textarea>
            </b-form-group>

            <hr />

            <b-form-group id="your-name" :label="`${$t('your_name')}`" label-for="input-name" label-cols="3">
                <b-form-input id="input-name"
                              v-model="form.name"
                              ref="name"
                              :state="getValidationState($v.form.name)"
                              :placeholder="$t('enter_name')"></b-form-input>
            </b-form-group>

            <b-form-group id="your-email" :label="`${$t('your_email')}`"
                          label-for="input-email"
                          class="required"
                          label-cols="3">
                <b-form-input id="input-email"
                              v-model="$v.form.email.$model"
                              type="email"
                              ref="email"
                              :state="getValidationState($v.form.email)"
                              required
                              :placeholder="$t('enter_email')"></b-form-input>
                <b-form-invalid-feedback>
                    <template v-if="!$v.form.email.$required || !$v.form.email.$email">
                        {{$t('validation_error_email')}}
                    </template>
                </b-form-invalid-feedback>
            </b-form-group>
            <div class="float-right mb-4">
                <b-button id="submit" v-to-lang type="submit" :disabled="loading" variant="primary">{{$t('submit')}}</b-button>
                <b-button id="reset" v-to-lang ref="reset" :disabled="loading" type="reset" variant="outline-dark">{{$t('reset')}}</b-button>
            </div>
        </b-form>
    </b-container>
</template>
<script>
    import Vue from 'vue'
    import Vuelidate from 'vuelidate'
    import { required, requiredIf, email, minLength } from 'vuelidate/lib/validators'
    import { languages, Term } from "../../Model/Constants"
    import { sendSuggestion, getBodies, getSubjects } from '../../DataServices/terminologyDataService';
    import Multiselect from 'vue-multiselect'
    import * as Actions from '../../Store/Actions'
    import { mapActions, mapGetters } from 'vuex'
    import UImage from '../UImage.vue'

    import messagesAr from "../../Lang/Home/Home-ar"
    import messagesEn from "../../Lang/Home/Home-en"
    import messagesFr from "../../Lang/Home/Home-fr"
    import messagesEs from "../../Lang/Home/Home-es"
    import messagesRu from "../../Lang/Home/Home-ru"
    import messagesZh from "../../Lang/Home/Home-zh"

    Vue.use(Vuelidate)

    export default {
        data: () => ({
            loading: false,
            form: {
                language: 'English',
                suggestion: '',
                source: '',
                description: '',
                context: '',
                targetLanguage: 'English',
                targetTitle: '',
                targetSource: '',
                targetContex: '',
                subject: '',
                body: '',
                comment: '',
                name: '',
                email: '',
            },
            show: true,
            submitted: false,
            failedToSubmit: '',
        }),
        validations: {
            form: {
                suggestion: {
                    required,
                    minLength: minLength(2)
                },
                source: {
                    required,
                    minLength: minLength(2)
                },
                email: {
                    email,
                    required
                },
                description: {
                    minLength: minLength(2)
                },
                context: {
                    minLength: minLength(2)
                },
                targetTitle: {
                    minLength: minLength(2)
                },
                targetSource: {
                    minLength: minLength(2)
                },
                targetContext: {
                    minLength: minLength(2)
                },
                name: {
                    minLength: minLength(2)
                },
            }
        },
        computed: {
            ...mapGetters([
                'getSearchSettings',
                'getDatasources',
                'getBodies',
                'getSubjects',
            ]),
            bodies() {
                let bodies = this.sortHelper(this.getBodies);
                return bodies.map(v => ({ text: this.$t(v.name), value: v.id }));
            },
            subjects() {
                let subjects = this.sortHelper( this.getSubjects);
                return subjects.map(v => ({ text: this.$t(v.name), value: v.id }));
            },
            languages() {
                return this.sortHelper(languages.reduce((prev, current) => {
                    if (current.isOfficial) {
                        prev.push({ text: this.$t(current.name.toLowerCase()), value: current.name })
                    }
                    return prev
                }, []))
            },
        },
        components: { Multiselect, UImage },
        methods: {
            ...mapActions({
                setDatasources: Actions.SET_DATASOURCES,
                setBodies: Actions.SET_BODIES,
                setSubjects: Actions.SET_SUBJECTS,
            }),
            async recaptcha() {
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('feedback')
                return token;
            },
            valid() {
                this.$v.$touch()
                return !this.$v.$error
            },
            async onSubmit() {

                if (this.valid()) {
                    this.loading = true;
                    const token = await this.recaptcha();

                    try {
                        let model = {
                            ...this.form,
                            captchaToken: token,
                            byPassToken: this.$refs.captcha.value
                        }
                        await sendSuggestion(model);
                        this.loading = false;
                        this.clearFormData()
                        this.$bvToast.toast(this.$t('feedback_success'), {
                            title: this.$t('success'),
                            variant: "success",
                            autoHideDelay: 5000,
                            appendToast: false
                        })
                    } catch (e) {
                        this.loading = false;
                        this.$bvToast.toast(this.$t('feedback_error'), {
                            title: this.$t('error'),
                            variant: "danger",
                            autoHideDelay: 5000,
                            appendToast: false
                        })
                    }
                }
                return { submitted: false };
            },
            clearFormData() {
                this.form.language = 'English'
                this.form.suggestion = ''
                this.form.source = ''
                this.form.description = ''
                this.form.context = ''
                this.form.targetLanguage = 'English'
                this.form.targetTitle = ''
                this.form.targetSource = ''
                this.form.targetContex = ''
                this.form.subject = ''
                this.form.body = ''
                this.form.comment = ''
                this.form.name = ''
                this.form.email = ''
                this.$v.$reset()
            },
            onReset(evt) {
                evt.preventDefault()
                this.clearFormData();
                this.$refs.recaptcha.reset()
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            },
            getValidationState(validator) {
                if (!validator) {
                    console.log('no validator found')
                    return;
                }
                if (!validator.$dirty)
                    return null
                else
                    return !validator.$error
            },
            removeField(field) {
                const index = this.form.fields.indexOf(field)
                if (index >= 0) {
                    this.form.fields.splice(index, 1)
                }
            },
            async checkCache() {
                if (this.getBodies.length == 0) {
                    let result = await getBodies()
                    let bodies = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                    this.setBodies(bodies)
                }

                if (this.getSubjects.length == 0) {
                    let result = await getSubjects()
                    let subjects = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                    this.setSubjects(subjects)
                }
            },
        },
        i18n: {
            messages: {
                ...messagesAr,
                ...messagesEn,
                ...messagesFr,
                ...messagesEs,
                ...messagesRu,
                ...messagesZh,
            }
        },
        async mounted() {
            this.form.suggestion = this.$route.query.q;
            await this.checkCache();
        }
    }
</script>
<style scoped lang="scss">
    form::v-deep {
        .required {
            & > label:after {
                content: "*";
                color: red;
            }
        }

        legend {
            font: bold 14px/30px Roboto;
            color: #3E3E3E;
        }

        label {
            font: normal 14px/16px Roboto;
            letter-spacing: 0.42px;
            color: #3E3E3E;

            &.title {
                font: bold 14px/30px Roboto;
                letter-spacing: 0px;
            }
        }

        #radio-langugages {
            label {
                font: normal 12px/14px Roboto;
                letter-spacing: 0.36px;
                color: #8292A5;
                padding-top: 4px;
            }
        }

        input[type=text], input[type=email], textarea {
            background: #F9FAFC 0% 0% no-repeat padding-box;
            border-radius: 4px;
            border: 0;
        }
    }
</style>
