var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    {
      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
      staticClass: "flex-nowrap shadow rounded-lg bg-white",
      attrs: { role: "search" },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _c("u-image", {
                staticClass: "magnifier bv-d-sm-down-none",
                attrs: { src: "theme/search.svg" },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "search-post" },
                [
                  _c("multiselect", {
                    staticClass:
                      "bg-transparent border-0 shadow-none searchType",
                    attrs: {
                      options: _vm.searchTypes,
                      "track-by": "value",
                      searchable: false,
                      "close-on-select": true,
                      "show-labels": false,
                      "preselect-first": true,
                      "allow-empty": false,
                      "aria-label":
                        "Search Type menu. Press Enter to activate the menu, then navigate with arrow keys.",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (props) {
                          return [
                            _c("span", [_vm._v(_vm._s(props.option.text))]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.searchType,
                      callback: function ($$v) {
                        _vm.searchType = $$v
                      },
                      expression: "searchType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "search-button",
                  attrs: {
                    variant: "primary",
                    disabled: _vm.disabled,
                    size: "lg",
                  },
                  on: { click: _vm.handleClick },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("search").toUpperCase()) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("SuggestionList", {
        attrs: { disabled: _vm.disabled },
        on: { search: _vm.raiseOnSearch },
        model: {
          value: _vm.userQuery,
          callback: function ($$v) {
            _vm.userQuery = $$v
          },
          expression: "userQuery",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }