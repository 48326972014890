export default {
    zh: {
        'what_is_unterm_title': '<span class="text-secondary">UNTERM</span>门户网站',
        'what_is_unterm': '<b>UNTERM</b>是一个多语种数据库，其中收录了与联合国工作相关的正式术语。这是一个公共网站，面向驻联合国代表、联合国工作人员以及世界各地对联合国工作和活动感兴趣的其他用户。',
        'multilingual_content_title': '多语文内容',
        'multilingual_content': '按照大会关于使用多种语文的任务规定，门户网站以联合国六种正式语文——阿拉伯文、中文、英文、法文、俄文和西班牙文——提供正式术语。',
        'global_management_title': '全球管理',
        'global_management': '<b>UNTERM</b>包含来自联合国系统各实体的术语条目。为确保整个联合国系统的一致性，数据库由专门负责不同主题的联合国语文工作人员管理。',
    }
}
