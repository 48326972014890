export default {
    fr: {
        'what_is_unterm_title': 'Portail <span class="text-secondary">UNTERM</span>',
        'what_is_unterm': '<b>UNTERM</b> est la base de données terminologique multilingue officielle des Nations Unies. Il s\'agit d\'un site Web public utilisé par les délégations comme par le personnel des Nations Unies, ainsi que par d\'autres personnes qui s\'intéressent au travail et aux activités des entités des Nations Unies partout dans le monde.',
        'multilingual_content_title': 'Contenu multilingue',
        'multilingual_content': 'La terminologie officielle est consignée dans les six langues officielles – anglais, arabe, chinois, espagnol, français et russe –, en application du mandat défini par l’Assemblée générale en matière de multilinguisme.',
        'global_management_title': 'Gestion mondiale',
        'global_management': '<b>UNTERM</b> réunit les fiches terminologiques de différentes entités du système des Nations Unies. La base de données est donc gérée par les spécialistes des différents sujets traités parmi les membres du personnel linguistique, dans un souci de cohérence terminologique.',
    }
}

