<template>
    <div>
        <b-row>
            <b-col>
                <b-container>
                    <h2 class="mt-5 mb-3 sr-only">Search terms</h2>
                    <div class="container mb-3 mt-n4">
                        <div class="row" id="search-box-row">
                            <search-input @search="doSearch" v-model="searchModel" :disabled="loading" />
                        </div>
                        <div>
                            <b-row class="mb-2 justify-content-between">
                                <b-col sm="auto" cols="8">
                                    <b-form-group v-to-lang.children id="fieldset-display-in"
                                                  :label="$t('display_results_in')"
                                                  label-class="text-nowrap">
                                        <b-button v-for="(lang, idx) in displayInLanguages"
                                                  :key="idx"
                                                  :title="$t(lang.name.toLowerCase())"
                                                  :pressed.sync="lang.state"
                                                  @click="onDisplayInClick"
                                                  variant="language" :class="{'ml-1': true }">
                                            {{ $t(lang.name.toLowerCase()) }}
                                        </b-button>
                                      
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" style="padding-right:0px !important;">
                                    <b-button
                                              id="download-country-button"
                                              @click="downloadList"
                                              v-shortkey.focus="['alt', 'd']"
                                              class="float-right mt-3"
                                              :style="getCurrentLanguage == 'ar'?'float:left !important;':'' "
                                              variant="primary"
                                              sm
                                              dark>
                                        <b-icon aria-label="" class="mr-2" icon="download" />
                                        {{$t('download_country_name')}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-container>
                <b-container>
                    <div>
                        <b-table hover show-empty
                                 :items="getSearchResults.results"
                                 :busy="loading"
                                 thead-tr-class="language-name-title"
                                 :fields="tableFields"
                                 id="results-table"
                                 :no-local-sorting="true"
                                 @sort-changed="handleSorting"
                                 :empty-text="noResultsText"
                                 responsive>

                            <template v-slot:row-details="row">
                                <b-card class="record-preview">
                                    <span class="float-right close-icon" data-effect="fadeOut" @click="row.toggleDetails">
                                        <i class="fa fa-times fa-2x"></i>
                                    </span>
                                    <record-preview :Record="row.item"></record-preview>
                                </b-card>
                            </template>

                            <template v-slot:cell(actions)="row">
                                <div class="status-icons">
                                    <i v-to-lang :title="$t(`${row.item.distribution.toLowerCase()}_record`)" :class="['info', `distribution-${row.item.distribution}`]"></i>
                                    <i v-to-lang :title="$t('record_'+row.item.status)" :class="['info', `status-${row.item.status}`]"></i>
                                </div>
                                <b-button v-to-lang size="sm" @click="viewTerm(row.item)" class="mr-1 px-2 py-0" variant="grid">
                                    {{$t('open')}}
                                </b-button>
                                <b-button v-to-lang size="sm" @click="row.toggleDetails" class="px-2 py-0" variant="grid">
                                    {{$t('details')}}
                                </b-button>
                            </template>

                            <template v-slot:cell()="data">
                                <language-result :search-term="searchTerm" :language-data="data.value" v-if="data.field.isLang"
                                                 :language-code="data.field.langCode" />
                                <record-info :Record="data.item" v-if="!data.field.isLang" />
                            </template>

                            <template v-to-lang v-slot:head()="data">
                                {{ $t(data.label.toLowerCase()) }}
                            </template>

                            <template #empty="scope">
                                <div role="alert" aria-live="polite">
                                    <div class="text-center my-2">
                                        {{$t('country_not_found_message')}}
                                    </div>
                                </div>
                            </template>


                            <template v-slot:table-busy>
                                <div class="text-center text-primary my-5">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong v-to-lang> {{ $t('loading') }}...</strong>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination v-model="currentPage" limit="15"
                                      :total-rows="getTotalHits"
                                      :per-page="getPageSize"
                                      aria-controls="results-table"
                                      v-on:change="handlePageChange"
                                      align="center"></b-pagination>
                    </div>
                </b-container>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import * as NProgress from "nprogress"
    import * as TerminologyDataService from "../../DataServices/terminologyDataService"
    import LanguageResult from "./LanguageResult.vue"
    import RecordPreview from "./RecordPreview.vue"
    import RecordInfo from "./RecordInfo.vue"
    import { mapActions, mapGetters } from 'vuex'
    import * as Actions from '../../Store/Actions'
    import SearchInput from "./SearchInput.vue"
    import { languages } from "../../Model/Constants"
    import { SearchModel } from "../../Model/SearchModel"
    import Multiselect from 'vue-multiselect'
    import * as urlHelper from "../../urlHelper";


    export default {
        name: "SearchPane",
        data: function () {
            return {
                searchModel: new SearchModel(),
                loading: false,
                noResultsText: 'No results yet',
                languageOptions: [...languages],
                advancedFiltersVisible: false,
                searchTerm: null,
                highlightData: null,
                displayInOtherLangVisible: false,
                currentPage: 1
            }
        },
        components: {
            LanguageResult,
            RecordInfo,
            RecordPreview,
            SearchInput,
            Multiselect
        },
        mounted() {
            let prevSearch = this.getSearchModel
            if (prevSearch != null && prevSearch.hasSearchTerm()) {
                this.searchModel = prevSearch
            }
            this.setDefaultLanguage(this.searchModel);
            this.searchModel.searchTerm = this.queryTerm
            //this.defaultColumns()
            this.doSearch()
        },
        computed: {
            ...mapGetters([
                'getSearchResults',
                'getSearchModel',
                'getTotalHits',
                'getPageSize',
                'getSearchFacets',
                'getCurrentLanguage'
            ]),            
            excelExportUrl() {
                return urlHelper.apicall(`term/downloadCountries`);
            },
            displayInLanguages: {
                get() {
                    return this.sortHelper(languages.filter(l => !['de', 'pt', 'ed'].includes(l.code)).map(lang => {
                        return {
                            state: this.searchModel.languagesDisplay.includes(lang.code),
                            ...lang
                        }
                    }))
                },
                set(newVal) {
                    this.searchModel.languagesDisplay = newVal.map(x => x.code)
                }
            },            
            queryTerm() {
                return this.$route.query.searchTerm;
            },
            selectedLangs() {
                return languages.filter(lang => { return this.searchModel.searchLanguages.find(x => x == lang.code) })
            },
            tableFields: {
                get() {
                    const selectedLangs = languages.filter(lang => { return this.searchModel.languagesDisplay.find(x => x == lang.code) })
                    return [
                        { key: 'actions', label: '', sortable: false },
                        ...selectedLangs.map(l => { return { key: l.name.toLowerCase(), langCode: l.code, sortable: false, isLang: true, headerTitle: this.$t(l.name.toLowerCase()) } }),
                        { key: 'recordinfo', label: '', sortable: false, langCode: '', isLang: false }
                    ]
                }
            },            
        },
        watch: {
            getSearchSettings() {
                this.setDefaultLanguage(this.searchModel);
            },
            loading(val: boolean) {
                if (val) {
                    NProgress.start();
                } else {
                    NProgress.done();
                }
            }
        },
        methods: {
            ...mapActions({
                setSearchResults: Actions.SET_SEARCH_RESULTS,
                changePage: Actions.SEARCH_CHANGE_PAGE,
                setSearchModel: Actions.SET_SEARCH_MODEL,
            }),
            downloadList() {
                let element = document.createElement('a');
                element.href = this.excelExportUrl;
                element.target = '_blank';
                element.click();
            },
            defaultColumns() {
                this.searchModel.languagesDisplay = languages
                    .filter(lang => ['en', 'fr'].includes(lang.code))
                    .map(lang => lang.code);
            },
            onDisplayInClick() {
                this.searchModel.languagesDisplay = this.displayInLanguages.reduce((col, lang) => {
                    if (lang.state || lang.code == 'en') {
                        col.push(lang.code);
                    }
                    return col;
                }, [])
            },            
            doSearch() {
                document.querySelector('th')?.click()
                this.searchTerm = this.searchModel?.searchTerm?.trim()
                this.setSearchModel(this.searchModel)
                this.fetchData(1)
            },
            async fetchData(page) {
                this.$data.loading = true;
                try {
                    let results = await TerminologyDataService.searchCountry(this.getSearchModel, page);
                    this.setSearchResults(results)
                    this.$data.loading = false
                    if (this.getSearchResults.results.length == 0) {
                        this.$data.noResultsText = this.$t('country_not_found_message');
                    }
                } catch (e) {
                    this.setSearchResults(null)
                    this.$data.noResultsText = this.$t('error_executing_search')
                    this.$bvToast.toast(this.$t('search_failed'), {
                        title: 'Error!',
                        variant: "danger",
                        autoHideDelay: 3000,
                        appendToast: false
                    })
                    this.$data.loading = false
                }
            },
            handlePageChange(pageNumber) {
                this.fetchData(pageNumber);
            },
            viewTerm(item) {
                const resolved = this.$router.resolve({ name: 'viewTerm', params: { recordId: item.recordID, langCode: this.getCurrentLanguage } })
                window.open(resolved.href, "record-" + item.recordID)
            },
            handleSorting(ctx) {
                if (ctx.sortBy) {
                    this.searchModel.sortBy = this.languageOptions.find(x => x.name.toLowerCase() == ctx.sortBy.toLowerCase()).code;
                    this.searchModel.sortDirection = ctx.sortDesc ? "desc" : "asc"
                    this.handlePageChange(1)
                } else {
                    this.searchModel.sortBy = '';
                    this.searchModel.sortDirection = ''
                }
            },
            setAdvancedSearchVisible(visible: boolean) {
                this.advancedFiltersVisible = visible
            },
        }
    }
</script>
<style lang="scss">
    #search-box-row .search-input {
        width: inherit;
    }

    .highlight-row {
        background-color: #ebebeb;
    }

    .table th, .table td {
        min-width: 150px;
    }

 
</style>
<style scoped lang="scss">
    .table-actions button {
        margin-bottom: 3px;
    }

    .status-icons {
        width: 100px;
        display: inline-block;
        vertical-align: middle;
        padding-left: 7px;
    }

    .record-preview {
        background-color: #F9FAFC;
        padding: 0 45px;
    }

    .sidebar {
        margin-left: 8px;
        /*margin-top: 4rem;
        max-width: 290px;*/
    }

    em {
        color: red;
    }

    .suggestion-area {
        cursor: pointer;
    }

    .suggestion-area .icon {
        float: left;
    }

    .suggestion-area .message-text {
        padding: 10px;
        margin-left: 40px;
        color: #666666;
        font-weight: bold;
    }

 
    .row {
        margin-right: auto !important;
    }

    .filter-badge {
        margin-left: 10px;
    }
</style>
