var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0 mb-2" },
    [
      _vm.show
        ? _c(
            "b-form",
            {
              directives: [
                {
                  name: "to-lang",
                  rawName: "v-to-lang.children",
                  modifiers: { children: true },
                },
              ],
              staticClass: "mt-3",
              attrs: { novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                },
                reset: _vm.onReset,
              },
            },
            [
              _vm.submitted
                ? _c("h1", [_vm._v("Feedback submitted")])
                : _vm._e(),
              _vm._v(" "),
              _vm.failedToSubmit
                ? _c(
                    "h1",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      staticClass: "error",
                      attrs: { id: "error" },
                    },
                    [_vm._v(_vm._s(_vm.$t("error")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "h1",
                {
                  staticClass: "mb-5",
                  attrs: { tabindex: "-1", id: "suggestion-page-heading" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("fill_in_the_form_to_suggest_a_new_entry"))
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "required",
                  attrs: {
                    id: "source-language",
                    label: "" + _vm.$t("source_language"),
                    "label-for": "input-sourceLang",
                    "label-class": "title",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "input-sourceLang", options: _vm.languages },
                    model: {
                      value: _vm.form.language,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "language", $$v)
                      },
                      expression: "form.language",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "required",
                  attrs: {
                    id: "term-title",
                    label: _vm.$t("term") + "/" + _vm.$t("title"),
                    "label-for": "input-suggestion",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-textarea", {
                    ref: "suggestion",
                    attrs: {
                      id: "input-suggestion",
                      rows: "1",
                      state: _vm.getValidationState(_vm.$v.form.suggestion),
                      required: "",
                    },
                    model: {
                      value: _vm.$v.form.suggestion.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.suggestion, "$model", $$v)
                      },
                      expression: "$v.form.suggestion.$model",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-invalid-feedback",
                    [
                      !_vm.$v.form.suggestion.$required
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("validation_suggestion")) +
                                "\n                "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "required",
                  attrs: {
                    id: "source",
                    label: _vm.$t("source"),
                    "label-for": "input-source",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "source",
                    attrs: {
                      id: "input-source",
                      state: _vm.getValidationState(_vm.$v.form.source),
                      required: "",
                      placeholder: _vm.$t("source"),
                    },
                    model: {
                      value: _vm.form.source,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "source", $$v)
                      },
                      expression: "form.source",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-invalid-feedback",
                    [
                      !_vm.$v.form.source.$required
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("validation_source")) +
                                "\n                "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "defination",
                    label:
                      _vm.$t("definition") +
                      "/" +
                      _vm.$t("description").toLowerCase(),
                    "label-for": "input-description",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "description",
                    attrs: {
                      id: "input-description",
                      rows: "1",
                      state: _vm.getValidationState(_vm.$v.form.description),
                      placeholder:
                        _vm.$t("definition") +
                        "/" +
                        _vm.$t("description").toLowerCase(),
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "context",
                    label: "" + _vm.$t("context"),
                    "label-for": "input-context",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "context",
                    attrs: {
                      id: "input-context",
                      rows: "1",
                      state: _vm.getValidationState(_vm.$v.form.context),
                      placeholder: "" + _vm.$t("context"),
                    },
                    model: {
                      value: _vm.form.context,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "context", $$v)
                      },
                      expression: "form.context",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "required",
                  attrs: {
                    id: "target-language",
                    label: "" + _vm.$t("target_language"),
                    "label-for": "input-targetLang",
                    "label-class": "title",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "input-targetLang", options: _vm.languages },
                    model: {
                      value: _vm.form.targetLanguage,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "targetLanguage", $$v)
                      },
                      expression: "form.targetLanguage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "target-title",
                    label:
                      _vm.$t("target") +
                      " " +
                      _vm.$t("term") +
                      "/" +
                      _vm.$t("title"),
                    "label-for": "input-t-title",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-textarea", {
                    ref: "targetTitle",
                    attrs: {
                      id: "input-t-title",
                      rows: "1",
                      state: _vm.getValidationState(_vm.$v.form.targetTitle),
                      placeholder: _vm.$t("term") + "/" + _vm.$t("title"),
                    },
                    model: {
                      value: _vm.form.targetTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "targetTitle", $$v)
                      },
                      expression: "form.targetTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "target-source",
                    label: "" + _vm.$t("source"),
                    "label-for": "input-t-source",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "targetSource",
                    attrs: {
                      id: "input-t-source",
                      rows: "1",
                      state: _vm.getValidationState(_vm.$v.form.targetSource),
                      placeholder: "" + _vm.$t("source"),
                    },
                    model: {
                      value: _vm.form.targetSource,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "targetSource", $$v)
                      },
                      expression: "form.targetSource",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "target-context",
                    label: "" + _vm.$t("context"),
                    "label-for": "input-t-context",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "targetContext",
                    attrs: {
                      id: "input-t-context",
                      rows: "1",
                      state: _vm.getValidationState(_vm.$v.form.targetContext),
                      placeholder: "" + _vm.$t("context"),
                    },
                    model: {
                      value: _vm.form.targetContext,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "targetContext", $$v)
                      },
                      expression: "form.targetContext",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "target-subject",
                    label: "" + _vm.$t("subject"),
                    "label-for": "input-subject",
                    "label-class": "subject",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "input-subject", options: _vm.subjects },
                    model: {
                      value: _vm.form.subject,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "subject", $$v)
                      },
                      expression: "form.subject",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "target-body",
                    label: "" + _vm.$t("body"),
                    "label-for": "input-body",
                    "label-class": "body",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: { id: "input-body", options: _vm.bodies },
                    model: {
                      value: _vm.form.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "body", $$v)
                      },
                      expression: "form.body",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("input", {
                ref: "captcha",
                attrs: { type: "hidden", name: "captcha" },
              }),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "comment",
                    label: "" + _vm.$t("comment"),
                    "label-for": "input-comment",
                    "label-class": "title",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-textarea", {
                    ref: "targetComment",
                    attrs: {
                      id: "input-comment",
                      required: "",
                      rows: "3",
                      placeholder: _vm.$t("comment") + "...",
                    },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "your-name",
                    label: "" + _vm.$t("your_name"),
                    "label-for": "input-name",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "name",
                    attrs: {
                      id: "input-name",
                      state: _vm.getValidationState(_vm.$v.form.name),
                      placeholder: _vm.$t("enter_name"),
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "required",
                  attrs: {
                    id: "your-email",
                    label: "" + _vm.$t("your_email"),
                    "label-for": "input-email",
                    "label-cols": "3",
                  },
                },
                [
                  _c("b-form-input", {
                    ref: "email",
                    attrs: {
                      id: "input-email",
                      type: "email",
                      state: _vm.getValidationState(_vm.$v.form.email),
                      required: "",
                      placeholder: _vm.$t("enter_email"),
                    },
                    model: {
                      value: _vm.$v.form.email.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.form.email, "$model", $$v)
                      },
                      expression: "$v.form.email.$model",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-invalid-feedback",
                    [
                      !_vm.$v.form.email.$required || !_vm.$v.form.email.$email
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("validation_error_email")) +
                                "\n                "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "float-right mb-4" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      attrs: {
                        id: "submit",
                        type: "submit",
                        disabled: _vm.loading,
                        variant: "primary",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("submit")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      ref: "reset",
                      attrs: {
                        id: "reset",
                        disabled: _vm.loading,
                        type: "reset",
                        variant: "outline-dark",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("reset")))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }