//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SearchModel } from "../../Model/SearchModel";
export default {
    name: 'SidePanel',
    props: {
        value: {
            required: true
        },
        facets: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        selectedDatasets: {
            get() {
                return this.searchModel.datasets||[];
            }
        },
        selectedBodies: {
            get() {
                return this.searchModel.bodies || [];
            }
        },
        selectedSubjects: {
            get() {
                return this.searchModel.subjects || [];
            }
        },
        searchModel: {
            get(): SearchModel {
                return this.value
            },
        },
    },
    methods: {
        highlightRows(highlight) {
            this.$emit('highlight', highlight)
        },
        clearDbNamesFilter() {
            this.searchModel.datasets = [];
            this.$emit('search');
        },
        clearSubjectsFilter() {
            this.searchModel.subjects = [];
            this.$emit('search');
        },
        clearBodiesFilter() {
            this.searchModel.bodies = [];
            this.$emit('search');
        },
        updateFacets(check, data) {
            if (check) {
                this.searchModel[data.name].push(data.value);
            } else {
                let index = this.searchModel[data.name].findIndex(v => v == data.value)
                this.searchModel[data.name].splice(index,1);
            }
            this.$emit('input', this.searchModel);
            this.$emit('search');
        }
    }
}
