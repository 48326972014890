//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { RecordModel } from "../../Model/RecordModel";


export default {
    name: "Term",
    data: function () {
        return {
        }
    },
    props: ["data", "record","LanguageCode"],
    components: { },
    watch: { },
    computed: {
        displayType() {
            return this.$t(RecordModel.getDisplayType(this.data.termType, this.data.termStatus, this.record.recordType))
        }
    },
}
