var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "col-9", attrs: { id: "home-container" } },
    [
      _c("div", { staticClass: "unterm-logo" }, [
        _c("img", {
          staticStyle: { height: "100px" },
          attrs: {
            src: _vm.basePath + "images/unterm-logo.svg",
            alt: "UNTERM logo",
          },
        }),
      ]),
      _vm._v(" "),
      _c("h2", {
        staticClass: "text-black",
        attrs: { id: "page-title" },
        domProps: { innerHTML: _vm._s(_vm.$t("home_title")) },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row", attrs: { id: "search-box-row" } },
        [
          _c("h2", { staticClass: "sr-only" }, [_vm._v("Search terms")]),
          _vm._v(" "),
          _c("search-input", {
            attrs: { disabled: _vm.loading },
            on: { search: _vm.doSearch },
            model: {
              value: _vm.searchModel,
              callback: function ($$v) {
                _vm.searchModel = $$v
              },
              expression: "searchModel",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("search-filters", {
        staticClass: "mb-3",
        model: {
          value: _vm.searchModel,
          callback: function ($$v) {
            _vm.searchModel = $$v
          },
          expression: "searchModel",
        },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "text-center mt-5 d-flex justify-content-around" },
        [
          _c(
            "b-col",
            { staticClass: "pr-lg-4 pr-md-2", attrs: { md: "4", sm: "auto" } },
            [
              _c(
                "div",
                { staticClass: "site-info p-4" },
                [
                  _c("u-image", {
                    attrs: { src: "theme/Icon feather-globe.svg" },
                  }),
                  _vm._v(" "),
                  _c("h5", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    staticClass: "my-4",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("what_is_unterm_title")),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    staticClass: "text-justify",
                    domProps: { innerHTML: _vm._s(_vm.$t("what_is_unterm")) },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "px-lg-4 px-md-2", attrs: { md: "4", sm: "auto" } },
            [
              _c(
                "div",
                { staticClass: "site-info p-4" },
                [
                  _c("u-image", {
                    attrs: { src: "theme/Icon material-translate.svg" },
                  }),
                  _vm._v(" "),
                  _c("h5", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    staticClass: "my-4",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("multilingual_content_title")),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    staticClass: "text-justify",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("multilingual_content")),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "pl-lg-4 pl-md-2", attrs: { md: "4", sm: "auto" } },
            [
              _c(
                "div",
                { staticClass: "site-info p-4" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fa", "users"], size: "3x" },
                  }),
                  _vm._v(" "),
                  _c("h5", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    staticClass: "my-4",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("global_management_title")),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    staticClass: "text-justify",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("global_management")),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }