<template>
    <div class="vue-simple-suggest search-input designed" :class="{ focus: suggestionsShowing }">
        <div role="combobox" aria-haspopup="listbox" aria-owns="61-suggestions" :aria-expanded="suggestionsShowing"
             class="input-wrapper">
            <input id="autoComplete" type="text" class="form-control form-control-lg my-2"
                   autocomplete="off"
                   v-shortkey.focus="['alt', 's']"
                   :value="userQuery"
                   :placeholder="$t('search_database') + '...'" :maxlength="244"
                   :aria-label="$t('enter_text_search')" aria-describedby="basic-addon2" :disabled="disabled"
                   ref="searchInput" @input="updateValue" @keydown="handleListNavigation" @blur="suggestionsShowing = hoveringSuggestions;" @focus="showSuggestions"
                   @keypress.esc="suggestionsShowing = false">
            <div @click="clearInput" id="clear-input" style="height:1px;width:10px;">
                <svg v-if="hasInput" aria-hidden="true" focusable="false" data-prefix="fas"
                     data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="search-input-clear pointer hover-red5 svg-inline--fa fa-times-circle fa-w-16 fa-fw fa-lg">
                    <path fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                          class=""></path>
                </svg>
            </div>
            <span :aria-label="$t('exit_suggestions')" class="hidden1" style="display: none;"></span>
        </div>
        <ul v-if="suggestionsShowing && suggestions.length" id="61-suggestions" role="listbox"
            @keydown.esc="suggestionsShowing = false" aria-labelledby="61-suggestions" class="suggestions">
            <li id="searching-for">
                <div class="misc-item">
                    <span role="alert" :aria-label="$t('exit_suggestions')" title="united" v-to-lang>
                        {{ $t('searching_for') }} <b>{{ userQuery }}</b>.
                    </span>
                </div>
            </li>

            <template v-for="(item, index) in suggestions">
                <li v-if="item.type == 'history'" :key="index" role="option" aria-selected="false"
                    :id="`61-suggestions-suggestion-history-${index}`" class="suggest-item">
                    <div class="misc-item">
                        <span title="unite" :class="item.type" v-to-lang>
                            {{ item.title }}
                        </span>
                    </div>
                </li>
                <li v-if="item.type == 'search'" :key="index" role="option" aria-selected="false"
                    :id="`61-suggestions-suggestion-search-${index}`" class="suggest-item">
                    <div class="misc-item">
                        <span :title="item.title" :class="item.type" v-to-lang>
                            {{ item.title }}
                        </span>
                    </div>
                </li>
            </template>


            <li id="clear-history" role="button" class="clear-history">
                <div class="misc-item" @click="() => clearSearchHistory()">
                    <a role="button" style="color:dodgerblue;text-decoration:underline;cursor:pointer;" class="" v-to-lang>{{ $t('clear_search_history') }}</a>
                </div>
            </li>
        </ul>
    </div>

</template>

<script lang="ts">
    import SearchHistory from "./SearchHistory"
    import Vue from "vue"
    import * as TerminologyDataService from "../../DataServices/terminologyDataService";
    import $ from "jquery";

    export default {
        name: "SuggestionList",
        data: () => ({
            suggestionsShowing: false,
            suggestions: [],
            timer: null,
            hoveringSuggestions: false,
        }),
        methods: {
            updateValue(e) {
                this.userQuery = e.target.value;
            },
            showSuggestions() {
                this.suggestionsShowing = true;
                this.debounce(() => this.onListHover(), 400);
            },
            clearInput() {
                this.$refs.searchInput._value = "";
                this.userQuery = '';
            },
            async typeAhead() {
                if (!this.userQuery) return;
                let result: { id: string, title: string, type: string }[] = SearchHistory.getMatchingTerms(this.userQuery)
                    .map((val, ix, ar) => {
                        return { id: `history-${ix}`, title: val, type: 'history' }
                    })
                await TerminologyDataService.typeahead(this.userQuery).then(values => {
                    let items = values.map((val, ix, ar) => {
                        return { id: `search-${ix}`, title: val, type: 'search' }
                    })
                    result.push(...items)
                })
                this.suggestions = result.slice(0, 10);
                this.debounce(() => this.onListHover(), 400);
            },
            clearSearchHistory() {
                console.log('clear history');
                this.suggestions = []
                SearchHistory.clear()
                this.hoveringSuggestions = false;
            },
            raiseOnSearch() {
                SearchHistory.addTerm(this.value.searchTerm)
                this.hoveringSuggestions = false;
                this.suggestionsShowing = false;
                this.$emit('search', this.value)
            },
            handleEnterKey() {
                //if enter was pressed without using the suggestions trigger search from input content
                if (!this.$refs.searchInput.hovered || !this.suggestionsShowing) {
                    this.raiseOnSearch()
                }
            },
            onSelect() {
                Vue.nextTick().then(this.raiseOnSearch)
            },
            debounce(func, timeout = 200) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    func();
                }, timeout);
            },
            onListHover() {
                let ctx = this;
                $(".suggest-item, .clear-history").unbind();
                $(".suggest-item, .clear-history").mouseover(function (e) {
                    ctx.hoveringSuggestions = true;
                    if (e.target.className == 'suggest-item') {
                        $(".suggest-item").removeClass("hover");
                        $(".suggest-item").attr("aria-selected", false);
                        $(this).addClass("hover");
                        $(this).attr("aria-selected", true);
                        $('#autoComplete').attr('aria-activedescendant', $(this).attr("id"))
                    }
                }).mouseleave(function (e) {
                    ctx.hoveringSuggestions = false;
                }).click(function (e) {
                    if (['history', 'search', 'suggest-item'].includes(e.target.className))
                        ctx.selectOption();
                });
            },
            handleListNavigation(e) {
                const ctx = this as any;
                ctx.suggestionsShowing = true;
                if (e.keyCode == 18 || e.keyCode == 27) {
                    this.suggestionsShowing = false;
                    Vue.nextTick().then(() => this.suggestionsShowing = false)
                    return;
                }
                if (e.keyCode == 13) { // enter
                    if (ctx.suggestionsShowing) {
                        this.selectOption();
                    }
                    e.preventDefault();
                }
                if (e.keyCode == 38) { // up
                    var selected = $(".hover").length == 0 ? $(".suggest-item").last() : $(".hover");
                    selected.removeClass("hover");
                    selected.attr("aria-selected", false);
                    if (selected.prev().length == 0) {
                        selected.siblings().last().addClass("hover");
                    } else {
                        selected.prev().addClass("hover");
                        selected.prev().attr("aria-selected", true);
                        $('#autoComplete').attr('aria-activedescendant', selected.prev().attr("id"))
                    }
                }
                if (e.keyCode == 40) { // down
                    var selected = $(".hover").length == 0 ? $(".suggest-item").first() : $(".hover");
                    selected.removeClass("hover");
                    selected.attr("aria-selected", false);
                    if (selected.next().length == 0) {
                        selected.siblings().first().addClass("hover");
                    } else {
                        selected.next().addClass("hover");
                        selected.next().attr("aria-selected", true);
                        $('#autoComplete').attr('aria-activedescendant', selected.next().attr("id"))
                    }
                }
                this.onListHover();
            },
            selectOption() {
                let searchSuggestions = $(".hover span").text().trim();
                this.userQuery = searchSuggestions == '' ? this.userQuery : searchSuggestions;
                this.handleEnterKey();
                this.suggestionsShowing = false;
            }
        },
        computed: {
            hasInput() {
                return this.userQuery
            },
            userQuery: {
                get() {
                    return this.value
                },
                set(newVal) {
                    this.$emit('input', newVal)
                }
            },
        },
        props: {
            value: {
                required: true
            },
            disabled: { default: false }
        },
        watch: {
            async userQuery(val) {
                if (val) {
                    this.debounce(async () => await this.typeAhead());
                }
            }
        },
        async mounted() {
            await this.typeAhead();
        }
    }
</script>

<style scoped>
    .search-input-clear {
        position: absolute;
        right: 0.5rem;
        top: 1.5rem;
        color: #adb5bd;
        cursor: pointer;
    }


        .search-input-clear:focus,
        .search-input-clear:hover {
            color: #009EDB;
            opacity: .8;
        }

    .search-button {
        /*border: 1px solid #cde;*/
        border-left: none;
    }

    ul.suggestions {
        top: 100% !important;
    }

    img.magnifier {
        margin-left: 30px;
        width: 30px;
    }

    .misc-item span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
    }
</style>
