import Axios from "axios";
import { SearchModel } from "../Model/SearchModel";
import * as urlHelper from "../urlHelper";
import { FeedbackModel } from "../Model/FeedbackModel";
import { SearchSettings } from "../Model/SearchSettings";

export async function searchTerm(searchModel: SearchModel, page: number, facetedSearch: boolean) {
    searchModel.facetedSearch = facetedSearch;
    searchModel.buildSubjectList = !searchModel.facetedSearch;
    const url = urlHelper.apicall(`term/search?query=${searchModel.searchTerm}&page=${page}`);

    return await Axios.post(url, searchModel).then<Array<object>>(
        x => x.data as Array<object>
    )
}

export async function searchCountry(searchModel: SearchModel, page: number) {
    const url = urlHelper.apicall(`term/countries?query=${searchModel.searchTerm ? searchModel.searchTerm : ''}&page=${page}`);

    return await Axios.post(url, searchModel).then<Array<object>>(
        x => x.data as Array<object>
    )
}

export async function saveSearchSettings(searchModel: SearchModel) {
    const url = urlHelper.action("Infrastructure", "SaveSearchSettings");
    const settings = {
        searchType: searchModel.searchType,
        languagesDisplay: searchModel.languagesDisplay,
        searchLanguages: searchModel.searchLanguages,
        dataSets: searchModel.datasets
    }
    return await Axios.post(url, settings)
}

export async function getClientSettings() {
    const url = urlHelper.action("Infrastructure", "GetClientSettings");
    return await Axios.get(url).then<any>(x => x.data)
}


export async function getSubjects() {
    const url = urlHelper.apicall(`subject`);

    return await Axios.get(url).then<Array<any>>(
        x => x.data as Array<any>
    )
}

export async function getBodies() {
    const url = urlHelper.apicall(`body`);

    return await Axios.get(url).then<Array<any>>(
        x => x.data as Array<any>
    )
}

export async function getDatasources() {
    const url = urlHelper.apicall(`datasource`);

    return await Axios.get(url).then<Array<object>>(
        x => x.data as Array<object>
    )
}

export async function typeahead(searchText: string) {
    if (!searchText || searchText.trim().length === 0) return null;
    const url = urlHelper.apicall(`term/typeahead?text=${encodeURIComponent(searchText.toLowerCase())}`);

    return await Axios.get(url).then<string[]>(
        x => x.data as string[]
    )
}

export async function getRecordById(dbname: string, recordId: string) {
    let path = 'record/'
    if (dbname) {
        path += dbname + '/'
    }
    path += recordId

    const url = urlHelper.apicall(path);

    return await Axios.get(url).then<Array<object>>(
        x => x.data as Array<object>
    )
}

export async function sendFeedback(feedback: FeedbackModel) {
    const url = urlHelper.apicall(`feedback/`);

    return await Axios.post(url, feedback)
}

export async function sendSuggestion(feedback: any) {
    const url = urlHelper.apicall(`feedback/suggestion`);

    return await Axios.post(url, feedback)
}
