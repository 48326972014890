//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'

library.add(faSquare, faCheckSquare)

export default {
    name: "MultiselectFilter",
    data: function () {
        return {
        }
    },
    props: {
        value: {
            required: true
        },
        name: {
            type: String,
            default: ""
        },
        options: {
            required: true
        },
        limit: {
            type: Number,
            default: 3
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    components: { Multiselect },
    computed: {
        selection: {
            get() {
                return this.value
            },
            set(newVal) {
                //this.searchModel.datasets = newVal.map(x => x.code)
                this.$emit('input', newVal)
            }
        }
    },
    methods: {
    }
}
