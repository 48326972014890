//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MultiselectFilter from './MultiselectFilter.vue'
import { SearchModel } from "../../Model/SearchModel";
import { library } from '@fortawesome/fontawesome-svg-core'
import { languages as allLanguages } from "../../Model/Constants"
import * as TerminologyDataService from "../../DataServices/terminologyDataService";
import * as Actions from '../../Store/Actions'
import { mapActions, mapGetters } from 'vuex'
import { faAngleDown, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faAngleDown, faAngleRight, faAngleLeft)


export default {
    name: "SearchFilters",
    data: function () {
        return {
            searchInOtherLangVisible: false,
            displayInOtherLangVisible: false,
            recordtypes: [
                { name: 'Terms', code: 'term' },
                { name: 'Titles', code: 'title' },
                { name: 'Countries', code: 'country' },
                { name: 'Phraseology', code: 'phraseology' },
                { name: 'Footnotes', code: 'footnote' }
            ],
            innerdatasets: []
        }
    },
    components: {
        MultiselectFilter
    },
    mounted() {
        this.checkCache()
    },
    props: {
        value: {
            required: true,
        },
        'disabled': {
            type: Boolean,
            default: false
        },
    },
    computed: {
        otherDisplayActive() {
            return this.displayInOtherLangVisible
                || this.displayInLanguages
                    .filter(x => x.state)
                    .map(x => x.name)
                    .some(name => allLanguages
                        .filter(x => !x.isOfficial)
                        .map(x => x.name)
                        .includes(name));
        },
        otherSearchInActive() {
            return this.displayInOtherLangVisible
                || this.searchInlanguages
                    .filter(x => x.state)
                    .map(x => x.name)
                    .some(name => allLanguages
                        .filter(x => !x.isOfficial)
                        .map(x => x.name)
                        .includes(name));
        },
        datasets() {
            let datasources = this.sortHelper(this.getDatasources);
            return datasources.map(v => ({ name: this.$t(v.name), code: v.name }));
        },
        bodies() {
            let bodies = this.sortHelper(this.getBodies);
            return bodies.map(v => ({ name: this.$t(v.name), code: v.code }));
        },
        subjects() {
            let subjects = this.sortHelper(this.getSubjects);
            return subjects.map(v => ({ name: this.$t(v.name), code: v.code }));
        },
        ...mapGetters([
            'getSearchSettings',
            'getDatasources',
            'getBodies',
            'getSubjects',
        ]),
        searchInlanguages: {
            get() {
                return allLanguages.map(lang => {
                    return {
                        state: this.searchModel.searchLanguages.find(l => l == lang.code) != null,
                        ...lang
                    }
                })
            },
            set(newVal) {
                this.searchModel.searchLanguages = newVal.map(x => x.code)
                this.$emit('input', this.searchModel)
            }
        },
        displayInLanguages: {
            get() {
                return allLanguages.map(lang => {
                    return {
                        state: this.searchModel.languagesDisplay.find(l => l == lang.code) != null,
                        ...lang
                    }
                })
            },
            set(newVal) {
                this.searchModel.languagesDisplay = newVal.map(x => x.code)
                this.$emit('input', this.searchModel)
            }
        },
        selectedDatasets: {
            get() {
                return this.datasets.filter(ds => (this.searchModel.datasets||[]).find(d => d == ds.code) != null)
            },
            set(newVal) {
                this.searchModel.datasets = newVal.map(x => x.code)
                this.$emit('input', this.searchModel)
            }
        },
        selectedBodies: {
            get() {
                return this.bodies.filter(ds => (this.searchModel.bodies||[]).find(d => d == ds.code) != null)
            },
            set(newVal) {
                this.searchModel.bodies = newVal.map(x => x.code)
                this.$emit('input', this.searchModel)
            }
        },
        selectedSubjects: {
            get() {
                return this.subjects.filter(ds => (this.searchModel.subjects || []).find(d => d == ds.code) != null)
            },
            set(newVal) {
                this.searchModel.subjects = newVal.map(x => x.code)
                this.$emit('input', this.searchModel)
            }
        },
        searchModel: {
            get(): SearchModel {
                return this.value
            },
            set(newVal: SearchModel) {
                this.$emit('input', newVal)
            }
        },
    },
    methods: {
        ...mapActions({
            setDatasources: Actions.SET_DATASOURCES,
            setBodies: Actions.SET_BODIES,
            setSubjects: Actions.SET_SUBJECTS,
        }),
        searchLanguageButtonToggle() {
            this.searchInOtherLangVisible = !this.searchInOtherLangVisible;
            if (this.searchInOtherLangVisible)
                setTimeout(() => document.getElementById('d-German').focus(), 200);
        },
        displayLanguageButtonToggle() {
            this.displayInOtherLangVisible = !this.displayInOtherLangVisible;
            if (this.displayInOtherLangVisible)
                setTimeout(() => document.getElementById('ds-German').focus(), 200);
        },
        async checkCache() {
            if (this.getDatasources.length == 0) {
                let result = await TerminologyDataService.getDatasources()
                this.setDatasources(result)
            }

            if (this.getBodies.length == 0) {
                let result = await TerminologyDataService.getBodies()
                let translated = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                this.setBodies(translated)
            }

            if (this.getSubjects.length == 0) {
                let result = await TerminologyDataService.getSubjects()
                let translated = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                this.setSubjects(translated)
            }
        },
        onClearAllClick() {
            this.value.clearFilters(this.getSearchSettings)
            this.$emit('input', this.value)
            this.$emit('apply', this.value)
        },
        onSearchInClick() {
            this.value.searchLanguages = this.searchInlanguages.reduce((col, lang) => {
                if (lang.state) {
                    col.push(lang.code);
                }
                return col;
            }, [])
            this.$emit('input', this.value)
        },
        onDisplayInClick() {
            this.value.languagesDisplay = this.displayInLanguages.reduce((col, lang) => {
                if (lang.state) {
                    col.push(lang.code);
                }
                return col;
            }, [])
            this.$emit('input', this.value)
        },
        onApplyClick() {
            this.$emit('apply', this.value)
        },
        handleShown() {
            this.$emit("shown")
        },
        handleHidden() {
            this.$emit("hidden")
        }
    }
}
