//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { faCheck, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faCheck, faQuestion)

export default {
    name: "LanguageResult",
    inheritAttrs: false,
    data: () => ({
        specialLanguages: ["ru", "zh", "ar"]
    }),
    props: ["LanguageData", "LanguageCode", 'SearchTerm'],
    computed: {
        isSpecialLanguage() {
            return this.specialLanguages.includes(this.LanguageCode);
        }
    },
    methods: {
        highlightSearch(term) {
            if (term != null && this.SearchTerm) {
                let finalString = term;
                const searchWords = this.SearchTerm.split(' ');
                let highlightTemplate = `<em>[match]</em>`;
                searchWords.forEach(searchWord => {
                    searchWord = searchWord.replace(/[\(\[\][\^\]\]\*\[\)\]]/g, '');
                    if (searchWord != '') {
                        let searchWordPattern = this.isSpecialLanguage ? `${searchWord}` : `\\b${searchWord}\\b`
                        const matches = term.match(new RegExp(searchWordPattern, 'gi'));
                        if (matches != null) {
                            matches.forEach(match => {
                                let matchPattern = this.isSpecialLanguage ? `${match}` : `\\b${match}\\b`
                                let hgihlightTerm = highlightTemplate.replace('[match]', match)
                                finalString = finalString.replace(new RegExp(matchPattern, 'g'), hgihlightTerm)
                            })
                        }
                    }
                });
                return finalString;
            }
            return term;
        }
    }
}
