<template>
    <b-navbar id="brand-bar" role="navigation" toggleable="lg" type="dark" variant="primary" :class="currentRoute">
        <div class="bg-secondary" id="gray-band"></div>
        <b-col>
            <b-navbar-toggle role="menuitem" target="language-switcher">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </b-navbar-toggle>
        </b-col>
        <a class="logo navbar-btn pull-left flip" :href="`${getAppBasePath}/${getCurrentLanguage}`" title="United Nations">
            <img class="img-responsive" src="https://www.un.org/sites/un2.un.org/themes/bootstrap_un2/logo.png" alt="UN logo">
        </a>
        <a class="navbar-brand pull-left slogan-text flip" :href="`${getAppBasePath}/${getCurrentLanguage}`" v-html="$t('headerTitle2')" :title="$t('headerTitle2')">
        </a>

        <b-collapse class="navbar-collapse collapse" id="language-switcher">
            <div class="mr-auto"></div>
            <b-navbar-nav>
                <b-nav-item to="/" id="home-menu" :active="currentRoute==='Home'" class="home" v-to-lang>{{$t("home")}}</b-nav-item>
                <b-nav-item to="/about" id="about-menu" :active="currentRoute==='About'" class="about" v-to-lang>{{$t("about")}}</b-nav-item>
                <b-nav-item-dropdown id="help-menu" class="help" :aria-label="$t('press_space_bar')" menu-class="bg-primary">
                    <template v-slot:button-content v-to-lang>
                        {{$t('help')}}
                    </template>
                    <b-dropdown-item to="/user-guide" v-to-lang>{{$t("user_guide")}}</b-dropdown-item>
                    <b-dropdown-item to="/contact" :active="currentRoute==='Contact'" v-to-lang>{{$t("contact_us")}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown id="resource-menue" class="help" :aria-label="$t('press_space_bar')" menu-class="bg-primary">
                    <template v-slot:button-content v-to-lang>
                        {{$t('resources')}}
                    </template>
                    <b-dropdown-item to="/country" v-to-lang>{{$t("country_name")}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item to="/settings" id="settings-menu" :active="currentRoute==='Settings'" class="settings" v-to-lang>{{$t("settings")}}</b-nav-item>

                <b-nav-item v-on:click="login" id="login-menu" class="login" target="blank" v-to-lang>
                    {{$t("log_in")}}
                </b-nav-item>
                <b-nav-item-dropdown id="language-menu" :aria-label="$t('press_space_bar')" class="language" menu-class="bg-primary" :right="!isRTL">
                    <template v-slot:button-content>
                        {{getCurrentLanguageName}}
                    </template>
                    <b-dropdown-item :to="localizePath('ar')" lang="ar" xml:lang="ar">&#x627;&#x644;&#x639;&#x631;&#x628;&#x64A;&#x629;</b-dropdown-item>
                    <b-dropdown-item :to="localizePath('zh')" lang="zh" xml:lang="zh-hans">&#x4E2D;&#x6587;</b-dropdown-item>
                    <b-dropdown-item :to="localizePath('en')" lang="en" xml:lang="en">English</b-dropdown-item>
                    <b-dropdown-item :to="localizePath('fr')" lang="fr" xml:lang="fr">Fran&#xE7;ais</b-dropdown-item>
                    <b-dropdown-item :to="localizePath('ru')" lang="ru" xml:lang="ru">&#x420;&#x443;&#x441;&#x441;&#x43A;&#x438;&#x439;</b-dropdown-item>
                    <b-dropdown-item :to="localizePath('es')" lang="es" xml:lang="es">Espa&#xF1;ol</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
        <b-col></b-col>
    </b-navbar>
</template>
<script>
    import { mapGetters } from 'vuex'
    import { SupportedLanguages } from "../Lang/i18n"
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faHome, faInfoCircle, faQuestionCircle, faUser, faGlobe } from '@fortawesome/free-solid-svg-icons'
    library.add(faHome, faInfoCircle, faQuestionCircle, faUser, faGlobe)

    declare var UNTERM: any;

    export default {
        computed: {
            ...mapGetters(["getCurrentLanguage", "isRTL"]),
            getCurrentLanguageName() {
                const current = this.getCurrentLanguage
                return SupportedLanguages[current]
            },
            basePath() { return UNTERM.BasePath },
            currentRoute() {
                return this.$route.name
            },
            externalLoginUrl() {
                return UNTERM.ExternLogin;
            },
            getAppBasePath() {
                return UNTERM.AppBasePath;
            }
        },
        methods: {
            login() {
                if (window.location.pathname.includes("view")) {
                    const splitedURL = window.location.pathname.split('/');
                    if (splitedURL.length > 0) {
                        // for dev environment
                        if (UNTERM.ExternLogin.includes("http")) {
                            window.location.href = UNTERM.ExternLogin + '&redirectURL=' + UNTERM.UntermUrl+ '/display/record/na/na/' + splitedURL[splitedURL.length - 1];
                        } else {
                            window.location.href = window.location.origin + UNTERM.ExternLogin + '&redirectURL=' + UNTERM.UntermUrl + '/display/record/na/na/' + splitedURL[splitedURL.length - 1];
                        }
                    }
                } else {
                    if (UNTERM.ExternLogin.includes("http")) {
                        window.location.href = UNTERM.ExternLogin;
                    } else {
                        window.location.href = window.location.origin + UNTERM.ExternLogin;
                    }
                }
            },
            localizePath(langCode) {
                let path = this.$route.fullPath
                const current = this.getCurrentLanguage
                return path.replace(current, langCode)
            },
        }
    }
</script>
<style lang="scss">
    .login {
        white-space: nowrap;
    }

    @media (min-width: 1400px) {
        body.i18n-fr .slogan-text {
            font-size: 1.400612rem !important;
        }

        body.i18n-es .slogan-text {
            font-size: 1.400612rem !important;
        }

        body.i18n-en .slogan-text {
            font-size: 1.400612rem !important;
        }

        body.i18n-zh .slogan-text {
            font-size: 1.700612rem !important;
        }
    }

    @media (min-width: 1200px) {
        body.i18n-ru .slogan-text {
            font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 15px !important;
            color: #4d4d4d !important;
            line-height: 1.045;
        }
    }

    @media (max-width: 1400px) and (min-width: 600px) {
        body.i18n-es .slogan-text {
            font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px !important;
            color: #4d4d4d !important;
            line-height: 1.045;
        }
    }

    @media (max-width: 1400px) and (min-width: 600px) {
        body.i18n-fr .slogan-text {
            font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 16px !important;
            color: #4d4d4d !important;
            line-height: 1.045;
        }
    }



    body.i18n-ar .slogan-text {
        font-family: "Noto Kufi Arabic Regular", "Roboto", sans-serif;
        font-size: 15px !important;
        color: #4d4d4d !important;
    }

    body.i18n-zh .slogan-text {
        font-family: "Microsoft YaHei", "Roboto", sans-serif;
        font-size: 1.700612rem !important;
    }

    .slogan-text {
        font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px !important;
        color: #4d4d4d !important;
        line-height: 1.045;
    }

    #banner::after {
        content: '';
        display: block;
        margin-top: 0.3041284404rem;
        height: 5.150917rem;
        border-right: black solid 1px;
    }
</style>
