var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$route.name, attrs: { id: "app-container" } },
    [
      _c("app-nav-bar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "app" } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "footer mt-auto py-1 bg-help-white" }, [
        _c("div", { staticClass: "container mt-3" }, [
          _c(
            "p",
            { staticClass: "text-center" },
            [
              _c("i18n", {
                attrs: { path: "please_read", tag: "p" },
                scopedSlots: _vm._u([
                  {
                    key: "terms_of_use_link",
                    fn: function () {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                            attrs: { href: _vm.termsUrl },
                          },
                          [_vm._v(_vm._s(_vm.$t("terms_of_use")))]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "copyright_notice_link",
                    fn: function () {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                            attrs: { href: _vm.copyrightUrl },
                          },
                          [_vm._v(_vm._s(_vm.$t("copyright_notice")))]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }