<template>
    <b-row class="filter-preview" v-if="searchedTerm">
        <b-col cols="3" class="text-nowrap search-text my-auto" v-b-tooltip.hover :title="searchModel.searchTerm" v-to-lang>"{{searchModel.searchTerm | maxText}}"</b-col>
        <b-col cols="6" class="my-auto">
            <div class="d-inline-block" style="font-size: 1.5rem;" v-if="searchModel.datasets.length">
                <search-tags :dataList="searchModel.datasets" @remove="removeDataset" :limit="3" :title="$t('datasets')"></search-tags>
            </div>
            <div class="d-inline-block" style="font-size: 1.5rem;" v-if="searchModel.subjects.length">
                <search-tags :dataList="searchModel.subjects" @remove="removeSubject" :limit="3" :title="$t('subjects')"></search-tags>                
            </div>
            <div class="d-inline-block" style="font-size: 1.5rem;" v-if="searchModel.bodies.length">                
                <search-tags :dataList="searchModel.bodies" @remove="removeBody" :limit="3" :title="$t('bodies')"></search-tags>                
            </div>
        </b-col>
        <b-col v-if="!loading" role="alert" class="text-right my-auto text-nowrap" v-to-lang>
            {{$t('results', [firstResult + '-' + lastResult, totalResults])}}
        </b-col>
    </b-row>
</template>

<script>
    import { SearchModel } from "../../Model/SearchModel";
    import SearchTags from "./SearchTags.vue";

    export default {
        name: "SearchFiltersPreview",
        data: function () {
            return {}
        },
        components: {
            SearchTags
        },
        props: {
            searchedTerm: {
                default: ''
            },
            value: {
                required: true
            },
            page: {
                required: true,
                type: Number
            },
            totalResults: {
                required: true,
                type: Number
            },
            pageSize: {
                required: true,
                type: Number
            },
            loading: {
                default: false,
            }
        },
        computed: {
            searchModel: {
                get(): SearchModel {
                    return this.value
                },
                set(newVal: SearchModel) {
                    this.$emit('input', newVal)
                }
            },
            firstResult: {
                get() {
                    return ((this.page - 1) * this.pageSize) + (this.totalResults > 0 ? 1 : 0)
                }
            },
            lastResult: {
                get() {
                    let last = (this.page * this.pageSize)
                    return (last > this.totalResults) ? this.totalResults : last

                }
            }
        },
        methods: {
            onSearchInClick() {
                this.$emit('input', this.value)
            },
            removeDataset(option) {
                const idx = this.searchModel.datasets.indexOf(option)
                if (idx >= 0) {
                    this.searchModel.datasets.splice(idx, 1)
                }
            },
            removeSubject(option) {
                const idx = this.searchModel.subjects.indexOf(option)
                if (idx >= 0) {
                    this.searchModel.subjects.splice(idx, 1)
                }
            },
            removeBody(option) {
                const idx = this.searchModel.bodies.indexOf(option)
                if (idx >= 0) {
                    this.searchModel.bodies.splice(idx, 1)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    div.filter-preview {
        background: #F9FAFC 0% 0% no-repeat padding-box;
        border: 1px solid #EAEAEA;
        min-height: 54px;
        margin: 20px 0;
    }

    .search-text {
        font: bold 14px/30px Roboto;
        letter-spacing: 0px;
        color: #DB2828;
        word-wrap: break-word !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
