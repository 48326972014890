export default {
    ru: {
        'what_is_unterm_title': 'Портал <span class="text-secondary">ЮНТЕРМ</span>',
         'what_is_unterm': '<b>ЮНТЕРМ</b> представляет собой многоязычную базу данных, которая содержит официальную терминологию, имеющую отношение к работе Организации Объединенных Наций. Это общедоступный веб-сайт, которым пользуются делегаты и сотрудники Организации Объединенных Наций, а также другие пользователи, интересующиеся работой и деятельностью Организации Объединенных Наций во всем мире.',
        'multilingual_content_title': 'Многоязычное содержание',
        'multilingual_content': 'Официальная терминология приводится на шести официальных языках Организации Объединенных Наций — английском, арабском, испанском, китайском, русском и французском — в соответствии с мандатом Генеральной Ассамблеи в области многоязычия.',
        'global_management_title': 'Глобальное управление',
        'global_management': '<b>ЮНТЕРМ</b> содержит терминологические записи различных структур системы Организации Объединенных Наций. В целях обеспечения единообразия терминологии во всей системе Организации Объединенных Наций наполнение базы данных находится в ведении лингвистических сотрудников Организации Объединенных Наций, которые специализируются по соответствующим тематикам.',
    }
}

