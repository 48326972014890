class SearchHistory {
    private _maxResults :number= 5;
    private _storedItems: string[]
    private _storageKey :string= "search-history"

    constructor() {
        this.restoreItems()
    }

    private restoreItems():void {
        let items = localStorage.getItem(this._storageKey)
        if (items) {
            this._storedItems = JSON.parse(items)
        } else {
            this._storedItems = []
        }
    }

    private storeItems():void {
        localStorage.setItem(this._storageKey, JSON.stringify(this._storedItems))
    }
    
    addTerm(text: string): void {
        if (!text || text.trim().length === 0) return;

        let existing = this._storedItems.findIndex(((value, index, obj) => {
            return value.toLowerCase() === text.toLowerCase()
        }) as any)

        if (existing === -1) {
            this._storedItems.push(text.toLowerCase())
            this.storeItems()
        }
    }

    getMatchingTerms(text: string): string[] {
        let filtered = this._storedItems.filter(x => x.toLowerCase().startsWith(text.toLowerCase()))
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        return filtered.filter(onlyUnique).sort((a, b) =>  a.localeCompare(b)).slice(0, this._maxResults) //sort and take first 5 results
    }

    clear(): void {
        localStorage.removeItem(this._storageKey)
        this._storedItems=[]
    }
}

let defaultInstance = new SearchHistory()

export default defaultInstance
