//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './en'
import messages_ar from './ar'
import messages_es from './es'
import messages_fr from './fr'
import messages_ru from './ru'
import messages_zh from './zh'

Vue.use(VueI18n)

export const SupportedLanguages = {
    en: "English",
    es: "Español",
    fr: "Français",
    ru: "Русский",
    zh: "中文",
    ar: "العربية"
}

export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    messages: {
        ...messages,
        ...messages_ar,
        ...messages_es,
        ...messages_fr,
        ...messages_ru,
        ...messages_zh
    }
})
