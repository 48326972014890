<template>
    <div id="app-container" :class="$route.name">
        <app-nav-bar />
        <div id="app" class="wrapper">
            <router-view />
        </div>
        <footer class="footer mt-auto py-1 bg-help-white">
            <div class="container mt-3">
                <p class="text-center">
                    <i18n path="please_read" tag="p">
                        <template v-slot:terms_of_use_link>
                            <a  v-to-lang :href="termsUrl">{{ $t('terms_of_use') }}</a>
                        </template>
                        <template v-slot:copyright_notice_link>
                            <a v-to-lang :href="copyrightUrl">{{ $t('copyright_notice') }}</a>
                        </template>
                    </i18n>
                </p>
            </div>
        </footer>
    </div>
</template>
<script>
    import AppNavBar from "./AppNavBar.vue"
    import { mapGetters, mapActions } from 'vuex'
    import * as TerminologyDataService from "../DataServices/terminologyDataService"
    import * as Actions from '../Store/Actions'
    export default {
        components: { AppNavBar },
        watch: {
            '$route'(to, from) {
                let title = 'UNTERM'
                if (to.meta.title) {
                    title += ` - ${to.meta.title}`
                }
                if (to.meta.accessabilityFocusElementId) {
                    setTimeout(() => {
                        document.getElementById(to.meta.accessabilityFocusElementId).focus();
                    }, 300)
                }
                document.title = title
            }
        },
        methods: {
            ...mapActions([Actions.SET_SEARCH_SETTINGS]),
        },
        async mounted() {
            const settings = await TerminologyDataService.getClientSettings()
            if (settings.searchSettings) {
                this.setSearchSettings(settings.searchSettings)                
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentLanguage'
            ]),
            termsUrl() {
                return "https://www.un.org/" + this.getCurrentLanguage + "/about-us/terms-of-use"
            },
            copyrightUrl() {
                return "https://www.un.org/" + this.getCurrentLanguage + "/about-us/copyright"
            }
        }
    }
</script>
<style>
    .grecaptcha-badge {
        display: none;
    }
</style>
