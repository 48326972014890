//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { SupportedLanguages } from "../Lang/i18n"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faInfoCircle, faQuestionCircle, faUser, faGlobe } from '@fortawesome/free-solid-svg-icons'
library.add(faHome, faInfoCircle, faQuestionCircle, faUser, faGlobe)

declare var UNTERM: any;

export default {
    computed: {
        ...mapGetters(["getCurrentLanguage", "isRTL"]),
        getCurrentLanguageName() {
            const current = this.getCurrentLanguage
            return SupportedLanguages[current]
        },
        basePath() { return UNTERM.BasePath },
        currentRoute() {
            return this.$route.name
        },
        externalLoginUrl() {
            return UNTERM.ExternLogin;
        },
        getAppBasePath() {
            return UNTERM.AppBasePath;
        }
    },
    methods: {
        login() {
            if (window.location.pathname.includes("view")) {
                const splitedURL = window.location.pathname.split('/');
                if (splitedURL.length > 0) {
                    // for dev environment
                    if (UNTERM.ExternLogin.includes("http")) {
                        window.location.href = UNTERM.ExternLogin + '&redirectURL=' + UNTERM.UntermUrl+ '/display/record/na/na/' + splitedURL[splitedURL.length - 1];
                    } else {
                        window.location.href = window.location.origin + UNTERM.ExternLogin + '&redirectURL=' + UNTERM.UntermUrl + '/display/record/na/na/' + splitedURL[splitedURL.length - 1];
                    }
                }
            } else {
                if (UNTERM.ExternLogin.includes("http")) {
                    window.location.href = UNTERM.ExternLogin;
                } else {
                    window.location.href = window.location.origin + UNTERM.ExternLogin;
                }
            }
        },
        localizePath(langCode) {
            let path = this.$route.fullPath
            const current = this.getCurrentLanguage
            return path.replace(current, langCode)
        },
    }
}
