var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "sidebar-backdrop",
            "no-close-on-route-change": true,
            shadow: "",
          },
        },
        [
          _c(
            "b-col",
            { staticClass: "sidebar" },
            [
              _c("side-panel", {
                attrs: { facets: _vm.getSearchFacets },
                on: { search: _vm.doSearch, highlight: _vm.highlightRow },
                model: {
                  value: _vm.searchModel,
                  callback: function ($$v) {
                    _vm.searchModel = $$v
                  },
                  expression: "searchModel",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.sidebar-backdrop",
                      modifiers: { "sidebar-backdrop": true },
                    },
                  ],
                },
                [
                  _c(
                    "b-button",
                    { staticClass: "toggle-button" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { color: "white", icon: ["fa", "angle-up"] },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("filter")) +
                          "\n                    "
                      ),
                      _c(
                        "b-badge",
                        {
                          staticClass: "filter-badge",
                          attrs: { pill: "", variant: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.availableFacets))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-container",
                [
                  _c("h2", { staticClass: "mt-5 mb-3 sr-only" }, [
                    _vm._v("Search terms"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "row", attrs: { id: "search-box-row" } },
                      [
                        _c("search-input", {
                          attrs: { disabled: _vm.loading },
                          on: { search: _vm.doSearch },
                          model: {
                            value: _vm.searchModel,
                            callback: function ($$v) {
                              _vm.searchModel = $$v
                            },
                            expression: "searchModel",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("search-filters", {
                    on: {
                      apply: _vm.doSearch,
                      shown: function ($event) {
                        return _vm.setAdvancedSearchVisible(true)
                      },
                      hidden: function ($event) {
                        return _vm.setAdvancedSearchVisible(false)
                      },
                    },
                    model: {
                      value: _vm.searchModel,
                      callback: function ($$v) {
                        _vm.searchModel = $$v
                      },
                      expression: "searchModel",
                    },
                  }),
                  _vm._v(" "),
                  _c("search-filters-preview", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.advancedFiltersVisible,
                        expression: "!advancedFiltersVisible",
                      },
                    ],
                    attrs: {
                      loading: _vm.loading,
                      searchedTerm: _vm.searchTerm,
                      page: _vm.CurrentPage,
                      "page-size": _vm.getPageSize,
                      "total-results": _vm.getTotalHits,
                    },
                    on: { change: _vm.doSearch },
                    model: {
                      value: _vm.searchModel,
                      callback: function ($$v) {
                        _vm.searchModel = $$v
                      },
                      expression: "searchModel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-container", { staticClass: "search-pane__container" }, [
                !_vm.searchTerm
                  ? _c("h1", { attrs: { role: "alert" } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("enter_search_query")) +
                          "\n                "
                      ),
                    ])
                  : _vm.getTotalHits <= 0 && !_vm.loading
                  ? _c(
                      "div",
                      {
                        staticClass: "no-data-message",
                        attrs: { role: "alert" },
                      },
                      [
                        _c(
                          "h1",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("no_result_found")) + " "),
                            _c("em", { attrs: { title: _vm.searchTerm } }, [
                              _vm._v(_vm._s(_vm.searchTerm)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.$t("lanugage_inquiry_message")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.$t("search_in_language_message")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.$t("currently_searching_in")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticStyle: { "list-style-position": "inside" } },
                          [
                            _vm._l(_vm.selectedLangs, function (item) {
                              return [
                                _c("li", { key: item.name }, [
                                  _vm._v(
                                    _vm._s(_vm.$t(item.name.toLowerCase()))
                                  ),
                                ]),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            directives: [
                              { name: "to-lang", rawName: "v-to-lang" },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("are_important_entries_missing"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "suggestion-area",
                            on: { click: _vm.goToSuggestions },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "icon" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: {
                                    color: "#009EDB",
                                    icon: ["fa", "envelope"],
                                    size: "3x",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "to-lang", rawName: "v-to-lang" },
                                ],
                                staticClass: "message-text",
                              },
                              [_vm._v(_vm._s(_vm.$t("send_your_suggestions")))]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      [
                        _c("b-table", {
                          directives: [
                            {
                              name: "shortkey",
                              rawName: "v-shortkey.focus",
                              value: ["alt", "r"],
                              expression: "['alt', 'r']",
                              modifiers: { focus: true },
                            },
                          ],
                          attrs: {
                            "sticky-header": "",
                            hover: "",
                            "show-empty": "",
                            items: _vm.getSearchResults.results,
                            busy: _vm.loading,
                            "thead-tr-class": "language-name-title",
                            fields: _vm.tableFields,
                            id: "results-table",
                            "tbody-tr-class": "rowClass",
                            "no-local-sorting": true,
                            "empty-text": _vm.noResultsText,
                            responsive: "",
                          },
                          on: { "sort-changed": _vm.handleSorting },
                          scopedSlots: _vm._u([
                            {
                              key: "row-details",
                              fn: function (row) {
                                return [
                                  _c(
                                    "b-card",
                                    { staticClass: "record-preview" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "float-right close-icon",
                                          attrs: { "data-effect": "fadeOut" },
                                          on: { click: row.toggleDetails },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times fa-2x",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("record-preview", {
                                        attrs: { Record: row.item },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(actions)",
                              fn: function (row) {
                                return [
                                  _c("div", { staticClass: "status-icons" }, [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "to-lang",
                                          rawName: "v-to-lang",
                                        },
                                      ],
                                      class: [
                                        "info",
                                        "distribution-" + row.item.distribution,
                                      ],
                                      attrs: {
                                        title: _vm.$t(
                                          row.item.distribution.toLowerCase() +
                                            "_record"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "to-lang",
                                          rawName: "v-to-lang",
                                        },
                                      ],
                                      class: [
                                        "info",
                                        "record status-" + row.item.status,
                                      ],
                                      attrs: {
                                        title: _vm.$t(
                                          "record_" + row.item.status
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "to-lang",
                                          rawName: "v-to-lang",
                                        },
                                      ],
                                      staticClass: "mr-1 px-2 py-0",
                                      attrs: { size: "sm", variant: "grid" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewTerm(row.item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$t("open")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "to-lang",
                                          rawName: "v-to-lang",
                                        },
                                      ],
                                      staticClass: "px-2 py-0",
                                      attrs: { size: "sm", variant: "grid" },
                                      on: { click: row.toggleDetails },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$t("details")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell()",
                              fn: function (data) {
                                return [
                                  data.field.isLang
                                    ? _c("language-result", {
                                        attrs: {
                                          "search-term": _vm.searchTerm,
                                          "language-data": data.value,
                                          "language-code": data.field.langCode,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !data.field.isLang
                                    ? _c("record-info", {
                                        attrs: { Record: data.item },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "head()",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t(data.label.toLowerCase())) +
                                      "\n                        "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "table-busy",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center text-primary my-5",
                                    },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "strong",
                                        {
                                          directives: [
                                            {
                                              name: "to-lang",
                                              rawName: "v-to-lang",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("loading")) +
                                              "..."
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("b-pagination", {
                          directives: [
                            {
                              name: "set-pagination",
                              rawName: "v-set-pagination",
                            },
                          ],
                          attrs: {
                            limit: "15",
                            "total-rows": _vm.getTotalHits,
                            "per-page": _vm.getPageSize,
                            "aria-controls": "results-table",
                            align: "center",
                          },
                          on: { change: _vm.handlePageChange },
                          model: {
                            value: _vm.CurrentPage,
                            callback: function ($$v) {
                              _vm.CurrentPage = $$v
                            },
                            expression: "CurrentPage",
                          },
                        }),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }