//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as NProgress from "nprogress"
import * as TerminologyDataService from "../../DataServices/terminologyDataService"
import LanguageResult from "./LanguageResult.vue"
import RecordPreview from "./RecordPreview.vue"
import RecordInfo from "./RecordInfo.vue"
import { mapActions, mapGetters } from 'vuex'
import * as Actions from '../../Store/Actions'
import SearchInput from "./SearchInput.vue"
import { languages } from "../../Model/Constants"
import { SearchModel } from "../../Model/SearchModel"
import Multiselect from 'vue-multiselect'
import * as urlHelper from "../../urlHelper";


export default {
    name: "SearchPane",
    data: function () {
        return {
            searchModel: new SearchModel(),
            loading: false,
            noResultsText: 'No results yet',
            languageOptions: [...languages],
            advancedFiltersVisible: false,
            searchTerm: null,
            highlightData: null,
            displayInOtherLangVisible: false,
            currentPage: 1
        }
    },
    components: {
        LanguageResult,
        RecordInfo,
        RecordPreview,
        SearchInput,
        Multiselect
    },
    mounted() {
        let prevSearch = this.getSearchModel
        if (prevSearch != null && prevSearch.hasSearchTerm()) {
            this.searchModel = prevSearch
        }
        this.setDefaultLanguage(this.searchModel);
        this.searchModel.searchTerm = this.queryTerm
        //this.defaultColumns()
        this.doSearch()
    },
    computed: {
        ...mapGetters([
            'getSearchResults',
            'getSearchModel',
            'getTotalHits',
            'getPageSize',
            'getSearchFacets',
            'getCurrentLanguage'
        ]),            
        excelExportUrl() {
            return urlHelper.apicall(`term/downloadCountries`);
        },
        displayInLanguages: {
            get() {
                return this.sortHelper(languages.filter(l => !['de', 'pt', 'ed'].includes(l.code)).map(lang => {
                    return {
                        state: this.searchModel.languagesDisplay.includes(lang.code),
                        ...lang
                    }
                }))
            },
            set(newVal) {
                this.searchModel.languagesDisplay = newVal.map(x => x.code)
            }
        },            
        queryTerm() {
            return this.$route.query.searchTerm;
        },
        selectedLangs() {
            return languages.filter(lang => { return this.searchModel.searchLanguages.find(x => x == lang.code) })
        },
        tableFields: {
            get() {
                const selectedLangs = languages.filter(lang => { return this.searchModel.languagesDisplay.find(x => x == lang.code) })
                return [
                    { key: 'actions', label: '', sortable: false },
                    ...selectedLangs.map(l => { return { key: l.name.toLowerCase(), langCode: l.code, sortable: false, isLang: true, headerTitle: this.$t(l.name.toLowerCase()) } }),
                    { key: 'recordinfo', label: '', sortable: false, langCode: '', isLang: false }
                ]
            }
        },            
    },
    watch: {
        getSearchSettings() {
            this.setDefaultLanguage(this.searchModel);
        },
        loading(val: boolean) {
            if (val) {
                NProgress.start();
            } else {
                NProgress.done();
            }
        }
    },
    methods: {
        ...mapActions({
            setSearchResults: Actions.SET_SEARCH_RESULTS,
            changePage: Actions.SEARCH_CHANGE_PAGE,
            setSearchModel: Actions.SET_SEARCH_MODEL,
        }),
        downloadList() {
            let element = document.createElement('a');
            element.href = this.excelExportUrl;
            element.target = '_blank';
            element.click();
        },
        defaultColumns() {
            this.searchModel.languagesDisplay = languages
                .filter(lang => ['en', 'fr'].includes(lang.code))
                .map(lang => lang.code);
        },
        onDisplayInClick() {
            this.searchModel.languagesDisplay = this.displayInLanguages.reduce((col, lang) => {
                if (lang.state || lang.code == 'en') {
                    col.push(lang.code);
                }
                return col;
            }, [])
        },            
        doSearch() {
            document.querySelector('th')?.click()
            this.searchTerm = this.searchModel?.searchTerm?.trim()
            this.setSearchModel(this.searchModel)
            this.fetchData(1)
        },
        async fetchData(page) {
            this.$data.loading = true;
            try {
                let results = await TerminologyDataService.searchCountry(this.getSearchModel, page);
                this.setSearchResults(results)
                this.$data.loading = false
                if (this.getSearchResults.results.length == 0) {
                    this.$data.noResultsText = this.$t('country_not_found_message');
                }
            } catch (e) {
                this.setSearchResults(null)
                this.$data.noResultsText = this.$t('error_executing_search')
                this.$bvToast.toast(this.$t('search_failed'), {
                    title: 'Error!',
                    variant: "danger",
                    autoHideDelay: 3000,
                    appendToast: false
                })
                this.$data.loading = false
            }
        },
        handlePageChange(pageNumber) {
            this.fetchData(pageNumber);
        },
        viewTerm(item) {
            const resolved = this.$router.resolve({ name: 'viewTerm', params: { recordId: item.recordID, langCode: this.getCurrentLanguage } })
            window.open(resolved.href, "record-" + item.recordID)
        },
        handleSorting(ctx) {
            if (ctx.sortBy) {
                this.searchModel.sortBy = this.languageOptions.find(x => x.name.toLowerCase() == ctx.sortBy.toLowerCase()).code;
                this.searchModel.sortDirection = ctx.sortDesc ? "desc" : "asc"
                this.handlePageChange(1)
            } else {
                this.searchModel.sortBy = '';
                this.searchModel.sortDirection = ''
            }
        },
        setAdvancedSearchVisible(visible: boolean) {
            this.advancedFiltersVisible = visible
        },
    }
}
