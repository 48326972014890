declare let UNTERM: any;

let baseUrl = UNTERM.BasePath;
if (!baseUrl.endsWith("/")) {
    baseUrl += "/"
}

let baseApiUrl = UNTERM.APIUrl;
if (!baseApiUrl.endsWith("/")) {
    baseApiUrl += "/"
}

export function content(relativeUrl: string) {
    return baseUrl === "/" ? relativeUrl : `${baseUrl}${relativeUrl}`;
}

export function action(controller: string, action: string) {
    let url = `${baseUrl}${controller}`
    if (action != null && action.length > 0) {
        url += `/${action}`
    }
    return url;
}

export function apicall(endpoint:string) {
    return `${baseApiUrl}api/${endpoint}`;
}
