<template>
    <b-input-group v-to-lang role="search" aria-label="UN Terminology Database" class="flex-nowrap shadow rounded-lg bg-white">
        <template v-slot:prepend>
            <u-image src="theme/search.svg" class="magnifier bv-d-sm-down-none" />
        </template>
        <template v-slot:append>
            <b-button v-to-lang variant="primary" v-on:click="handleClick" :disabled="disabled" class="search-button" size="lg">
                {{$t('search').toUpperCase()}}
            </b-button>
        </template>

        <input type="text" class="form-control form-control-lg my-2"
               :placeholder="$t('search_database')+'...'"
               :maxlength="244"
               v-model="userQuery"
               ref="searchInput"
               id="searchInput"
               :aria-label="$t('enter_text_search')"
               aria-describedby="basic-addon2"
               :disabled="disabled"
               v-shortkey.focus="['alt', 's']"
               @keyup.enter.prevent="handleEnterKey" />
        <svg v-if="hasInput"
             @click="clearInput"
             aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
             class="search-input-clear pointer hover-red5 svg-inline--fa fa-times-circle fa-w-16 fa-fw fa-lg">
            <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path>
        </svg>
        <template slot="misc-item-above" slot-scope="{ suggestions, query }">
            <div class="misc-item">
                <span v-to-lang>{{$t('searching_for')}} {{ query }}.</span>
            </div>
        </template>

        <template slot="suggestion-item" slot-scope="{ suggestion, query }">
            <span v-to-lang :class="[suggestion.type]">{{ suggestion.title }}</span>
        </template>
    </b-input-group>
</template>

<script lang="ts">
    import Vue from "vue"
    import VueSimpleSuggest from 'vue-simple-suggest/lib'
    import * as TerminologyDataService from "../../DataServices/terminologyDataService";
    import UImage from '../UImage.vue'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "SearchInput",
        data: function () {
            return {
                suggestionsShowing: false,
                enterKeyPressed: false
            }
        },
        components: {
            VueSimpleSuggest,
            UImage,
            Multiselect
        },
        props: {
            value: {
                required: true
            },
            'disabled': {
                type: Boolean,
                default: false
            },
        },
        computed: {
            hasInput() {
                return this.userQuery
            },
            userQuery: {
                get() {
                    return this.value.searchTerm
                },
                set(newVal) {
                    this.value.searchTerm = newVal
                    this.$emit('input', this.value)
                    if (this.enterKeyPressed) this.raiseOnSearch()
                }
            },
        },
        methods: {                        
            handleEnterKey() {
                //if enter was pressed without using the suggestions trigger search from input content
                this.raiseOnSearch()
            },
            handleClick() {
                this.raiseOnSearch()
            },
            raiseOnSearch() {
                this.$emit('search', this.value)
            },
            clearInput() {
                this.userQuery = ""
                this.raiseOnSearch()
            }
        }
    }
</script>

<style scoped>
    .search-input-clear {
        position: absolute;
        right: 12.5rem;
        top: 1.5rem;
        color: #adb5bd;
        cursor: pointer;
    }

    .search-input-clear:focus, .search-input-clear:hover {
        color: #009EDB;
        opacity: .8;
    }

    .search-button {
        border-left: none;
    }

    ul.suggestions {
        top: 100% !important;
    }

    img.magnifier {
        margin-left: 30px;
        width: 30px;
    }

</style>
