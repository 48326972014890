//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required, requiredIf, email, minLength } from 'vuelidate/lib/validators'
import { languages, Term } from "../../Model/Constants"
import { sendSuggestion, getBodies, getSubjects } from '../../DataServices/terminologyDataService';
import Multiselect from 'vue-multiselect'
import * as Actions from '../../Store/Actions'
import { mapActions, mapGetters } from 'vuex'
import UImage from '../UImage.vue'

import messagesAr from "../../Lang/Home/Home-ar"
import messagesEn from "../../Lang/Home/Home-en"
import messagesFr from "../../Lang/Home/Home-fr"
import messagesEs from "../../Lang/Home/Home-es"
import messagesRu from "../../Lang/Home/Home-ru"
import messagesZh from "../../Lang/Home/Home-zh"

Vue.use(Vuelidate)

export default {
    data: () => ({
        loading: false,
        form: {
            language: 'English',
            suggestion: '',
            source: '',
            description: '',
            context: '',
            targetLanguage: 'English',
            targetTitle: '',
            targetSource: '',
            targetContex: '',
            subject: '',
            body: '',
            comment: '',
            name: '',
            email: '',
        },
        show: true,
        submitted: false,
        failedToSubmit: '',
    }),
    validations: {
        form: {
            suggestion: {
                required,
                minLength: minLength(2)
            },
            source: {
                required,
                minLength: minLength(2)
            },
            email: {
                email,
                required
            },
            description: {
                minLength: minLength(2)
            },
            context: {
                minLength: minLength(2)
            },
            targetTitle: {
                minLength: minLength(2)
            },
            targetSource: {
                minLength: minLength(2)
            },
            targetContext: {
                minLength: minLength(2)
            },
            name: {
                minLength: minLength(2)
            },
        }
    },
    computed: {
        ...mapGetters([
            'getSearchSettings',
            'getDatasources',
            'getBodies',
            'getSubjects',
        ]),
        bodies() {
            let bodies = this.sortHelper(this.getBodies);
            return bodies.map(v => ({ text: this.$t(v.name), value: v.id }));
        },
        subjects() {
            let subjects = this.sortHelper( this.getSubjects);
            return subjects.map(v => ({ text: this.$t(v.name), value: v.id }));
        },
        languages() {
            return this.sortHelper(languages.reduce((prev, current) => {
                if (current.isOfficial) {
                    prev.push({ text: this.$t(current.name.toLowerCase()), value: current.name })
                }
                return prev
            }, []))
        },
    },
    components: { Multiselect, UImage },
    methods: {
        ...mapActions({
            setDatasources: Actions.SET_DATASOURCES,
            setBodies: Actions.SET_BODIES,
            setSubjects: Actions.SET_SUBJECTS,
        }),
        async recaptcha() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('feedback')
            return token;
        },
        valid() {
            this.$v.$touch()
            return !this.$v.$error
        },
        async onSubmit() {

            if (this.valid()) {
                this.loading = true;
                const token = await this.recaptcha();

                try {
                    let model = {
                        ...this.form,
                        captchaToken: token,
                        byPassToken: this.$refs.captcha.value
                    }
                    await sendSuggestion(model);
                    this.loading = false;
                    this.clearFormData()
                    this.$bvToast.toast(this.$t('feedback_success'), {
                        title: this.$t('success'),
                        variant: "success",
                        autoHideDelay: 5000,
                        appendToast: false
                    })
                } catch (e) {
                    this.loading = false;
                    this.$bvToast.toast(this.$t('feedback_error'), {
                        title: this.$t('error'),
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: false
                    })
                }
            }
            return { submitted: false };
        },
        clearFormData() {
            this.form.language = 'English'
            this.form.suggestion = ''
            this.form.source = ''
            this.form.description = ''
            this.form.context = ''
            this.form.targetLanguage = 'English'
            this.form.targetTitle = ''
            this.form.targetSource = ''
            this.form.targetContex = ''
            this.form.subject = ''
            this.form.body = ''
            this.form.comment = ''
            this.form.name = ''
            this.form.email = ''
            this.$v.$reset()
        },
        onReset(evt) {
            evt.preventDefault()
            this.clearFormData();
            this.$refs.recaptcha.reset()
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
        getValidationState(validator) {
            if (!validator) {
                console.log('no validator found')
                return;
            }
            if (!validator.$dirty)
                return null
            else
                return !validator.$error
        },
        removeField(field) {
            const index = this.form.fields.indexOf(field)
            if (index >= 0) {
                this.form.fields.splice(index, 1)
            }
        },
        async checkCache() {
            if (this.getBodies.length == 0) {
                let result = await getBodies()
                let bodies = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                this.setBodies(bodies)
            }

            if (this.getSubjects.length == 0) {
                let result = await getSubjects()
                let subjects = result.map(s => { return { code: s.name, name: s.name, id: s.id } })
                this.setSubjects(subjects)
            }
        },
    },
    i18n: {
        messages: {
            ...messagesAr,
            ...messagesEn,
            ...messagesFr,
            ...messagesEs,
            ...messagesRu,
            ...messagesZh,
        }
    },
    async mounted() {
        this.form.suggestion = this.$route.query.q;
        await this.checkCache();
    }
}
