var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contentsOnly" },
    [
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "11" } },
            [
              _vm.data != null
                ? [
                    _vm._l(_vm.terms, function (term) {
                      return _c(
                        "ul",
                        { attrs: { dir: _vm.data.isRTL ? "rtl" : "ltr" } },
                        [
                          term.termStatus != "open"
                            ? _c(
                                "li",
                                [
                                  _c("term", {
                                    attrs: {
                                      LanguageCode: _vm.LanguageCode,
                                      data: term,
                                      record: _vm.record,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.data.definition
                      ? [
                          _c(
                            "h4",
                            {
                              directives: [
                                { name: "to-lang", rawName: "v-to-lang" },
                              ],
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("definition")))]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "to-lang",
                                rawName: "v-to-lang.children",
                                value: _vm.LanguageCode,
                                expression: "LanguageCode",
                                modifiers: { children: true },
                              },
                            ],
                            attrs: { dir: _vm.data.isRTL ? "rtl" : "ltr" },
                            domProps: {
                              innerHTML: _vm._s(_vm.data.definition),
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.data == null
                ? [_vm._v("\n                 \n            ")]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "to-lang",
                  rawName: "v-to-lang.children",
                  modifiers: { children: true },
                },
              ],
              staticClass: "status-icons",
            },
            [
              _vm.isValidated
                ? _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      class: ["info", "languagestatus-validated"],
                      attrs: { title: _vm.$t("language_validated") },
                    },
                    [
                      _c("span", {
                        attrs: { "aria-label": _vm.$t("language_validated") },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showObsolete
                ? _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      class: ["info", "recordstatus-obsolete"],
                      attrs: { title: _vm.$t("record_obsolete") },
                    },
                    [
                      _c("span", {
                        attrs: { "aria-label": _vm.$t("record_obsolete") },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }