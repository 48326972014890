import Vue from "vue";
import VueRouter from "vue-router"
import SearchPane from "./Components/Search/SearchPane.vue";
import CountryList from "./Components/Country/CountryList.vue";
import Home from "./Components/Home/Home.vue";
import FeedbackForm from "./Components/Suggestion/Suggestion.vue";
const Settings = () => import("./Components/Settings/Settings.vue");
const UserGuide = () => import("./Components/UserGuide.vue");
const Contact = () => import(/* webpackChunkName: "contact-page" */'./Components/Contact.vue')
const About = () => import(/* webpackChunkName: "about-page" */'./Components/About.vue')
const Help = () => import(/* webpackChunkName: "help-page" */'./Components/Help.vue')
const Record = () => import(/* webpackChunkName: "record-page" */'./Components/Record/Record.vue')
const PageNotFound = () => import(/* webpackChunkName: "page404" */'./Components/page404.vue')
import store from "./Store/index"
import * as Actions from './Store/Actions'

declare let UNTERM: any

Vue.use(VueRouter)

const routes = [
    {
        name: 'Search',
        path: '/:langCode?/search',
        component: SearchPane,
        meta: {
            title: "Home",            
        }
    },
    {
        name: 'country',
        path: '/:langCode?/country',
        component: CountryList,
        meta: {
            title: "Home",
            accessabilityFocusElementId: 'download-country-button'
        }
    },
    {
        name: 'settings',
        path: '/:langCode?/Settings',
        component: Settings,
        meta: {
            title: "Settings",
            accessabilityFocusElementId: 'settings-heading'
        }
    },
    {
        name: 'suggestion',
        path: '/:langCode?/suggestion',
        component: FeedbackForm,
        meta: {
            title: "FeedBack Suggestion",
            accessabilityFocusElementId: 'suggestion-page-heading'
        }
    },
    {
        name: 'About',
        path: '/:langCode?/about',
        component: About,
        meta: {
            title: "About",
            accessabilityFocusElementId: 'about-para-1'
        }
    },
    {
        name: 'Help',
        path: '/:langCode?/help',
        component: Help
    },
    {
        name: 'Contact',
        path: '/:langCode?/contact',
        component: Contact,
        meta: {
            title: "Contact Form",
            accessabilityFocusElementId: 'contactus-page-heading'
        }
    },
    {
        name: 'UserGuide',
        path: '/:langCode?/user-guide',
        component: UserGuide,
        meta: {
            title: "User Guide",
            accessabilityFocusElementId: 'user-guide-heading'
        }
    },
    {
        name: 'viewTerm-db',
        path: '/:langCode?/view/:dbname/:recordId',
        component: Record,
        meta: {
            title: "DbRecord"
        }
    },
    {
        name: 'viewTerm',
        path: '/:langCode?/view/:recordId',
        component: Record,
        meta: {
            title: "Record"
        }
    },
    {
        name: 'Home',
        path: '/:langCode?',
        component: Home,
        meta: {
            title: "Home"
        }
    },
    { path: '*', component: PageNotFound }
]

const router = new VueRouter({
    mode: 'history',
    base: UNTERM.BasePath,
    routes,
    linkActiveClass: "active"
})

router.beforeEach((to, from, next) => {
    //router base is case-sensitive, remove /unterm2 if detected
    //the double comparison is to detect mixed case versions like UnTerm2
    //(anything matching unterm2 with i switch but not starting with lowercase / unterm)    
    if (to.fullPath.match(/^\/unterm2.*/gi) && !to.fullPath.match(/^\/unterm2.*/g)) {
        let rewritten = to.fullPath.replace(/^\/unterm2(.*)/gi, "$1")
        next(rewritten)
    }
    else if (!to.name) { next() }
    else {
        let langCode = to.params.langCode
        let currentLangCode = store.getters.getCurrentLanguage
        let redirect = false
        if (!['ar', 'en', 'es', 'fr', 'ru', 'zh'].includes(langCode)) {
            langCode = currentLangCode ? currentLangCode : 'en'
            redirect = true
        }
        if (currentLangCode !== langCode) {
            store.dispatch(Actions.SET_CURRENT_LANGUAGE, langCode)
        }
        if (redirect) {
            next(`/${langCode}${to.fullPath}`)
        } else {
            next()
        }
    }
})

export default router
