import { bodies } from './bodies';
import { subjects } from './subjects';
export default {
    fr: {
        ...bodies.fr,
        ...subjects.fr,
        home: 'Accueil',
        about: 'À propos',
        help: 'Aide',
        user_guide: 'Manuel d\'utilisation',
        contact_us: 'Contact',
        resources: 'Ressources',
        log_in: 'Connexion',
        search_database: 'Recherche dans la base de données',
        all_words: 'Tous les mots',
        exact_phrase: 'Expression exacte',
        combined: 'Recherche combinée',
        boolean: 'Recherche booléenne',
        fuzzy: 'Correspondance partielle',
        search: 'Recherche',
        enter_search_query: "Veuillez saisir une requête",
        no_result_found: 'Aucun résultat trouvé pour',
        advanced_search: 'Recherche avancée',
        clear_all: 'Tout effacer',
        search_in: 'Rechercher en',
        display_results_in: 'Afficher les résultats en',
        other: 'Autres',
        datasets: 'Bases de données',
        all_datasets: 'Toutes les bases de données',
        all_subjects: 'Tous les domaines',
        all_bodies: 'Tous les organes',
        apply: 'Appliquer',
        please_read: 'Merci de lire attentivement les {terms_of_use_link} et {copyright_notice_link} de l\'Organisation des Nations Unies.',
        terms_of_use: 'conditions d\'utilisation',
        copyright_notice: 'l\'avis de droit d\'auteur',
        error_executing_search: 'Erreur lors de la recherche, merci de réessayer',
        results: 'Résultats {0}. sur {1}. ',
        other_languages: 'Autres langues',
        clear_search_history: 'Effacer l\'historique de recherche',
        searching_for: 'Vous recherchez',
        open: 'Ouvrir',
        details: 'Description',
        public_record: 'Fiche publique',
        internal_record: 'Fiche interne',
        record_complete: 'Fiche complète',
        record_obsolete: 'Cette fiche est obsolète.',
        language_validated: 'Intitulé validé',
        language_internally_validated: 'Intitulé validé en interne',
        language_not_validated: 'Intitulé non validé',
        language_under_review: 'Intitulé en cours d\'examen',
        record_status: 'Statut de la fiche',
        record_distribution: 'Diffusion de la fiche',
        references: 'Références',
        subjects: 'Domaines',
        subject: 'Sujet',
        bodies: 'Organes',
        english: 'Anglais',
        french: 'Français',
        spanish: 'Espagnol',
        russian: 'Russe',
        chinese: 'Chinois',
        arabic: 'Arabe',
        german: 'Allemand',
        portuguese: 'Portugais',
        editorial: 'Édition',
        share_by_email: 'Partager un lien vers cette fiche par courriel.',
        send_feedback: 'Envoyer un commentaire',
        send_us_feedback: 'Envoyer un commentaire sur cette fiche.',
        feedback_success: "Commentaires soumis avec succès.",
        feedback_error: "Échec de l'envoi des commentaires.",
        country: 'Pays',
        phraseology: 'Phraséologie',
        proper_name: 'Nom propre',
        footnote: 'Note de bas de page',
        term: 'Terme',
        title: 'Titre',
        synonym: 'synonyme',
        complete: 'Complète',
        obsolete: 'Obsolète',
        suggestion: 'Suggestion',
        review_draft: 'Projet en cours d\'examen',
        review_suggestion: 'Proposition de révision',
        draft: 'Projet',
        ok: 'OK',
        review_suggestion_priority: 'Proposition de révision prioritaire',
        obsolete_candidate: 'Potentiellement obsolète',
        superseded: 'Intitulé remplacé',
        review_draft_priority: 'Projet à examiner en priorité',
        suspended: 'Caduque',
        deleted: 'Supprimée',
        verification: 'En vérification',
        variant: 'Variante',
        alternate: 'Synonyme',
        phrase: 'Expression',
        short_form: 'Forme courte',
        acronym: 'Acronyme',
        avoid: 'À éviter',
        rejected: 'Rejeté',
        standardized: 'Normalisé',
        loading: 'Chargement',
        error: 'Erreur !',
        success: "Succès",
        search_failed: 'Échec de la recherche',
        definition: 'Définition',
        description: 'Description',
        remark: 'N.B.',
        note: 'Note',
        source: 'Source',
        sources: 'Sources',
        link: 'Lien',
        more: 'Développer',
        less: 'Réduire',
        updated: 'Mise à jour',
        created: 'Création ',
        your_name: 'Votre nom ',
        enter_name: 'Saisir le nom',
        email_address: 'Adresse électronique ',
        enter_email: 'Saisir l\'adresse électronique',
        never_share_your_email: 'Nous ne communiquerons jamais votre adresse électronique à qui que ce soit.',
        comment: 'Commentaire ',
        enter_comment: 'Saisir le commentaire',
        submit: 'Envoyer',
        reset: 'Réinitialiser',
        validation_error_name: 'Nom (obligatoire)',
        validation_error_email: 'Adresse électronique (obligatoire)',
        validation_error_suggestion: "Campo obligatorio",
        validation_error_term: "Campo obligatorio",
        validation_error_comment: 'Commentaire (obligatoire)',
        validation_error_captcha: 'Test CAPTCHA',
        comment_sent: 'Commentaire envoyé',
        comment_not_sent: 'Votre commentaire n\'a pas été envoyé.Veuillez recommencer.',
        lanugage_inquiry_message: "Avez-vous fait votre recherche dans une autre langue que l’anglais ?",
        search_in_language_message: "Dans « Recherche avancée », sélectionnez les langues dans lesquelles vous souhaitez effectuer vos recherches.",
        currently_searching_in: "La recherche est actuellement paramétrée dans les langues suivantes :",
        are_important_entries_missing: "Il manque une entrée importante ? Envoyez-nous votre suggestion !",
        "country short": "Forme courte",
        send_your_suggestions: "S'il vous plaît envoyez-nous votre suggestion!",
        databases: "Bases de données",
        domains: "domaines",
        press_space_bar: "Pour activer appuyez sur la barre d'espace",
        enter_text_search: "Entrez du texte et appuyez sur Entrée pour rechercher.",
        filter: "Filtre",
        country_name: "Noms de pays",
        download_country_name: "Télécharger la liste des pays",
        currency_designation: "Devise Désignation",
        currency_symbol: "Symbole de la monnaie",
        fractional_unit: "Unité fractionnaire",
        inhabitant: "Habitante",
        capital_inhabitants: "Habitants de la capitale",
        monetary_unit_plural: "Pluriel d'unité monétaire",
        languages: "Langues",
        adjective: "Adjectif",
        capital_city: "Capitale",
        monetary_unit: "Unité monétaire",
        settings: "Paramètres",
        fill_in_the_form_to_suggest_a_new_entry: 'Remplissez le formulaire pour proposer une nouvelle entrée',
        source_language: 'Langue source',
        context: 'Contexte',
        target_language: 'Langue cible',
        body: 'Organe',
        your_email: 'Adresse électronique',
        validation_suggestion: 'votre suggestion est requise',
        validation_source: 'la source du terme est requise',
        target: "Cibler",
        update_default_settings: 'Modifier les paramètres par défaut',
        settings_saved_in_cookie: "Paramètres enregistrés dans le cookie",
        submit_feedback: 'Envoyer un commentaire',
        which_languages_feedback_for: "Sur quelle langue porte votre commentaire ?",
        email: 'Adresse électronique',
        suggested_term: 'Terme proposé',
        term_source: 'Source',
        additional_fields: 'Champs supplémentaires',
        select: 'Sélectionner',
        comments: 'Observations',
        short: 'Forme courte',
        home_title: 'Le portail terminologique des Nations Unies',
        remove: "Retirer",
        headerTitle1: "La base de données terminologique",
        headerTitle2: "Département de l'Assemblée générale </br>et de la gestion des conférences",
        country_not_found_message: "Aucun pays trouvé correspondant aux critères de recherche fournis",
        no_elements_found: "Aucun élément trouvé. Veuillez envisager de modifier le contenu de la requête.",
        record_superseded: 'Cette fiche a été remplacée.',
        record_ok: 'Enregistrer OK',
    }
}
