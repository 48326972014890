var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      class: _vm.currentRoute,
      attrs: {
        id: "brand-bar",
        role: "navigation",
        toggleable: "lg",
        type: "dark",
        variant: "primary",
      },
    },
    [
      _c("div", { staticClass: "bg-secondary", attrs: { id: "gray-band" } }),
      _vm._v(" "),
      _c(
        "b-col",
        [
          _c(
            "b-navbar-toggle",
            { attrs: { role: "menuitem", target: "language-switcher" } },
            [
              _c("span", { staticClass: "sr-only" }, [
                _vm._v("Toggle navigation"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "icon-bar" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-bar" }),
              _vm._v(" "),
              _c("span", { staticClass: "icon-bar" }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "logo navbar-btn pull-left flip",
          attrs: {
            href: _vm.getAppBasePath + "/" + _vm.getCurrentLanguage,
            title: "United Nations",
          },
        },
        [
          _c("img", {
            staticClass: "img-responsive",
            attrs: {
              src: "https://www.un.org/sites/un2.un.org/themes/bootstrap_un2/logo.png",
              alt: "UN logo",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("a", {
        staticClass: "navbar-brand pull-left slogan-text flip",
        attrs: {
          href: _vm.getAppBasePath + "/" + _vm.getCurrentLanguage,
          title: _vm.$t("headerTitle2"),
        },
        domProps: { innerHTML: _vm._s(_vm.$t("headerTitle2")) },
      }),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "navbar-collapse collapse",
          attrs: { id: "language-switcher" },
        },
        [
          _c("div", { staticClass: "mr-auto" }),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "home",
                  attrs: {
                    to: "/",
                    id: "home-menu",
                    active: _vm.currentRoute === "Home",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("home")))]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "about",
                  attrs: {
                    to: "/about",
                    id: "about-menu",
                    active: _vm.currentRoute === "About",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("about")))]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "help",
                  attrs: {
                    id: "help-menu",
                    "aria-label": _vm.$t("press_space_bar"),
                    "menu-class": "bg-primary",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("help")) +
                              "\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      attrs: { to: "/user-guide" },
                    },
                    [_vm._v(_vm._s(_vm.$t("user_guide")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      attrs: {
                        to: "/contact",
                        active: _vm.currentRoute === "Contact",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("contact_us")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "help",
                  attrs: {
                    id: "resource-menue",
                    "aria-label": _vm.$t("press_space_bar"),
                    "menu-class": "bg-primary",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("resources")) +
                              "\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      attrs: { to: "/country" },
                    },
                    [_vm._v(_vm._s(_vm.$t("country_name")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "settings",
                  attrs: {
                    to: "/settings",
                    id: "settings-menu",
                    active: _vm.currentRoute === "Settings",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("settings")))]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "login",
                  attrs: { id: "login-menu", target: "blank" },
                  on: { click: _vm.login },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("log_in")) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "language",
                  attrs: {
                    id: "language-menu",
                    "aria-label": _vm.$t("press_space_bar"),
                    "menu-class": "bg-primary",
                    right: !_vm.isRTL,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.getCurrentLanguageName) +
                              "\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: _vm.localizePath("ar"),
                        lang: "ar",
                        "xml:lang": "ar",
                      },
                    },
                    [_vm._v("العربية")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: _vm.localizePath("zh"),
                        lang: "zh",
                        "xml:lang": "zh-hans",
                      },
                    },
                    [_vm._v("中文")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: _vm.localizePath("en"),
                        lang: "en",
                        "xml:lang": "en",
                      },
                    },
                    [_vm._v("English")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: _vm.localizePath("fr"),
                        lang: "fr",
                        "xml:lang": "fr",
                      },
                    },
                    [_vm._v("Français")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: _vm.localizePath("ru"),
                        lang: "ru",
                        "xml:lang": "ru",
                      },
                    },
                    [_vm._v("Русский")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: _vm.localizePath("es"),
                        lang: "es",
                        "xml:lang": "es",
                      },
                    },
                    [_vm._v("Español")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-col"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }