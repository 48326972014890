export const bodies = {
    en: {
        'European Union': 'European Union',
        'CTBTO': 'CTBTO',
        'ECA': 'ECA',
        'ECE': 'ECE',
        'ECLAC': 'ECLAC',
        'ECOSOC': 'ECOSOC',
        'ESCAP': 'ESCAP',
        'ESCWA': 'ESCWA',
        'External Organizations': 'External Organizations',
        'FAO': 'FAO',
        'General Assembly': 'General Assembly',
        'Human Rights Council': 'Human Rights Council',
        'IAEA': 'IAEA',
        'ICAO': 'ICAO',
        'ICJ': 'ICJ',
        'IFAD': 'IFAD',
        'ILC': 'ILC',
        'ILO': 'ILO',
        'IMF': 'IMF',
        'IMO': 'IMO',
        'INCB': 'INCB',
        'ITU': 'ITU',
        'OCHA': 'OCHA',
        'OHCHR': 'OHCHR',
        'OOSA': 'OOSA',
        'OPCW': 'OPCW',
        'Secretariat': 'Secretariat',
        'Security Council': 'Security Council',
        'Specialized Agencies': 'Specialized Agencies',
        'Trusteeship Council': 'Trusteeship Council',
        'UNAIDS': 'UNAIDS',
        'UNCITRAL': 'UNCITRAL',
        'UNCTAD': 'UNCTAD',
        'UNDP': 'UNDP',
        'UNEP': 'UNEP',
        'UNESCO': 'UNESCO',
        'UNFCCC': 'UNFCCC',
        'UNFPA': 'UNFPA',
        'UN-HABITAT': 'UN-HABITAT',
        'UNHCR': 'UNHCR',
        'UNICEF': 'UNICEF',
        'UNIDO': 'UNIDO',
        'UNDRR': 'UNDRR',
        'UNODC': 'UNODC',
        'UNOG': 'UNOG',
        'UNON': 'UNON',
        'UNOV': 'UNOV',
        'UNRWA': 'UNRWA',
        'UN-Women': 'UN-Women',
        'UNWTO': 'UNWTO',
        'UPU': 'UPU',
        'WFP': 'WFP',
        'WHO': 'WHO',
        'WMO': 'WMO',
        'WTO': 'WTO',
        'ICTR': 'ICTR',
        ICTY: 'ICTY',
        IOM: 'IOM',
        ISA: 'ISA',
        MINUJUSTH: 'MINUJUSTH',
        MINURSO: 'MINURSO',
        MINUSCA: 'MINUSCA',
        MINUSMA: 'MINUSMA',
        MONUSCO: 'MONUSCO',
        UNAMI: 'UNAMI',
        UNAMID: 'UNAMID',
        UNDOF: 'UNDOF',
        UNFICYP: 'UNFICYP',
        UNIFIL: 'UNIFIL',
        UNISFA: 'UNISFA',
        UNJSPF: 'UNJSPF',
        UNMIK: 'UNMIK',
        UNMISS: 'UNMISS',
        UNMOGIP: 'UNMOGIP',
        UNOAU: 'UNOAU',
        UNOPS: 'UNOPS',
        UNTSO: 'UNTSO',
        WIPO: 'WIPO',
        IRMCT: 'IRMCT',
        UNISDR: 'UNISDR',
        DETERM: 'DETERM',
        'BINUH': 'BINUH',
        'CAT': 'CAT',
        'CEDAW': 'CEDAW',
        'Council of Europe': 'Council of Europe',
        'ICRC': 'ICRC',
        'ICSC': 'ICSC',
        'IPCC': 'IPCC',
        'PAHO': 'PAHO',
        'UNAMA': 'UNAMA',
        'UNCCD': 'UNCCD',
        'UNITAMS': 'UNITAMS',
        'UNSMIL': 'UNSMIL',
        'UNVMC': 'UNVMC'
    },
    es: {
        'European Union': "Unión Europea",
        'CTBTO': 'OTPCE',
        'ECA': 'CEPA',
        'ECE': 'CEPE',
        'ECLAC': 'CEPAL',
        'ECOSOC': 'ECOSOC',
        'ESCAP': 'CESPAP',
        'ESCWA': 'CESPAO',
        'External Organizations': 'Organizaciones externas',
        'FAO': 'FAO',
        'General Assembly': 'Asamblea General',
        'Human Rights Council': 'Consejo de Derechos Humanos',
        'IAEA': 'OIEA',
        'ICAO': 'OACI',
        'ICJ': 'CIJ',
        'IFAD': 'FIDA',
        'ILC': 'CDI',
        'ILO': 'OIT',
        'IMF': 'FMI',
        'IMO': 'OMI',
        'INCB': 'JIFE',
        'ITU': 'UIT',
        'OCHA': 'OCHA',
        'OHCHR': 'ACNUDH',
        'OOSA': 'OOSA',
        'OPCW': 'OPAQ',
        'Secretariat': 'Secretaría',
        'Security Council': 'Consejo de Seguridad',
        'Specialized Agencies': 'Organismos especializados',
        'Trusteeship Council': 'Consejo de Administración Fiduciaria',
        'UNAIDS': 'ONUSIDA',
        'UNCITRAL': 'CNUDMI',
        'UNCTAD': 'UNCTAD',
        'UNDP': 'PNUD',
        'UNEP': 'PNUMA',
        'UNESCO': 'UNESCO',
        'UNFCCC': 'CMNUCC',
        'UNFPA': 'UNFPA',
        'UN-HABITAT': 'ONU-Hábitat',
        'UNHCR': 'ACNUR',
        'UNICEF': 'UNICEF',
        'UNIDO': 'ONUDI',
        'UNDRR': 'UNDRR',
        'UNODC': 'UNODC',
        'UNOG': 'ONUG',
        'UNON': 'ONUN',
        'UNOV': 'ONUV',
        'UNRWA': 'UNRWA',
        'UN-Women': 'ONU-Mujeres',
        'UNWTO': 'OMT',
        'UPU': 'UPU',
        'WFP': 'PMA',
        'WHO': 'OMS',
        'WMO': 'OMM',
        'WTO': 'OMC',
        ICTR: "TPIR",
        ICTY: "TPIY",
        IOM: "OIM",
        IRMCT: "MRITP",
        UNDOF: "FNUOS",
        UNIFIL: "FPNUL",
        UNJSPF: "CCPPNU",
        UNOAU: "ONUUA",
        UNTSO: "ONUVT",
        WIPO: "OMPI",
        'BINUH': 'BINUH',
        'CAT': 'CAT',
        'CEDAW': 'CEDAW',
        'Council of Europe': 'Consejo de Europa',
        'ICRC': 'CICR',
        'ICSC': 'CAPI',
        'IPCC': 'IPCC',
        'PAHO': 'OPS',
        'UNAMA': 'UNAMA',
        'UNCCD': 'CLD',
        'UNITAMS': 'UNITAMS',
        'UNSMIL': 'UNSMIL',
        'UNVMC': 'UNVMC'
    },
    fr: {
        'European Union': "Union européenne",
        'CTBTO': 'OTICE',
        'ECA': 'CEA',
        'ECE': 'CEE',
        'ECLAC': 'CEPALC',
        'ECOSOC': 'Conseil économique et social',
        'ESCAP': 'CESAP',
        'ESCWA': 'CESAO',
        'External Organizations': 'Autres organisations',
        'FAO': 'FAO',
        'General Assembly': 'Assemblée générale',
        'Human Rights Council': 'Conseil des droits de l\'homme',
        'IAEA': 'AIEA',
        'ICAO': 'OACI',
        'ICJ': 'CIJ',
        'IFAD': 'FIDA',
        'ILC': 'CDI',
        'ILO': 'OIT',
        'IMF': 'FMI',
        'IMO': 'OMI',
        'INCB': 'OICS',
        'ITU': 'UIT',
        'OCHA': 'OCHA',
        'OHCHR': 'HCDH',
        'OOSA': 'Bureau des affaires spatiales',
        'OPCW': 'OIAC',
        'Secretariat': 'Secrétariat',
        'Security Council': 'Conseil de sécurité',
        'Specialized Agencies': 'Institutions spécialisées',
        'Trusteeship Council': 'Conseil de tutelle',
        'UNAIDS': 'ONUSIDA',
        'UNCITRAL': 'CNUDCI',
        'UNCTAD': 'CNUCED',
        'UNDP': 'PNUD',
        'UNEP': 'PNUE',
        'UNESCO': 'UNESCO',
        'UNFCCC': 'CCNUCC',
        'UNFPA': 'FNUAP',
        'UN-HABITAT': 'ONU-Habitat',
        'UNHCR': 'HCR',
        'UNICEF': 'UNICEF',
        'UNIDO': 'ONUDI',
        'UNDRR': 'UNDRR',
        'UNODC': 'ONUDC',
        'UNOG': 'ONUG',
        'UNON': 'ONUN',
        'UNOV': 'ONUV',
        'UNRWA': 'UNRWA',
        'UN-Women': 'ONU-Femmes',
        'UNWTO': 'OMT',
        'UPU': 'UPU',
        'WFP': 'PAM',
        'WHO': 'OMS',
        'WMO': 'OMM',
        'WTO': 'OMC',
        ICTR: "TPIR",
        ICTY: "TPIY",
        IOM: "OIM",
        IRMCT: "MIFRTP",
        UNDOF: "FNUOD",
        UNIFIL: "FINUL",
        UNJSPF: "Caisse des pensions",
        UNOAU: "BNUUA",
        UNTSO: "ONUST",
        WIPO: "OMPI",
        UNAMI: 'MANUI',
        UNAMID: 'MINUAD',
        UNISFA: 'FISNUA',
        UNMIK: 'MINUK',
        UNMISS: 'MINUSS',
        'BINUH': 'BINUH ',
        'CAT': 'Comité contre la torture',
        'CEDAW': 'CEDAW',
        'Council of Europe': "Conseil de l'Europe",
        'ICRC': 'CICR',
        'ICSC': 'CFPI',
        'IPCC': 'GIEC',
        'PAHO': 'OPS',
        'UNAMA': 'MANUA',
        'UNCCD': 'CLD',
        'UNITAMS': 'MINUATS',
        'UNSMIL': 'MANUL',
        'UNVMC': 'Mission de vérification des Nations Unies en Colombie'
    },
    ar: {
        'European Union': "الاتحاد الأوروبي",
        'CTBTO': 'منظمة معاهدة الحظر الشامل للتجارب النووية',
        'ECA': 'اللجنة الاقتصادية لأفريقيا',
        'ECE': 'اللجنة الاقتصادية لأوروبا',
        'ECLAC': 'اللجنة الاقتصادية لأمريكا اللاتينية ومنطقة البحر الكاريبي',
        'ECOSOC': 'المجلس الاقتصادي والاجتماعي',
        'ESCAP': 'اللجنة الاقتصادية والاجتماعية لآسيا والمحيط الهادئ',
        'ESCWA': 'اللجنة الاقتصادية والاجتماعية لغربي آسيا',
        'External Organizations': 'منظمات خارجية',
        'FAO': 'منظمة الأغذية والزراعة',
        'General Assembly': 'الجمعية العامة',
        'Human Rights Council': 'مجلس حقوق الإنسان',
        'IAEA': 'الوكالة الدولية للطاقة الذرية',
        'ICAO': 'منظمة الطيران المدني الدولي',
        'ICJ': 'محكمة العدل الدولية',
        'IFAD': 'الصندوق الدولي للتنمية الزراعية',
        'ILC': 'لجنة القانون الدولي',
        'ILO': 'منظمة العمل الدولية',
        'IMF': 'صندوق النقد الدولي',
        'IMO': 'المنظمة البحرية الدولية',
        'INCB': 'الهيئة الدولية لمراقبة المخدرات',
        'ITU': 'الاتحاد الدولي للاتصالات',
        'OCHA': 'مكتب تنسيق الشؤون الإنسانية',
        'OHCHR': 'مفوضية حقوق الإنسان',
        'OOSA': 'مكتب شؤون الفضاء الخارجي',
        'OPCW': 'منظمة حظر الأسلحة الكيميائية',
        'Secretariat': 'الأمانة العامة',
        'Security Council': 'مجلس الأمن',
        'Specialized Agencies': 'الوكالات المتخصصة',
        'Trusteeship Council': 'مجلس الوصاية',
        'UNAIDS': 'برنامج الأمم المتحدة المشترك المعني بفيروس نقص المناعة البشرية/الإيدز',
        'UNCITRAL': 'لجنة الأمم المتحدة للقانون التجاري الدولي',
        'UNCTAD': 'مؤتمر الأمم المتحدة للتجارة والتنمية',
        'UNDP': 'برنامج الأمم المتحدة الإنمائي',
        'UNEP': 'برنامج الأمم المتحدة للبيئة',
        'UNESCO': 'منظمة الأمم المتحدة للتربية والعلم والثقافة',
        'UNFCCC': 'اتفاقية الأمم المتحدة الإطارية بشأن تغيُّر المناخ',
        'UNFPA': 'صندوق الأمم المتحدة للسكان',
        'UN-HABITAT': 'موئل الأمم المتحدة',
        'UNHCR': 'مفوضية الأمم المتحدة لشؤون اللاجئين',
        'UNICEF': 'منظمة الأمم المتحدة للطفولة',
        'UNIDO': 'منظمة الأمم المتحدة للتنمية الصناعية',
        'UNDRR': 'مكتب الأمم المتحدة للحد من مخاطر الكوارث',
        'UNODC': 'مكتب الأمم المتحدة المعني بالمخدرات والجريمة',
        'UNOG': 'مكتب الأمم المتحدة في جنيف',
        'UNON': 'مكتب الأمم المتحدة في نيروبي',
        'UNOV': 'مكتب الأمم المتحدة في فيينا',
        'UNRWA': 'وكالة الأمم المتحدة لإغاثة وتشغيل اللاجئين الفلسطينيين في الشرق الأدنى',
        'UN-Women': 'هيئة الأمم المتحدة للمساواة بين الجنسين وتمكين المرأة',
        'UNWTO': 'منظمة السياحة العالمية',
        'UPU': 'الاتحاد البريدي العالمي',
        'WFP': 'برنامج الأغذية العالمي',
        'WHO': 'منظمة الصحة العالمية',
        'WMO': 'المنظمة العالمية للأرصاد الجوية',
        'WTO': 'منظمة التجارة العالمية',
        ICTR: "المحكمة الجنائية الدولية لرواندا",
        ICTY: "المحكمة الجنائية الدولية ليوغوسلافيا السابقة",
        IOM: "المنظمة الدولية للهجرة",
        IRMCT: "الآلية الدولية لتصريف الأعمال المتبقية للمحكمتين الجنائيتين",
        ISA: "السلطة الدولية لقاع البحار",
        MINUJUSTH: "بعثة الأمم المتحدة لدعم نظام العدالة في هايتي",
        MINURSO: "بعثة الأمم المتحدة للاستفتاء في الصحراء الغربية",
        MINUSCA: "بعثة الأمم المتحدة المتكاملة المتعددة الأبعاد لتحقيق الاستقرار في جمهورية أفريقيا الوسطى",
        MINUSMA: "بعثة الأمم المتحدة المتكاملة المتعددة الأبعاد لتحقيق الاستقرار في مالي",
        MONUSCO: "بعثة منظمة الأمم المتحدة لتحقيق الاستقرار في جمهورية الكونغو الديمقراطية",
        UNAMI: "بعثة الأمم المتحدة لتقديم المساعدة إلى العراق",
        UNAMID: "العملية المختلطة للاتحاد الأفريقي والأمم المتحدة في دارفور",
        UNDOF: "قوة الأمم المتحدة لمراقبة فض الاشتباك",
        UNFICYP: "قوة الأمم المتحدة لحفظ السلام في قبرص",
        UNIFIL: "قوة الأمم المتحدة المؤقتة في لبنان",
        UNISFA: "قوة الأمم المتحدة الأمنية المؤقتة لأبيي",
        UNJSPF: "الصندوق المشترك للمعاشات التقاعدية لموظفي الأمم المتحدة",
        UNMIK: "بعثة الأمم المتحدة للإدارة المؤقتة في كوسوفو",
        UNMISS: "بعثة الأمم المتحدة في جنوب السودان",
        UNMOGIP: "فريق مراقبي الأمم المتحدة العسكريين في الهند وباكستان",
        UNOAU: "مكتب الأمم المتحدة لدى الاتحاد الأفريقي",
        UNOPS: "مكتب الأمم المتحدة للشراكات",
        UNTSO: "هيئة الأمم المتحدة لمراقبة الهدنة",
        WIPO: "المنظمة العالمية للملكية الفكرية",
        'BINUH': 'مكتب الأمم المتحدة المتكامل في هايتي',
        'CAT': 'لجنة مناهضة التعذيب',
        'CEDAW': 'اللجنة المعنية بالقضاء على التمييز ضد المرأة',
        'Council of Europe': 'مجلس أوروبا',
        'ICRC': 'اللجنة الدولية للصليب الأحمر',
        'ICSC': 'لجنة الخدمة المدنية الدولية',
        'IPCC': 'الهيئة الحكومية الدولية المعنية بتغير المناخ',
        'PAHO': 'منظمة الصحة للبلدان الأمريكية',
        'UNAMA': 'بعثة الأمم المتحدة لتقديم المساعدة إلى أفغانستان',
        'UNCCD': 'اتفاقية الأمم المتحدة لمكافحة التصحر',
        'UNITAMS': 'بعثة الأمم المتحدة المتكاملة في السودان',
        'UNSMIL': 'بعثة الأمم المتحدة للدعم في ليبيا',
        'UNVMC': 'بعثة الأمم المتحدة للتحقق في كولومبيا'
    },
    ru: {
        'European Union': "Европейский союз",
        'CTBTO': 'ОДВЗЯИ',
        'ECA': 'ЭКА',
        'ECE': 'ЕЭК',
        'ECLAC': 'ЭКЛАК',
        'ECOSOC': 'ЭКОСОС',
        'ESCAP': 'ЭСКАТО',
        'ESCWA': 'ЭСКЗА',
        'External Organizations': 'Внешние организации',
        'FAO': 'ФАО',
        'General Assembly': 'Генеральная Ассамблея',
        'Human Rights Council': 'Совет по правам человека',
        'IAEA': 'МАГАТЭ',
        'ICAO': 'ИКАО',
        'ICJ': 'МС',
        'IFAD': 'МФСР',
        'ILC': 'КМП',
        'ILO': 'МОТ',
        'IMF': 'МВФ',
        'IMO': 'ИМО',
        'INCB': 'МККН',
        'ITU': 'МСЭ',
        'OCHA': 'УКГВ',
        'OHCHR': 'УВКПЧ',
        'OOSA': 'УВКП',
        'OPCW': 'ОЗХО',
        'Secretariat': 'Секретариат',
        'Security Council': 'Совет Безопасности',
        'Specialized Agencies': 'Специализированные учреждения',
        'Trusteeship Council': 'Совет по Опеке',
        'UNAIDS': 'ЮНЭЙДС',
        'UNCITRAL': 'ЮНСИТРАЛ',
        'UNCTAD': 'ЮНКТАД',
        'UNDP': 'ПРООН',
        'UNEP': 'ЮНЕП',
        'UNESCO': 'ЮНЕСКО',
        'UNFCCC': 'РКИКООН',
        'UNFPA': 'ЮНФПА',
        'UN-HABITAT': 'ООН-Хабитат',
        'UNHCR': 'УВКБ',
        'UNICEF': 'ЮНИСЕФ',
        'UNIDO': 'ЮНИДО',
        'UNDRR': 'МСУОБ',
        'UNODC': 'УНП ООН',
        'UNOG': 'ЮНОГ',
        'UNON': 'ЮНОН',
        'UNOV': 'ЮНОВ',
        'UNRWA': 'БАПОР',
        'UN-Women': 'Структура «ООН-женщины»',
        'UNWTO': 'ЮНВТО',
        'UPU': 'ВПС',
        'WFP': 'ВПП',
        'WHO': 'ВОЗ',
        'WMO': 'ВМО',
        'WTO': 'ВТО',
        ICTR: 'МУТР',
        ICTY: 'МТБЮ',
        IOM: 'МОМ',
        ISA: 'МОМД',
        MINUJUSTH: 'МООНППГ',
        MINURSO: 'МООНРЗС',
        MINUSCA: 'МИНУСКА',
        MINUSMA: 'МИНУСМА',
        MONUSCO: 'МООНСДРК',
        UNAMI: 'МООНСИ',
        UNAMID: 'ЮНАМИД',
        UNDOF: 'СООННР',
        UNFICYP: 'ВСООНК',
        UNIFIL: 'ВСООНЛ',
        UNISFA: 'ЮНИСФА',
        UNJSPF: 'ОПФПООН',
        UNMIK: 'МООНК',
        UNMISS: 'МООНЮС',
        UNMOGIP: 'ГВНООНИП',
        UNOAU: 'ОООНАС',
        UNOPS: 'ЮНОПС',
        UNTSO: 'ОНВУП',
        WIPO: 'ВОИС',
        IRMCT: 'МОМУТ',
        'BINUH': 'ОПООНГ',
        'CAT': 'Комитет против пыток',
        'CEDAW': 'КЛДОЖ',
        'Council of Europe': 'Совет Европы',
        'ICRC': 'МККК',
        'ICSC': 'КМГС',
        'IPCC': 'МГЭИК',
        'PAHO': 'ПАОЗ',
        'UNAMA': 'МООНСА',
        'UNCCD': 'КБОООН',
        'UNITAMS': 'ЮНИТАМС',
        'UNSMIL': 'МООНПЛ',
        'UNVMC': 'КМООНК'
    },
    zh: {
        "European Union": "欧洲联盟",
        'CTBTO': '禁核试条约组织',
        'ECA': '非洲经委会',
        'ECE': '欧洲经委会',
        'ECLAC': '拉加经委会',
        'ECOSOC': '经社理事会',
        'ESCAP': '亚太经社会',
        'ESCWA': '西亚经社会',
        'External Organizations': '外部组织',
        'FAO': '粮农组织',
        'General Assembly': '大会',
        'Human Rights Council': '人权理事会',
        'IAEA': '原子能机构',
        'ICAO': '国际民航组织',
        'ICJ': '国际法院',
        'IFAD': '农发基金',
        'ILC': '国际法委员会',
        'ILO': '劳工组织',
        'IMF': '基金组织',
        'IMO': '海事组织',
        'INCB': '麻管局',
        'ITU': '国际电联',
        'OCHA': '人道协调厅',
        'OHCHR': '人权高专办',
        'OOSA': '外空厅',
        'OPCW': '禁化武组织',
        'Secretariat': '秘书处',
        'Security Council': '安全理事会',
        'Specialized Agencies': '专门机构',
        'Trusteeship Council': '托管理事会',
        'UNAIDS': '艾滋病署',
        'UNCITRAL': '贸易法委员会',
        'UNCTAD': '贸发会议',
        'UNDP': '开发署',
        'UNEP': '环境署',
        'UNESCO': '教科文组织',
        'UNFCCC': '气候公约',
        'UNFPA': '人口基金',
        'UN-HABITAT': '人居署',
        'UNHCR': '难民署',
        'UNICEF': '儿基会',
        'UNIDO': '工发组织',
        'UNDRR': '减灾办',
        'UNODC': '毒品和犯罪问题办公室',
        'UNOG': '日内瓦办事处',
        'UNON': '内罗毕办事处',
        'UNOV': '维也纳办事处',
        'UNRWA': '近东救济工程处',
        'UN-Women': '妇女署',
        'UNWTO': '世旅组织',
        'UPU': '万国邮联',
        'WFP': '粮食署',
        'WHO': '世卫组织',
        'WMO': '气象组织',
        'WTO': '世贸组织',
        ICTR: "卢旺达问题国际法庭",
        ICTY: "前南斯拉夫问题国际法庭",
        IOM: "移民组织",
        IRMCT: "余留机制",
        ISA: "海管局",
        MNUJUSTH: "联海司法支助团",
        MINUJUSTH: "联海司法支助团",
        MINURSO: "西撒特派团",
        MINUSCA: "中非稳定团",
        MINUSMA: "马里稳定团",
        MONUSCO: "联刚稳定团",
        UNAMI: "联伊援助团",
        UNAMID: "达尔富尔混合行动",
        UNDOF: "观察员部队",
        UNFICYP: "联塞部队",
        UNIFIL: "联黎部队",
        UNISFA: "联阿安全部队",
        UNJSPF: "养恤基金",
        UNMIK: "科索沃特派团",
        UNMISS: "南苏丹特派团",
        UNMOGIP: "印巴观察组",
        UNOAU: "联非办",
        UNOPS: "项目署",
        UNTSO: "停战监督组织",
        WIPO: "知识产权组织",
        DETERM: "英德双语库",
        'BINUH': '联海综合办',
        'CAT': '禁止酷刑委员会',
        'CEDAW': '消除对妇女歧视委员会',
        'Council of Europe': '欧洲委员会',
        'ICRC': '红十字国际委员会',
        'ICSC': '公务员制度委员会',
        'IPCC': '气专委',
        'PAHO': '泛美卫生组织',
        'UNAMA': '联阿援助团',
        'UNCCD': '防治荒漠化公约秘书处',
        'UNITAMS': '联苏综合援助团',
        'UNSMIL': '联利支助团',
        'UNVMC': '联哥核查团'
    }
}
