import { Term, Record as record } from "./Constants";

export class RecordModel {
    public constructor(init?: Partial<RecordModel>) {
        Object.assign(this, init);
    }

    recordID: string
    datasource: string
    recordType: string
    space: string
    status: string
    distribution: string
    languages: Array<string> = []
    subjects: Array<string> = []
    bodies: Array<string> = []

    public static getDisplayType(termType: string, termStatus: string, recordType: string): string {

        let label = "";
        let prioritaryStatuses: Array<string> = [Term.Status.Deleted, Term.Status.Rejected, Term.Status.Accepted]
        if (prioritaryStatuses.indexOf(termStatus) >= 0) {
            return termStatus;
        }

        if (termStatus == Term.Status.Open) {
            return "suggestion";
        }

        label = RecordModel.getDisplayTypeForStandardized(termType, termStatus);
        if (label != "") {
            return label;
        }

        if (recordType == record.Type.Term) {
            label = RecordModel.getDisplayTypeForTerm(termType, termStatus);
        } else if (recordType == record.Type.Title) {
            label = RecordModel.getDisplayTypeFoTitle(termType, termStatus);
        } else if (recordType == record.Type.Phraseology) {
            label = RecordModel.getDisplayTypeForPhrase(termType, termStatus);
        } else if (recordType == record.Type.Country) {
            label = RecordModel.getDisplayTypeForCountry(termType, termStatus);

        } else if (recordType == record.Type.ProperName) {
           label = RecordModel.getDisplayTypeForProperName(termType, termStatus);
        }
        return label;
    }

    public static getDisplayTypeForTerm(termType: string, termStatus: string): string {
      switch (termType) {
        case 'full':
        case 'term':
          switch (termStatus) {
            case 'preferred':
              return 'term';
            case 'standardized':
              return 'standardized';
            case 'admitted':
              return 'synonym';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return 'term';
        case 'short':
          switch (termStatus) {
            case 'preferred':
              return 'short form';
            case 'standardized':
              return 'standardized short';
            case 'admitted':
              return 'short form';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return 'short form';
        case 'acronym':
          switch (termStatus) {
            case 'preferred':
              return 'acronym';
            case 'standardized':
              return 'standardized';
            case 'admitted':
              return 'acronym';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return 'acronym';
        case 'variant':
          switch (termStatus) {
            case 'preferred':
              return 'variant';
            case 'standardized':
              return 'standardized variant';
            case 'admitted':
              return 'variant';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return 'variant';
        case 'preferred':
          return 'term';
        case 'admitted':
          return 'synonym';
        case 'deprecated':
          return 'avoid';
      }

      return 'term';
    }

    public static getDisplayTypeForStandardized(termType: string, termStatus: string): string {
        let label = "";
        if (termStatus == Term.Status.Standardized) {
            switch (termType) {
                case Term.Type.Short:
                    return "standardized short"
                case Term.Type.Variant:
                    return "standardized variant"
                default:
                    return "standardized";
            }
        } else if (termStatus == Term.Status.Deprecated) {
            return "avoid";
        } else if (termStatus == Term.Status.Superseded) {
            return "superseded";
        } else if (termType == Term.Type.Acronym) {
            return "acronym";
        }
        return label;
    }

    public static getDisplayTypeFoTitle(termType: string, termStatus: string): string {
      switch (termType) {
        case 'full':
        case 'title':
          switch (termStatus) {
            case 'preferred':
              return 'title';
            case 'standardized':
              return 'standardized';
            case 'admitted':
              return 'alternate';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          break;
        case 'short':
          switch (termStatus) {
            case 'preferred':
              return 'short form';
            case 'standardized':
              return 'standardized short';
            case 'admitted':
              return 'short form';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded short';
          }
          break;
        case 'acronym':
          switch (termStatus) {
            case 'preferred':
              return 'acronym';
            case 'admitted':
              return 'acronym';
            case 'standardized':
              return 'standardized';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return 'acronym';
        case 'variant':
          switch (termStatus) {
            case 'preferred':
              return 'variant';
            case 'admitted':
              return 'variant';
            case 'standardized':
              return 'standardized variant';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return 'variant';
        case 'preferred':
          return 'title';
        case 'admitted':
          return 'alternate';
        case 'deprecated':
          return 'avoid';

      }


      return 'title';
    }
    public static getDisplayTypeForPhrase(termType: string, termStatus: string): string {
      switch (termType) {
        case 'full':
        case 'phrase':
          switch (termStatus) {
            case 'preferred':
              return 'phrase';
            case 'standardized':
              return 'standardized';
            case 'admitted':
              return 'optional';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return "phrase";
        case 'short':
          switch (termStatus) {
            case 'preferred':
              return 'short phrase';
            case 'standardized':
              return 'standardized short';
            case 'admitted':
              return 'short optional';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return "short form";

        case 'variant':
          switch (termStatus) {
            case 'preferred':
              return 'variant';
            case 'standardized':
              return 'standardized';
            case 'admitted':
              return 'variant';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return "variant";
        case 'acronym':
          switch (termStatus) {
            case 'preferred':
              return 'acronym';
            case 'standardized':
              return 'acronym';
            case 'admitted':
              return 'acronym';
            case 'deprecated':
              return 'avoid';
            case 'superseded':
              return 'superseded';
          }
          return "acronym";
      }

      return 'phrase';
    }

    public static getDisplayTypeForCountry(termType: string, termStatus: string): string {
      switch (termType) {
        case 'full':
          switch (termStatus) {
            case 'preferred':
              return "country";
            case 'standardized':
              return "standardized";
            case 'admitted':
              return "synonym";
            case 'deprecated':
              return "avoid";
            case 'superseded':
              return "superseded";
          }
          return 'country';
        case 'short':
          switch (termStatus) {
            case 'preferred':
              return "country short";
            case 'standardized':
              return "standardized short ";
            case 'admitted':
              return "synonym short";
            case 'deprecated':
              return "avoid";
            case 'superseded':
              return "superseded short";
          }
          return 'country short';
        case 'preferred':
          return "country";
        case 'standardized':
          return "standardized";
        case 'admitted':
          return "variant";
        case 'deprecated':
          return "avoid";
        case 'superseded':
          return "superseded";
        case 'acronym':
          return "acronym";
        case 'variant':
          return "variant";

      }

      return 'country';
    }
    public static getDisplayTypeForProperName(termType: string, termStatus: string): string {
      switch (termType) {
        case 'full':
          switch (termStatus) {
            case 'preferred':
              return "country";
            case 'standardized':
              return "standardized";
            case 'admitted':
              return "synonym";
            case 'deprecated':
              return "avoid";
            case 'superseded':
              return "superseded";
          }
          return 'country';
        case 'short':
          switch (termStatus) {
            case 'preferred':
              return "country short";
            case 'standardized':
              return "standardized short ";
            case 'admitted':
              return "synonym short";
            case 'deprecated':
              return "avoid";
            case 'superseded':
              return "superseded short";
          }
          return 'country short';
        case 'preferred':
          return "country";
        case 'standardized':
          return "standardized";
        case 'admitted':
          return "variant";
        case 'deprecated':
          return "avoid";
        case 'superseded':
          return "superseded";
        case 'acronym':
          return "acronym";
        case 'variant':
          return "variant";
      }

      return 'proper name';
    }
}
