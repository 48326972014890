var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      attrs: {
        label: "name",
        placeholder: "",
        "track-by": "code",
        lang: "en",
        "aria-label":
          "Type to search " +
          _vm.name +
          " or use the arrow to navigate through the list. To select press spacebar.",
        "close-on-select": false,
        limit: _vm.limit,
        searchable: true,
        showLabels: false,
        options: _vm.options,
        multiple: true,
      },
      scopedSlots: _vm._u([
        {
          key: "placeholder",
          fn: function (props) {
            return [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("all_" + _vm.name)) + "\n    "
              ),
            ]
          },
        },
        {
          key: "option",
          fn: function (props) {
            return [
              _c(
                "span",
                { directives: [{ name: "to-lang", rawName: "v-to-lang" }] },
                [
                  _c("span", {
                    directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                    attrs: {
                      "aria-label":
                        (_vm.selection
                          .map(function (x) {
                            return x.name
                          })
                          .includes(props.option.name)
                          ? "Unselect "
                          : "Select ") + props.option.name,
                    },
                  }),
                  _vm._v(" " + _vm._s(props.option.name) + "\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "check" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "unchecked",
                    attrs: { icon: ["far", "square"] },
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    staticClass: "checked",
                    attrs: { icon: ["far", "check-square"] },
                  }),
                ],
                1
              ),
            ]
          },
        },
        {
          key: "tag",
          fn: function (ref) {
            var option = ref.option
            var remove = ref.remove
            return [
              _c(
                "span",
                {
                  staticClass:
                    "badge b-form-tag d-inline-flex align-items-baseline mw-100 mr-1 active badge-filterTag",
                  attrs: {
                    title: option.name,
                    role: "alert",
                    "aria-label": option.name + " selected",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                      staticClass:
                        "b-form-tag-content flex-grow-1 text-truncate",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(option.name) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close b-form-tag-remove",
                      attrs: {
                        "aria-keyshortcuts": "Delete",
                        type: "button",
                        "aria-label": "press space to remove",
                      },
                      on: {
                        click: function ($event) {
                          return remove(option)
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.selection,
        callback: function ($$v) {
          _vm.selection = $$v
        },
        expression: "selection",
      },
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "template",
        { slot: "limit" },
        [
          _c(
            "b-badge",
            { staticClass: "mr-1 active", attrs: { variant: "filterTag" } },
            [
              _vm._v(
                "\n            +" +
                  _vm._s(this.value.length - this.limit) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
        _vm._v(_vm._s(_vm.$t("no_elements_found"))),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }