import { bodies } from './bodies';
import { subjects } from './subjects';
export default {
    zh: {
        ...subjects.zh,
        ...bodies.zh,
        home: '主页',
        about: '关于',
        help: '帮助',
        user_guide: '用户指南',
        contact_us: '联系我们',
        resources: '资源',
        log_in: '登录',
        search_database: '搜索数据库',
        all_words: '搜索全部字词',
        exact_phrase: '精确匹配搜索',
        combined: '布尔搜索',
        boolean: '模糊搜索',
        fuzzy: '模糊搜索',
        search: '搜索',
        enter_search_query: "请输入要搜索的查询内容",
        no_result_found: '找不到搜索结果',
        advanced_search: '高级搜索',
        clear_all: '全部清除',
        search_in: '选择搜索范围',
        display_results_in: '选择显示范围',
        other: '其他',
        datasets: '数据集',
        all_datasets: '所有数据集',
        all_subjects: '所有主题',
        all_bodies: '所有机构',
        apply: '应用',
        please_read: ' 请仔细阅读联合国{terms_of_use_link} 和 {copyright_notice_link}。',
        terms_of_use: '使用条款',
        copyright_notice: '版权声明',
        error_executing_search: ' 执行搜索时出错，请重试 ',
        results: '共{1}个结果，显示{0}个结果 ',
        other_languages: '其他语文',
        clear_search_history: '清除搜索历史记录',
        searching_for: '正在搜索',
        open: '打开',
        details: '详细信息',
        public_record: '这是公开词条',
        internal_record: '这是内部词条',
        record_complete: '词条完整',
        record_obsolete: '词条已过时。',
        language_validated: '该语文已核证',
        language_internally_validated: '该语文已内部核证',
        language_not_validated: '该语文尚未核证',
        language_under_review: '该语文正在复核',
        record_status: '词条状态',
        record_distribution: '词条可见范围',
        references: '参考',
        subjects: '主题',
        subject: '主题',
        bodies: '机构',
        english: '英文',
        french: '法文',
        spanish: '西班牙文',
        russian: '俄文',
        chinese: '中文',
        arabic: '阿拉伯文',
        german: '德文',
        portuguese: '葡萄牙文',
        editorial: '编辑',
        share_by_email: '通过电子邮件分享词条链接',
        send_feedback: '发送反馈',
        send_us_feedback: '发送关于当前词条的反馈',
        feedback_success: "反馈提交成功",
        feedback_error: "提交反馈失败",
        country: '国家',
        phraseology: '短语',
        proper_name: '专有名称',
        footnote: '脚注',
        term: '术语',
        title: '专用名称',
        synonym: '同义词',
        complete: '完整',
        obsolete: '过时',
        suggestion: '建议',
        review_draft: '待复核草稿',
        review_suggestion: '待复核建议',
        draft: '草稿',
        ok: 'OK',
        review_suggestion_priority: '优先待复核建议',
        obsolete_candidate: '过时词条候选项',
        superseded: '已被取代',
        review_draft_priority: '优先待复核建议',
        suspended: '不再使用',
        deleted: '已删除',
        verification: '待核对',
        variant: '变体',
        alternate: '备选',
        phrase: ' 短语',
        short_form: '简称',
        acronym: '缩写',
        avoid: '避免使用',
        rejected: '已拒绝',
        standardized: '标准化',
        loading: '正在加载',
        error: '出错了！',
        success: "成功",
        search_failed: '搜索失败',
        definition: '定义',
        description: '说明',
        remark: '备注',
        note: '附注',
        source: '来源',
        sources: '来源',
        link: '链接',
        more: '展开',
        less: '收起',
        updated: '更新时间',
        created: '创建时间',
        your_name: '姓名',
        enter_name: '输入姓名',
        email_address: '电子邮件地址',
        enter_email: '输入电子邮件地址',
        never_share_your_email: '我们绝不会向任何人透露您的电子邮件地址。',
        comment: '评论',
        enter_comment: '输入评论',
        submit: '提交',
        reset: '重置',
        validation_error_name: '姓名不能为空。',
        validation_error_email: '必须提供有效的电子邮件地址。',
        "validation_error_suggestion": "必须填写建议内容",
        "validation_error_term": "词源是必需的",
        validation_error_comment: '内容不能为空。',
        validation_error_captcha: '请点击完成人机身份验证。',
        comment_sent: '留言已发送。',
        comment_not_sent: '留言未能发送。请重试。',
        lanugage_inquiry_message: "是否用英文以外的语言进行了搜索？",
        search_in_language_message: "确保在“高级搜索”选项中选择适当语种",
        currently_searching_in: "当前搜索范围是",
        are_important_entries_missing: "是否有重要词条遗漏？",
        send_your_suggestions: "欢迎提出建议！",
        databases: "数据库",
        domains: "域",
        press_space_bar: "要激活按空格键",
        enter_text_search: "输入文本并按回车键进行搜索。",
        filter: "筛选",
        country_name: "国名",
        download_country_name: "下载国名",
        currency_designation: "货币名称",
        currency_symbol: "货币符号",
        fractional_unit: "分数单位",
        inhabitant: "居民",
        capital_inhabitants: "首都居民",
        monetary_unit_plural: "货币单位复数",
        languages: "语言",
        adjective: "形容词",
        capital_city: "首都",
        monetary_unit: "货币单位",
        settings: "设置",
        fill_in_the_form_to_suggest_a_new_entry: '请填写表格，提交新词条建议',
        source_language: '源语',
        context: '语境',
        target_language: '目的语',
        body: '机构',
        your_email: '电子邮件地址',
        validation_suggestion: '你的建議是必需的',
        validation_source: '詞源為必填項',
        target: "目标",
        update_default_settings: '更新默认设置',
        settings_saved_in_cookie: "已在cookie中保存设置",
        submit_feedback: '提交反馈',
        which_languages_feedback_for: "请选择要提出反馈的语言",
        email: '电子邮件',
        suggested_term: '术语建议',
        term_source: '术语来源',
        additional_fields: '其他字段',
        select: '选择',
        comments: '评论',
        short: '简称',
        no_elements_found: "没有找到任何元素。请考虑更改查询内容。",
        home_title: '联合国术语数据库',
        "country short": "国家简称",
        "ISO Country alpha-2-code":"国际标准化组织二位字母国家代码",
        "Date of Entry in UN":"加入联合国日期",
        "ISO Country alpha-3-code":"国际标准化组织三位字母国家代码",
        "ISO Currency Code": "国际标准化组织货币代码",
        remove: "删除",
        headerTitle1: "联合国",
        headerTitle2: "大会和会议管理部",
        country_not_found_message: "没有找到与搜索标准相匹配的国家。",
        record_superseded: '词条已被取代。',
        record_ok: '记录好',
    }
}
