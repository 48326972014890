//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Term from "./TermPreview.vue"

export default {
    name: "Language",
    data: function () {
        return {
        }
    },
    props: ["data", "record", "LanguageCode"],
    computed: {
        terms() {
            var _termSortExpression = (status) => {
                switch (status.toLowerCase()) {
                    case 'standardized':
                        return 0;
                    case 'preferred':
                        return 1;
                    case 'admitted':
                        return 2;
                    case 'superseded':
                        return 3;
                    case 'deprecated':
                        return 4;
                }
                return 99;
            }
            return this.data.terms.sort(function (a, b) {
                return _termSortExpression(a.termStatus) - _termSortExpression(b.termStatus)
            })
        },
        isValidated: {
            get(): Boolean {
                if (this.data.validationStatus == null) return false
                return this.data.validationStatus.toLowerCase() === 'validated'
            }
        },
        showObsolete: {
            get(): Boolean {
                return this.index == 0 && (this.record.status.toLowerCase() == 'obsolete')
            }
        }
    },
    components: { Term },
    watch: {},
    methods: {}
}
