import * as Actions from "../Actions"
import i18n from "../../Lang/i18n"

const state = {
    currentLanguage: "en"
};

const mutations = {
    setCurrentLanguage(state, langCode: string) {
        state.currentLanguage = langCode
    },
}

const getters = {
    getCurrentLanguage: state => {
        return state.currentLanguage
    },
    isRTL: state => state.currentLanguage==='ar'
}

const actions = {
    setCurrentLanguage({ commit }, langCode: string) {
        commit(Actions.SET_CURRENT_LANGUAGE, langCode)
        i18n.locale = langCode
    },
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}
