//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchInput from '../Search/SearchInput.vue'
import UImage from '../UImage.vue'
import * as NProgress from "nprogress";
import { mapActions, mapGetters } from 'vuex'
import * as Actions from '../../Store/Actions'
import SearchFilters from "../Search/SearchFilters.vue"
import { SearchModel } from "../../Model/SearchModel";
import messagesAr from "../../Lang/Home/Home-ar"
import messagesEn from "../../Lang/Home/Home-en"
import messagesFr from "../../Lang/Home/Home-fr"
import messagesEs from "../../Lang/Home/Home-es"
import messagesRu from "../../Lang/Home/Home-ru"
import messagesZh from "../../Lang/Home/Home-zh"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

library.add(faUsers)
declare var UNTERM: any;

export default {
    name: "Home",
    data: function () {
        return {
            searchModel: new SearchModel(),
            loading: false,
        }
    },
    components: {
        SearchInput,
        SearchFilters,
        UImage
    },
    computed: {
        ...mapGetters(['getCurrentLanguage', 'getSearchSettings']),
        basePath() { return UNTERM.BasePath },
    },
    mounted() {
        this.setDefaultLanguage(this.searchModel);
    },
    watch: {
        getSearchSettings() {
            this.setDefaultLanguage(this.searchModel);
        },
        loading(val: boolean) {
            if (val) {
                NProgress.start();
            } else {
                NProgress.done();
            }
        }
    },
    methods: {
        ...mapActions([Actions.RESET_SEARCH]),
        doSearch() {
            this.resetSearch()
            this.$router.push({
                name: 'Search', params: {
                    langCode: this.getCurrentLanguage,
                },
                query: this.searchModel
            })
        },
    },
    i18n: {
        messages: {
            ...messagesAr,
            ...messagesEn,
            ...messagesFr,
            ...messagesEs,
            ...messagesRu,
            ...messagesZh,
        }
    }
}
