<template>
    <div >
        <b-row>
            <h5 v-to-lang class="mt-2">{{$t('databases')}}</h5>
        </b-row>

        <b-container>
            <b-row v-if="selectedDatasets.length > 0">
                <b-col class="text-left">
                    <b-link v-to-lang class="filter-clear" href="javascript:void(0);" @click="clearDbNamesFilter">{{$t('clear_all')}}</b-link>
                </b-col>
            </b-row>
            <b-row class="facets" @mouseleave="highlightRows()" @mouseover="highlightRows({name:'dataset',value:item.key})" v-for="item in facets.dbNames" :key="item.key">
                <b-col cols="10">
                    <b-form-checkbox @change="updateFacets($event,{name:'datasets',value:item.key})"
                                     :checked="selectedDatasets.includes(item.key)">{{$t(item.key)}}</b-form-checkbox>
                </b-col>
                <b-col cols="2"><b-badge pill variant="primary">{{item.value}}</b-badge></b-col>
            </b-row>
        </b-container>
        <b-row>
            <h5 v-to-lang class="mt-2">{{$t('bodies')}}</h5>
        </b-row>
        <b-container>
            <b-row v-if="selectedBodies.length > 0">
                <b-col>
                    <b-link class="filter-clear" href="javascript:void(0);" @click="clearBodiesFilter" v-to-lang>{{$t('clear_all')}}</b-link>
                </b-col>
            </b-row>
            <b-row class="facets" @mouseleave="highlightRows" @mouseover="highlightRows({name:'body',value:item.key})" v-for="item in facets.domains" :key="item.key">
                <b-col cols="10">
                    <b-form-checkbox @change="updateFacets($event,{name:'bodies',value:item.key})"
                                     :checked="selectedBodies.includes(item.key)">{{$t(item.key)}}</b-form-checkbox>
                </b-col>
                <b-col cols="2"><b-badge pill variant="primary">{{item.value}}</b-badge></b-col>
            </b-row>
        </b-container>
        <b-row>
            <h5 class="mt-2" v-to-lang>{{$t('subjects')}}</h5>
        </b-row>
        <b-container>
            <b-row v-if="selectedSubjects.length > 0">
                <b-col>
                    <b-link class="filter-clear" href="javascript:void(0);" @click="clearSubjectsFilter" v-to-lang>{{$t('clear_all')}}</b-link>
                </b-col>
            </b-row>
            <b-row class="facets" @mouseleave="highlightRows" @mouseover="highlightRows({name:'subject',value:item.key})" v-for="item in facets.subjects" :key="item.key">
                <b-col cols="10">
                    <b-form-checkbox @change="updateFacets($event,{name:'subjects',value:item.key})"
                                     :checked="selectedSubjects.includes(item.key)">{{$t(item.key)}}</b-form-checkbox>
                </b-col>
                <b-col cols="2"><b-badge pill variant="primary">{{item.value}}</b-badge></b-col>
            </b-row>
        </b-container>
    </div>
</template>


<script>
    import { SearchModel } from "../../Model/SearchModel";
    export default {
        name: 'SidePanel',
        props: {
            value: {
                required: true
            },
            facets: {
                type: Object,
                default: () => ({})
            },
        },
        computed: {
            selectedDatasets: {
                get() {
                    return this.searchModel.datasets||[];
                }
            },
            selectedBodies: {
                get() {
                    return this.searchModel.bodies || [];
                }
            },
            selectedSubjects: {
                get() {
                    return this.searchModel.subjects || [];
                }
            },
            searchModel: {
                get(): SearchModel {
                    return this.value
                },
            },
        },
        methods: {
            highlightRows(highlight) {
                this.$emit('highlight', highlight)
            },
            clearDbNamesFilter() {
                this.searchModel.datasets = [];
                this.$emit('search');
            },
            clearSubjectsFilter() {
                this.searchModel.subjects = [];
                this.$emit('search');
            },
            clearBodiesFilter() {
                this.searchModel.bodies = [];
                this.$emit('search');
            },
            updateFacets(check, data) {
                if (check) {
                    this.searchModel[data.name].push(data.value);
                } else {
                    let index = this.searchModel[data.name].findIndex(v => v == data.value)
                    this.searchModel[data.name].splice(index,1);
                }
                this.$emit('input', this.searchModel);
                this.$emit('search');
            }
        }
    }
</script>


<style scoped lang="scss">
    .facets:hover {
        background-color: #ebebeb;
    }
</style>
