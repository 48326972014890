var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-row", [
        _c(
          "h5",
          {
            directives: [{ name: "to-lang", rawName: "v-to-lang" }],
            staticClass: "mt-2",
          },
          [_vm._v(_vm._s(_vm.$t("databases")))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _vm.selectedDatasets.length > 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "b-link",
                        {
                          directives: [
                            { name: "to-lang", rawName: "v-to-lang" },
                          ],
                          staticClass: "filter-clear",
                          attrs: { href: "javascript:void(0);" },
                          on: { click: _vm.clearDbNamesFilter },
                        },
                        [_vm._v(_vm._s(_vm.$t("clear_all")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.facets.dbNames, function (item) {
            return _c(
              "b-row",
              {
                key: item.key,
                staticClass: "facets",
                on: {
                  mouseleave: function ($event) {
                    return _vm.highlightRows()
                  },
                  mouseover: function ($event) {
                    return _vm.highlightRows({
                      name: "dataset",
                      value: item.key,
                    })
                  },
                },
              },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "10" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          checked: _vm.selectedDatasets.includes(item.key),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateFacets($event, {
                              name: "datasets",
                              value: item.key,
                            })
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(item.key)))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("b-badge", { attrs: { pill: "", variant: "primary" } }, [
                      _vm._v(_vm._s(item.value)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "h5",
          {
            directives: [{ name: "to-lang", rawName: "v-to-lang" }],
            staticClass: "mt-2",
          },
          [_vm._v(_vm._s(_vm.$t("bodies")))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _vm.selectedBodies.length > 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-link",
                        {
                          directives: [
                            { name: "to-lang", rawName: "v-to-lang" },
                          ],
                          staticClass: "filter-clear",
                          attrs: { href: "javascript:void(0);" },
                          on: { click: _vm.clearBodiesFilter },
                        },
                        [_vm._v(_vm._s(_vm.$t("clear_all")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.facets.domains, function (item) {
            return _c(
              "b-row",
              {
                key: item.key,
                staticClass: "facets",
                on: {
                  mouseleave: _vm.highlightRows,
                  mouseover: function ($event) {
                    return _vm.highlightRows({ name: "body", value: item.key })
                  },
                },
              },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "10" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          checked: _vm.selectedBodies.includes(item.key),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateFacets($event, {
                              name: "bodies",
                              value: item.key,
                            })
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(item.key)))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("b-badge", { attrs: { pill: "", variant: "primary" } }, [
                      _vm._v(_vm._s(item.value)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "h5",
          {
            directives: [{ name: "to-lang", rawName: "v-to-lang" }],
            staticClass: "mt-2",
          },
          [_vm._v(_vm._s(_vm.$t("subjects")))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-container",
        [
          _vm.selectedSubjects.length > 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-link",
                        {
                          directives: [
                            { name: "to-lang", rawName: "v-to-lang" },
                          ],
                          staticClass: "filter-clear",
                          attrs: { href: "javascript:void(0);" },
                          on: { click: _vm.clearSubjectsFilter },
                        },
                        [_vm._v(_vm._s(_vm.$t("clear_all")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.facets.subjects, function (item) {
            return _c(
              "b-row",
              {
                key: item.key,
                staticClass: "facets",
                on: {
                  mouseleave: _vm.highlightRows,
                  mouseover: function ($event) {
                    return _vm.highlightRows({
                      name: "subject",
                      value: item.key,
                    })
                  },
                },
              },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "10" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          checked: _vm.selectedSubjects.includes(item.key),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.updateFacets($event, {
                              name: "subjects",
                              value: item.key,
                            })
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(item.key)))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { cols: "2" } },
                  [
                    _c("b-badge", { attrs: { pill: "", variant: "primary" } }, [
                      _vm._v(_vm._s(item.value)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }