//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "SearchTags",
    data: function () {
        return {
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        dataList: {
            required: true
        },
        limit: {
            type: Number,
            default: 3
        }
    },
    methods: {
        remove(tag) {
            this.$emit('remove', tag)
        }
    }
}
