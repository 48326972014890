<template>
    <div>
        <b-sidebar id="sidebar-backdrop"
                   :no-close-on-route-change="true"
                   shadow>
            <b-col class="sidebar">
                <side-panel @search="doSearch" @highlight="highlightRow" v-model="searchModel" :facets="getSearchFacets"></side-panel>
            </b-col>
        </b-sidebar>
        <b-row>
            <b-col>
                <div v-b-toggle.sidebar-backdrop>
                    <b-button class="toggle-button">
                        <font-awesome-icon color="white" :icon="['fa', 'angle-up']" />
                        {{$t('filter')}}
                        <b-badge class="filter-badge" pill variant="primary">{{availableFacets}}</b-badge>
                    </b-button>
                </div>
                <b-container>
                    <h2 class="mt-5 mb-3 sr-only">Search terms</h2>
                    <div class="container mb-3">
                        <div class="row" id="search-box-row">
                            <search-input @search="doSearch" v-model="searchModel" :disabled="loading" />
                        </div>
                    </div>
                    <search-filters v-model="searchModel" @apply="doSearch" @shown="setAdvancedSearchVisible(true)" @hidden="setAdvancedSearchVisible(false)" />
                    <search-filters-preview v-model="searchModel" @change="doSearch"
                                            :loading="loading"
                                            :searchedTerm="searchTerm"
                                            :page="CurrentPage"
                                            :page-size="getPageSize"
                                            :total-results="getTotalHits"
                                            v-show="!advancedFiltersVisible" />
                </b-container>
                <b-container class="search-pane__container">
                    <h1 v-if="!searchTerm" role="alert">
                        {{$t('enter_search_query')}}
                    </h1>
                    <div class="no-data-message" v-else-if="getTotalHits <= 0 && !loading" role="alert">
                        <h1 v-to-lang>{{$t('no_result_found')}} <em :title="searchTerm">{{searchTerm}}</em></h1>
                        <h3 v-to-lang>{{$t('lanugage_inquiry_message')}}</h3>
                        <p v-to-lang>{{$t('search_in_language_message')}}</p>
                        <p v-to-lang>{{$t('currently_searching_in')}}</p>
                        <ul style="list-style-position:inside;">
                            <template v-for="item in selectedLangs">
                                <li :key="item.name">{{$t(item.name.toLowerCase())}}</li>
                            </template>
                        </ul>
                        <h3 v-to-lang>{{$t('are_important_entries_missing')}}</h3>
                        <div @click="goToSuggestions" class="suggestion-area">
                            <div class="icon">
                                <font-awesome-icon color="#009EDB" :icon="['fa', 'envelope']" size="3x" />
                            </div>
                            <div class="message-text" v-to-lang>{{$t('send_your_suggestions')}}</div>
                        </div>
                    </div>
                    <div v-else="getTotalHits>0">
                        <b-table sticky-header hover show-empty
                                 v-shortkey.focus="['alt', 'r']"
                                 :items="getSearchResults.results"
                                 :busy="loading"
                                 thead-tr-class="language-name-title"
                                 :fields="tableFields"
                                 id="results-table"
                                 tbody-tr-class="rowClass"
                                 :no-local-sorting="true"
                                 @sort-changed="handleSorting"
                                 :empty-text="noResultsText"
                                 responsive>

                            <template v-slot:row-details="row">
                                <b-card class="record-preview">
                                    <span class="float-right close-icon" data-effect="fadeOut" @click="row.toggleDetails">
                                        <i class="fa fa-times fa-2x"></i>
                                    </span>
                                    <record-preview :Record="row.item"></record-preview>
                                </b-card>
                            </template>

                            <template v-slot:cell(actions)="row">
                                <div class="status-icons">
                                    <i v-to-lang :title="$t(`${row.item.distribution.toLowerCase()}_record`)" :class="['info', `distribution-${row.item.distribution}`]"></i>
                                    <i v-to-lang :title="$t('record_'+row.item.status)" :class="['info', `record status-${row.item.status}`]"></i>
                                </div>
                                <b-button v-to-lang size="sm" @click="viewTerm(row.item)" class="mr-1 px-2 py-0" variant="grid">
                                    {{$t('open')}}
                                </b-button>
                                <b-button v-to-lang size="sm" @click="row.toggleDetails" class="px-2 py-0" variant="grid">
                                    {{$t('details')}}
                                </b-button>
                            </template>

                            <template v-slot:cell()="data" aria-live="polite">
                                <language-result :search-term="searchTerm" :language-data="data.value" v-if="data.field.isLang"
                                                 :language-code="data.field.langCode" />
                                <record-info :Record="data.item" v-if="!data.field.isLang" />
                            </template>

                            <template v-to-lang v-slot:head()="data">
                                {{ $t(data.label.toLowerCase()) }}
                            </template>

                            <template v-slot:table-busy>
                                <div class="text-center text-primary my-5">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong v-to-lang> {{ $t('loading') }}...</strong>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination v-set-pagination v-model="CurrentPage" limit="15"
                                      :total-rows="getTotalHits"
                                      :per-page="getPageSize"
                                      aria-controls="results-table"
                                      v-on:change="handlePageChange"
                                      align="center"></b-pagination>
                    </div>
                </b-container>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import * as NProgress from "nprogress"
    import * as TerminologyDataService from "../../DataServices/terminologyDataService"
    import LanguageResult from "./LanguageResult.vue"
    import RecordPreview from "./RecordPreview.vue"
    import RecordInfo from "./RecordInfo.vue"
    import { mapActions, mapGetters } from 'vuex'
    import * as Actions from '../../Store/Actions'
    import { SearchResults } from "../../Model/SearchResults"
    import SearchInput from "./SearchInput.vue"
    import SearchFilters from "./SearchFilters.vue"
    import SearchFiltersPreview from "./SearchFiltersPreview.vue"
    import SidePanel from "./SidePanel.vue"
    import { languages } from "../../Model/Constants"
    import { SearchModel } from "../../Model/SearchModel"
    import Multiselect from 'vue-multiselect'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faEnvelope, faAngleUp } from '@fortawesome/free-solid-svg-icons'

    library.add(faEnvelope)
    library.add(faAngleUp)

    export default {
        name: "SearchPane",
        data: function () {
            return {
                searchModel: new SearchModel(),
                loading: false,
                noResultsText: 'No results yet',
                languageOptions: [...languages],
                advancedFiltersVisible: false,
                searchTerm: null,
                highlightData: null
            }
        },
        components: {
            LanguageResult,
            RecordInfo,
            RecordPreview,
            SearchInput,
            SearchFilters,
            SearchFiltersPreview,
            Multiselect,
            SidePanel
        },
        mounted() {
            this.setDefaultLanguage(this.searchModel);
            let prevSearch = this.getSearchModel
            if (prevSearch != null && prevSearch.hasSearchTerm()) {
                this.searchModel = prevSearch
            }
            if (this.queryTerm) {
                let query = this.$route.query
                //Handle array query parameters with single value
                let assignProp = (arrayprop) => {
                    if (typeof (query[arrayprop]) === 'string') {
                        query[arrayprop] = [query[arrayprop]]
                    }
                    if (typeof (query[arrayprop]) !== 'undefined') {
                        this.searchModel[arrayprop] = query[arrayprop]
                    }
                }
                this.setDefaultLanguage(this.searchModel);
                if (query.searchType) this.searchModel.searchType = parseInt(query.searchType)
                assignProp("datasets")
                assignProp("subjects")
                assignProp("bodies")
                assignProp("languagesDisplay")
                assignProp("searchLanguages")

                this.searchModel.searchTerm = this.queryTerm

                if (this.searchModel.searchTerm.trim() != "") {
                    this.doSearch()
                }
            }
        },
        computed: {
            ...mapGetters([
                'getSearchResults',
                'getCurrentPage',
                'getSearchModel',
                'getTotalHits',
                'getPageSize',
                'getSearchFacets',
                'getCurrentLanguage',
                'getSearchSettings'
            ]),
            availableFacets() {
                let { subjects, domains, dbNames } = this.getSearchFacets || { subjects: [], domains: [], dbNames: [] };
                return subjects.length + domains.length + dbNames.length;
            },
            queryTerm() {
                return this.$route.query.searchTerm;
            },
            selectedLangs() {
                return languages.filter(lang => { return this.searchModel.searchLanguages.find(x => x == lang.code) })
            },
            CurrentPage: {
                get() { return this.getCurrentPage + 1 },
                set(value) { this.changePage(value - 1) }
            },
            tableFields: {
                get() {
                    const selectedLangs = languages.filter(lang => { return this.searchModel.languagesDisplay.find(x => x == lang.code) })
                    return [
                        { key: 'actions', label: '', sortable: false },
                        ...selectedLangs.map(l => { return { key: l.name.toLowerCase(), langCode: l.code, sortable: true, isLang: true, headerTitle: this.$t(l.name.toLowerCase()) } }),
                        { key: 'recordinfo', label: '', sortable: false, langCode: '', isLang: false }
                    ]
                }
            },
            facettingRequest() {
                const searchModel: SearchModel = this.searchModel;
                return searchModel.subjects.length > 0 || searchModel.bodies.length > 0
                    || searchModel.datasets.length > 0 || this.getCurrentPage > 0;
            }
        },
        watch: {
            getSearchSettings() {
                this.setDefaultLanguage(this.searchModel);
            },
            loading(val: boolean) {
                if (val) {
                    NProgress.start();
                } else {
                    NProgress.done();
                }
            }
        },
        methods: {
            ...mapActions({
                setSearchResults: Actions.SET_SEARCH_RESULTS,
                changePage: Actions.SEARCH_CHANGE_PAGE,
                setSearchModel: Actions.SET_SEARCH_MODEL,
            }),
            highlightRow(data) {
                this.highlightData = data;
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (this.highlightData && this.highlightData.name == 'dataset') {
                    return item.dbName == this.highlightData.value ? 'highlight-row' : '';
                }
                if (this.highlightData && this.highlightData.name == 'body') {
                    return item.bodies.includes(this.highlightData.value) ? 'highlight-row' : '';
                }
                if (this.highlightData && this.highlightData.name == 'subject') {
                    return item.subjects.includes(this.highlightData.value) ? 'highlight-row' : '';
                }
                return;
            },
            doSearch() {
                document.querySelector('th')?.click()
                this.searchTerm = this.searchModel.searchTerm.trim()
                this.setSearchModel(this.searchModel)
                this.changePage(0)
                this.setSearchUrl(this.searchTerm)
                this.fetchData()
            },
            setSearchUrl(searchTerm) {
                let resolved = this.$router.resolve({
                    params: { langCode: this.getCurrentLanguage }, query: this.searchModel
                })
                let currentHref = window.location.href.replace(window.location.origin, "");
                if (currentHref != resolved.href) {
                    this.$router.push({
                        params: { langCode: this.getCurrentLanguage }, query: this.searchModel
                    }, null, function () { console.log("Navigation aborted" + arguments) })
                }
            },
            async fetchData() {
                if (this.searchModel.searchTerm.trim() === '') {
                    this.setSearchResults(new SearchResults())
                    return
                }

                this.$data.loading = true;

                try {
                    let results = await TerminologyDataService.searchTerm(this.getSearchModel, this.getCurrentPage, this.facettingRequest);
                    this.setSearchResults(results)
                    this.$data.loading = false
                    if (this.getSearchResults.results.length == 0) {
                        this.$data.noResultsText = "No terms found matching provided search criteria"
                    }
                } catch (e) {
                    console.log('error', e);
                    this.setSearchResults(null)
                    this.$data.noResultsText = this.$t('error_executing_search')
                    this.$bvToast.toast(this.$t('search_failed'), {
                        title: 'Error!',
                        variant: "danger",
                        autoHideDelay: 3000,
                        appendToast: false
                    })
                    this.$data.loading = false
                }
            },
            handlePageChange(pageNumber) {
                this.changePage(pageNumber - 1);
                this.fetchData();
            },
            viewTerm(item) {
                const resolved = this.$router.resolve({ name: 'viewTerm', params: { recordId: item.recordID, langCode: this.getCurrentLanguage } })
                window.open(resolved.href, "record-" + item.recordID)
            },
            handleSorting(ctx) {
                if (ctx.sortBy) {
                    this.searchModel.sortBy = this.languageOptions.find(x => x.name.toLowerCase() == ctx.sortBy.toLowerCase()).code;
                    this.searchModel.sortDirection = ctx.sortDesc ? "desc" : "asc"
                    this.handlePageChange(1)
                } else {
                    this.searchModel.sortBy = '';
                    this.searchModel.sortDirection = ''
                }
            },
            setAdvancedSearchVisible(visible: boolean) {
                this.advancedFiltersVisible = visible
            },
            goToSuggestions() {
                this.$router.push({
                    name: 'suggestion',
                    query: { q: this.searchTerm }
                })
            }
        }
    }
</script>
<style lang="scss">

    #search-box-row .search-input {
        width: inherit;
    }

    .highlight-row {
        background-color: #ebebeb;
    }

    /*th {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }*/

    .table th, .table td {
        min-width: 150px;
    }

   /* body {
        &.i18n-ru {
            .toggle-button {
                left: -46px !important;
            }
        }
    }*/

    .no-data-message {
        color: #8292A5;
    }
</style>
<style scoped lang="scss">

    .i18n-ar .search-pane__container {
        text-align: right;
    }

    .i18n-ar .suggestion-area .icon {
        float: right !important;
        margin-left: 10px !important;
    }

    .search-pane__container .b-table-sticky-header {
        max-height: unset !important;
        overflow-x: unset;
        //overflow-y: visible;
    }

    .table-actions button {
        margin-bottom: 3px;
    }

    .status-icons {
        width: 100px;
        display: inline-block;
        vertical-align: middle;
        padding-left: 7px;
    }

    .record-preview {
        background-color: #F9FAFC;
        padding: 0 45px;
    }

    .sidebar {
        margin-left: 8px;
        /*margin-top: 4rem;
        max-width: 290px;*/
    }

    em {
        color: red;
    }

    .suggestion-area {
        cursor: pointer;
    }

    .suggestion-area .icon {
        float: left;
    }

    .suggestion-area .message-text {
        padding: 10px;
        margin-left: 40px;
        color: #666666;
        font-weight: bold;
        direction: initial !important;
    }

    .toggle-button {
        position: fixed;
        top: 50%;
        width:150px;
        left: -60px;
        background-color: grey;
        color: white;
        transform: rotate(90deg);
        z-index: 100
    }

    .row {
        margin-right: auto !important;
    }

    .filter-badge {
        margin-left: 10px;
    }

    h1 em {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: inline-block;
    }
</style>
