import { bodies } from './bodies';
import { subjects } from './subjects';

export default {
    "es": {
        ...bodies.es,
        ...subjects.es,
        "about": "Acerca del Portal",
        "acronym": "Sigla",
        "advanced_search": "Búsqueda avanzada",
        "all_bodies": "Todos los órganos",
        "all_datasets": "Todos los conjuntos de datos",
        "all_subjects": "Todos los campos temáticos",
        "all_words": "Todas las palabras",
        "alternate": "alternativa",
        "apply": "Aplicar",
        "arabic": "Árabe",
        "avoid": "evítese",
        "bodies": "Órganos",
        "boolean": "Búsqueda booleana",
        "chinese": "Chino",
        "clear_all": "Borrar todo",
        "clear_search_history": "Borrar historial de búsqueda",
        "combined": "Búsqueda combinada",
        "comment": "Comentario",
        "comment_not_sent": "Su comentario no se ha enviado. Inténtelo de nuevo.",
        "comment_sent": "Su comentario se ha enviado.",
        "complete": "completa",
        "contact_us": "Contacto",
        "copyright_notice": "aviso de propiedad intelectual",
        "country": "país",
        "created": "Creada ",
        "datasets": "Conjuntos de datos",
        "definition": "Definición",
        "deleted": "eliminada",
        "description": "Descripción",
        "details": "Detalles",
        "display_results_in": "Mostrar resultados en",
        "draft": "borrador",
        "editorial": "Editorial",
        "email_address": "Dirección de correo electrónico",
        "english": "Inglés",
        "enter_comment": "Escribir comentario",
        "enter_email": "Escriba su dirección de correo electrónico",
        "enter_name": "Escriba su nombre",
        "error": "Error",
        "success": "éxito",
        "error_executing_search": "Error al ejecutar la búsqueda. Vuelva a intentarlo",
        "exact_phrase": "Frase exacta",
        "footnote": "nota a pie de página",
        "french": "Francés",
        "fuzzy": "Búsqueda aproximada",
        "german": "Alemán",
        "help": "Ayuda",
        "home": "Inicio",
        "internal_record": "Esta ficha es interna",
        "language_internally_validated": "Campo con validación interna",
        "language_not_validated": "Campo sin validar",
        "language_under_review": "Campo en revisión",
        "language_validated": "Campo validado",
        "less": "Menos ",
        "link": "Hipervínculo",
        "loading": "Cargando",
        "log_in": "Iniciar sesión ",
        "more": "Más ",
        "never_share_your_email": "No difundiremos su correo electrónico.",
        "note": "Nota",
        "obsolete": "obsoleta",
        "obsolete_candidate": "posible ficha obsoleta",
        "ok": "ok",
        "open": "Abrir",
        "other": "Más",
        "other_languages": "Otros idiomas",
        "phrase": "frase",
        "phraseology": "fraseología",
        "please_read": "Lea atentamente las {terms_of_use_link} y el {copyright_notice_link} de las Naciones Unidas.",
        "portuguese": "Portugués",
        "proper_name": "nombre propio",
        "public_record": "Esta ficha es pública.",
        "record_complete": "Esta ficha está completa.",
        "record_distribution": "Distribución de la ficha",
        "record_obsolete": "Esta ficha ha quedado obsoleta.",
        "record_status": "Estado de la ficha",
        "references": "Referencias",
        "rejected": "rechazado",
        "remark": "Observaciones ",
        "reset": "Restablecer ",
        "resources": "Recursos",
        "results": "Resultados: {0} de {1}",
        "review_draft": "revisar borrador",
        "review_draft_priority": "revisar borrador (prioridad)",
        "review_suggestion": "revisar sugerencia",
        "review_suggestion_priority": "revisar sugerencia (prioridad)",
        "russian": "Ruso",
        "search": "Buscar",
        "enter_search_query": "Introduzca un término para buscar",
        "no_result_found": 'No se encontraron resultados para',
        "search_database": "Buscar",
        "search_failed": "No se pudo ejecutar la búsqueda",
        "search_in": "Buscar en",
        "searching_for": "Su búsqueda:",
        "send_feedback": "Enviar comentarios",
        "send_us_feedback": "Enviar comentarios sobre esta ficha",
        "feedback_success": "Comentarios enviados correctamente.",
        "feedback_error": "Error al enviar comentarios.",
        "share_by_email": "Enviar esta ficha por correo electrónico",
        "short_form": "forma corta",
        "source": "Fuente",
        "sources": "Fuentes",
        "spanish": "Español",
        "standardized": "estandarizado",
        "subjects": "Campos temáticos",
        "subject": "Campos temático",
        "submit": "Enviar ",
        "suggestion": "sugerencia",
        "superseded": "reemplazada",
        "suspended": "suspendida",
        "synonym": "sinónimo",
        "term": "Término",
        "terms_of_use": "condiciones de utilización",
        "title": "título",
        "updated": "Actualizada ",
        "user_guide": "Guía de uso",
        "validation_error_captcha": "Prueba CAPTCHA",
        "validation_error_comment": "El campo del comentario es obligatorio.",
        "validation_error_email": "Campo obligatorio",
        "validation_error_suggestion": "Campo obligatorio",
        "validation_error_term": "Campo obligatorio",
        "validation_error_name": "El campo del nombre es obligatorio.",
        "variant": "Variante",
        "verification": "verificación",
        "your_name": "Nombre",
        "lanugage_inquiry_message": "¿Buscó en un idioma que no es el inglés?",
        "search_in_language_message": "Asegúrese de haber seleccionado el idioma o los idiomas adecuados en las opciones disponibles en la búsqueda avanzada",
        "currently_searching_in": "Usted está buscando en:",
        "country short":"nombre corto",
        are_important_entries_missing: "¿Faltan entradas importantes?",
        send_your_suggestions: "Envíenos su sugerencia.",
        databases: "Bases de datos",
        domains: "dominios",
        press_space_bar: "Para activar presione la barra espaciadora",
        enter_text_search: "Ingrese el texto y presione Enter para buscar.",
        exit_suggestions: "Press Escape to exit suggestion list",
        filter: "Filtrar",
        country_name: "Nombres de países",
        download_country_name: "Descargar los nombres de países",
        currency_designation: "Designación de moneda",
        currency_symbol: "Símbolo de moneda",
        fractional_unit: "Unidad fraccionaria",
        inhabitant: "Habitante",
        capital_inhabitants: "Habitantes de la capital",
        monetary_unit_plural: "Unidad Monetaria Plural",
        languages: "Idiomas",
        adjective: "Adjetivo",
        capital_city: "Ciudad capital",
        monetary_unit: "Unidad monetaria",
        settings: "Configuración",
        fill_in_the_form_to_suggest_a_new_entry: 'Complete el formulario para proponer una nueva entrada',
        source_language: 'Idioma fuente',   
        context: 'Contexto',
        target_language: 'Idioma meta',
        body: 'Órgano',
        your_email: 'Correo electrónico',
        validation_suggestion: 'Campo obligatorio',
        validation_source: 'Campo obligatorio',
        target: "Objetivo",
        update_default_settings: 'Actualizar configuración predeterminada',
        settings_saved_in_cookie: "Se guardó la configuración en una <i>cookie</i>",
        submit_feedback: 'Enviar un comentario',
        which_languages_feedback_for: "¿A qué idioma se refiere su comentario?",
        email: 'Dirección de correo electrónico',
        suggested_term: 'Término sugerido',
        term_source: 'Fuente',
        additional_fields: 'Campos adicionales',
        select: ' Seleccionar',
        comments: 'Observaciones',
        short: 'Forma corta',
        home_title: 'La Base de Datos Terminológica de las Naciones Unidas',
        remove: "Eliminar",
        headerTitle1: "La base de datos terminológica",
        headerTitle2: "Departamento de la Asamblea General </br>y de Gestión de Conferencias",
        country_not_found_message: "No se encontró ninguna coincidencia con esos criterios de búsqueda.",
        no_elements_found: "No se encontró ningún elemento. Considere cambiar el contenido de la consulta.",
        record_superseded: 'Esta ficha ha sido reemplazada por otra.',
        record_ok: 'Grabar bien',
    }
}
