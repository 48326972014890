//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SearchModel } from "../../Model/SearchModel";
import SearchTags from "./SearchTags.vue";

export default {
    name: "SearchFiltersPreview",
    data: function () {
        return {}
    },
    components: {
        SearchTags
    },
    props: {
        searchedTerm: {
            default: ''
        },
        value: {
            required: true
        },
        page: {
            required: true,
            type: Number
        },
        totalResults: {
            required: true,
            type: Number
        },
        pageSize: {
            required: true,
            type: Number
        },
        loading: {
            default: false,
        }
    },
    computed: {
        searchModel: {
            get(): SearchModel {
                return this.value
            },
            set(newVal: SearchModel) {
                this.$emit('input', newVal)
            }
        },
        firstResult: {
            get() {
                return ((this.page - 1) * this.pageSize) + (this.totalResults > 0 ? 1 : 0)
            }
        },
        lastResult: {
            get() {
                let last = (this.page * this.pageSize)
                return (last > this.totalResults) ? this.totalResults : last

            }
        }
    },
    methods: {
        onSearchInClick() {
            this.$emit('input', this.value)
        },
        removeDataset(option) {
            const idx = this.searchModel.datasets.indexOf(option)
            if (idx >= 0) {
                this.searchModel.datasets.splice(idx, 1)
            }
        },
        removeSubject(option) {
            const idx = this.searchModel.subjects.indexOf(option)
            if (idx >= 0) {
                this.searchModel.subjects.splice(idx, 1)
            }
        },
        removeBody(option) {
            const idx = this.searchModel.bodies.indexOf(option)
            if (idx >= 0) {
                this.searchModel.bodies.splice(idx, 1)
            }
        }
    }
}
