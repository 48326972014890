export const SET_SEARCH_RESULTS = "setSearchResults";
export const SEARCH_CHANGE_PAGE = "searchChangePage";
export const SET_SEARCH_MODEL = "setSearchModel";
export const SET_SEARCH_SETTINGS = "setSearchSettings";
export const RESET_SEARCH = "resetSearch";

export const SET_CURRENT_RECORD = "setCurrentRecord";
export const SET_CURRENT_RECORD_ID = "setCurrentRecordId";
export const TOGGLE_FEEDBACK_SHOWING = "toggleFeedbackShowing";
export const RESET_RECORD_STORE = "resetRecordStore";

export const SET_CURRENT_LANGUAGE = "setCurrentLanguage";

export const SET_DATASOURCES = "setDatasources";
export const SET_BODIES = "setBodies";
export const SET_SUBJECTS = "setSubjects"
export const GET_DATASOURCES = "getDatasources"
export const GET_BODIES = "getBodies"
export const GET_SUBJECTS = "getSubjects"
