var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.searchedTerm
    ? _c(
        "b-row",
        { staticClass: "filter-preview" },
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
                { name: "to-lang", rawName: "v-to-lang" },
              ],
              staticClass: "text-nowrap search-text my-auto",
              attrs: { cols: "3", title: _vm.searchModel.searchTerm },
            },
            [
              _vm._v(
                '"' +
                  _vm._s(_vm._f("maxText")(_vm.searchModel.searchTerm)) +
                  '"'
              ),
            ]
          ),
          _vm._v(" "),
          _c("b-col", { staticClass: "my-auto", attrs: { cols: "6" } }, [
            _vm.searchModel.datasets.length
              ? _c(
                  "div",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { "font-size": "1.5rem" },
                  },
                  [
                    _c("search-tags", {
                      attrs: {
                        dataList: _vm.searchModel.datasets,
                        limit: 3,
                        title: _vm.$t("datasets"),
                      },
                      on: { remove: _vm.removeDataset },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.searchModel.subjects.length
              ? _c(
                  "div",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { "font-size": "1.5rem" },
                  },
                  [
                    _c("search-tags", {
                      attrs: {
                        dataList: _vm.searchModel.subjects,
                        limit: 3,
                        title: _vm.$t("subjects"),
                      },
                      on: { remove: _vm.removeSubject },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.searchModel.bodies.length
              ? _c(
                  "div",
                  {
                    staticClass: "d-inline-block",
                    staticStyle: { "font-size": "1.5rem" },
                  },
                  [
                    _c("search-tags", {
                      attrs: {
                        dataList: _vm.searchModel.bodies,
                        limit: 3,
                        title: _vm.$t("bodies"),
                      },
                      on: { remove: _vm.removeBody },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "b-col",
                {
                  directives: [{ name: "to-lang", rawName: "v-to-lang" }],
                  staticClass: "text-right my-auto text-nowrap",
                  attrs: { role: "alert" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("results", [
                          _vm.firstResult + "-" + _vm.lastResult,
                          _vm.totalResults,
                        ])
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }