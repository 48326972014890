<template>
    <b-container class="col-9" id="home-container">
        <div class="unterm-logo">
            <img :src="basePath + 'images/unterm-logo.svg'" style="height:100px;" alt="UNTERM logo" />
        </div>
        <h2 class="text-black" id="page-title" v-html="$t('home_title')" />
        <div class="row" id="search-box-row">
            <h2 class="sr-only">Search terms</h2>
            <search-input @search="doSearch" v-model="searchModel" :disabled="loading" />
        </div>
        <search-filters v-model="searchModel" class="mb-3" />
        <b-row class="text-center mt-5 d-flex justify-content-around">
            <b-col class="pr-lg-4 pr-md-2" md="4" sm="auto">
                <div class="site-info p-4">
                    <u-image src="theme/Icon feather-globe.svg" />
                    <h5 v-to-lang class="my-4" v-html="$t('what_is_unterm_title')" />
                    <p v-to-lang class="text-justify" v-html="$t('what_is_unterm')" />
                </div>
            </b-col>
            <b-col class="px-lg-4 px-md-2" md="4" sm="auto">
                <div class="site-info p-4">
                    <u-image src="theme/Icon material-translate.svg" />
                    <h5 v-to-lang class="my-4" v-html="$t('multilingual_content_title')" />
                    <p v-to-lang class="text-justify" v-html="$t('multilingual_content')" />
                </div>
            </b-col>
            <b-col class="pl-lg-4 pl-md-2" md="4" sm="auto">
                <div class="site-info p-4">
                    <font-awesome-icon :icon="['fa', 'users']" size="3x" />
                    <h5 v-to-lang class="my-4" v-html="$t('global_management_title')" />
                    <p v-to-lang class="text-justify" v-html="$t('global_management')" />
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import SearchInput from '../Search/SearchInput.vue'
    import UImage from '../UImage.vue'
    import * as NProgress from "nprogress";
    import { mapActions, mapGetters } from 'vuex'
    import * as Actions from '../../Store/Actions'
    import SearchFilters from "../Search/SearchFilters.vue"
    import { SearchModel } from "../../Model/SearchModel";
    import messagesAr from "../../Lang/Home/Home-ar"
    import messagesEn from "../../Lang/Home/Home-en"
    import messagesFr from "../../Lang/Home/Home-fr"
    import messagesEs from "../../Lang/Home/Home-es"
    import messagesRu from "../../Lang/Home/Home-ru"
    import messagesZh from "../../Lang/Home/Home-zh"
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faUsers } from '@fortawesome/free-solid-svg-icons'

    library.add(faUsers)
    declare var UNTERM: any;

    export default {
        name: "Home",
        data: function () {
            return {
                searchModel: new SearchModel(),
                loading: false,
            }
        },
        components: {
            SearchInput,
            SearchFilters,
            UImage
        },
        computed: {
            ...mapGetters(['getCurrentLanguage', 'getSearchSettings']),
            basePath() { return UNTERM.BasePath },
        },
        mounted() {
            this.setDefaultLanguage(this.searchModel);
        },
        watch: {
            getSearchSettings() {
                this.setDefaultLanguage(this.searchModel);
            },
            loading(val: boolean) {
                if (val) {
                    NProgress.start();
                } else {
                    NProgress.done();
                }
            }
        },
        methods: {
            ...mapActions([Actions.RESET_SEARCH]),
            doSearch() {
                this.resetSearch()
                this.$router.push({
                    name: 'Search', params: {
                        langCode: this.getCurrentLanguage,
                    },
                    query: this.searchModel
                })
            },
        },
        i18n: {
            messages: {
                ...messagesAr,
                ...messagesEn,
                ...messagesFr,
                ...messagesEs,
                ...messagesRu,
                ...messagesZh,
            }
        }
    }
</script>
<style scoped lang="scss">
    h5 {
        font-weight: bold;
    }
    .unterm-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    @media (max-width: 700px) {
        .unterm-logo {
            display: none;
        }

        #home-container {
            margin-top: -100px;
            max-width: 1152px;
        }
    }
</style>
